import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  Button,
  Typography,
  Box,
} from '@mui/material';
import LabeledDropdown from './LabeledDropdown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { updateIntegration } from '../api/integrations';

const QuickbooksSetup = ({
  integrations,
  setIntegrations,
  quickbooksLocations,
  setQuickbooksLocations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  integrationTypes,
}) => {

  const handlePartnerSelectionChange = async (integrationId, nativeId) => {
    const updatedIntegration = { ...integrations.find(integration => integration.id === integrationId), native_finance_id: nativeId };
    try {
      await updateIntegration(integrationId, updatedIntegration);
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((integration) =>
          integration.id === integrationId ? updatedIntegration : integration
        )
      );
      setSnackbarMessage('QuickBooks reference linked.');
      setSnackbarAlert('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert('error');
    }
  };

  return (
    <>
      <Dialog
        open={quickbooksLocations.length > 0}
        onClose={() => setQuickbooksLocations([])}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Link partners to QuickBooks.</DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Typography variant="body2" sx={{ mb: '0.5rem' }}>If you send POs to suppliers/manufacturers, these will automatically be synced to Trinity.</Typography>
            <Typography variant="body2" sx={{ mb: '0.5rem' }}>Link Distributors/Customers to push invoices for sales orders to QuickBooks.</Typography>
            <List sx={{ maxHeight: '50vh', overflowY: 'auto', mt: 0 }}>
              {integrations
                .filter(integration => !['HQ'].includes(integration.integration_type.category))
                .map(integration => (
                  <ListItem key={integration.id}>
                    <Box display="flex" flexDirection="row" alignItems="center" gap="1rem" sx={{ width: '100%' }}>
                      <Typography sx={{ width: '40%' }}>
                        <b>{integration.name} ({integration.integration_type.type})</b>
                      </Typography>
                      <Typography sx={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowForwardIcon />
                      </Typography>
                      <LabeledDropdown
                        label="Vendor"
                        value={integration.native_finance_id || ''}
                        sx={{ width: '50%' }}
                        options={quickbooksLocations.map(location => ({
                          value: location.id,
                          label: location.name,
                        }))}
                        onChange={(e) => {
                          const nativeId = e.target.value;
                          handlePartnerSelectionChange(integration.id, nativeId);
                        }}
                      />
                    </Box>
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQuickbooksLocations([])}>Close</Button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default QuickbooksSetup;