import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import sheets_logo from '../assets/icons8-google-sheets-48.png';

const SheetsLink = () => {


  return (
    <>
      <Card sx={{ width: '250px', height: '95%', borderRadius: '15px' }}>
        <CardActionArea sx={{ height: '100%' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={sheets_logo} alt="Sheets Logo" style={{ width: '25px', height: '25px' }} />
            </Box>
            <Typography variant="body1" color="black">
              Google Sheets
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link data from Google Sheets.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card >
    </>
  )
}

export default SheetsLink;