import React from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  Tooltip,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import NotificationsDatagrid from './datagrid/NotificationsDatagrid';
import POCreationBox from './POCreationBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';

const SelectDropdown = ({ selectedValue, setSelectedValue, menuItems }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ml: '0.5rem',
        width: '220px',
      }}
      size='small'
    >
      <Select
        labelId="select-small-label"
        id="select-small"
        value={selectedValue}
        onChange={handleChange}
        IconComponent={() => <ArrowDropDownIcon style={{ color: 'white' }} />}
        sx={{
          color: 'white',
          border: '1px solid grey',
          height: '2.25rem',
          borderRadius: '10px',
          '&:focus': {
            borderColor: 'white',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function Overview({
  hqIntegration,
  alertsData,
  user,
  logo,
  logoBlob,
  materialsRows,
  productsRows,
  integrations,
  notifications,
  setNotifications,
  isLoading,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [inputIntegration, setInputIntegration] = React.useState("");
  const [inputIntegrationFrom, setInputIntegrationFrom] = React.useState("");
  const [inputPOName, setInputPOName] = React.useState("");
  const [selectedFilterValue, setSelectedFilterValue] = React.useState("All");
  const [inputItems, setInputItems] = React.useState([]);
  const [urgentRows, setUrgentRows] = React.useState(alertsData.filter(row => row.order_category === 'urgent'));
  const [neededSoonRows, setNeededSoonRows] = React.useState(alertsData.filter(row => row.order_category === 'consider'));
  const [inquireRows, setInquireRows] = React.useState(alertsData.filter(row => row.order_category === 'reminder'));


  React.useEffect(() => {
    if (selectedFilterValue === "All") {
      setUrgentRows(alertsData.filter(row => row.order_category === 'urgent'));
      setNeededSoonRows(alertsData.filter(row => row.order_category === 'consider'));
      setInquireRows(alertsData.filter(row => row.order_category === 'reminder'));
    } else {
      setUrgentRows(alertsData.filter(row => row.order_category === 'urgent' && row.item_type === selectedFilterValue));
      setNeededSoonRows(alertsData.filter(row => row.order_category === 'consider' && row.item_type === selectedFilterValue));
      setInquireRows(alertsData.filter(row => row.order_category === 'reminder' && row.item_type === selectedFilterValue));
    }
  }, [selectedFilterValue, alertsData]);


  return (
    <>
      <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ height: '100%', width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: '2rem', gap: '2rem' }}>
          <Box sx={{ height: '35%', width: '100%' }}>
            {logo && (
              <Avatar
                sx={{
                  bgcolor: 'white',
                  width: 'auto',
                  height: 'auto',
                  maxWidth: '250px',
                  maxHeight: '250px',
                  borderRadius: '15px',
                  mb: '2rem',
                  p: '1rem',
                  objectFit: 'contain',
                }}
                alt="logo"
                src={logo}
              />
            )}
            <Typography variant="h4" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}>
              {hqIntegration?.name}
            </Typography>
            <Typography variant="body1" sx={{ color: 'white', mb: '2rem', textAlign: 'left' }}>
              Welcome back, {user.email}!
            </Typography>
          </Box>
          <Box sx={{ height: '65%', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', alignContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Trinity is smartest when supply chain inputs are accurate and up-to-date." placement='right'>
                  <InfoOutlinedIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                </Tooltip>
                Platform To-Dos
              </Typography>
            </Box>
            <Divider sx={{ mb: '1rem', width: '100%', bgcolor: 'grey.700' }} />
            <NotificationsDatagrid
              logoBlob={logoBlob}
              rows={notifications}
              setRows={setNotifications}
              integrations={integrations}
              materialsRows={materialsRows}
              productsRows={productsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" sx={{ height: '100%', bgcolor: 'grey.700', my: '1rem' }} />
        <Box sx={{ height: '100%', width: '75%', px: '2rem', pt: '1rem' }}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
              Recommended Actions
            </Typography>
            <SelectDropdown
              selectedValue={selectedFilterValue}
              setSelectedValue={setSelectedFilterValue}
              menuItems={[
                { label: 'All', value: 'All' },
                { label: 'Materials', value: 'Material' },
                { label: 'Products', value: 'Product' },
              ]}
            />
          </Box>
          <Divider sx={{ mb: '1rem', width: '100%', bgcolor: 'grey.700' }} />
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'top', gap: '1rem' }}>
            <Box sx={{ width: '33%' }}>
              <Typography component="div" variant="body1" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Place order as soon as possible to prevent stock out." placement='right'>
                    <InfoOutlinedIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                  </Tooltip>
                  <b>Urgent</b>
                </Box>
                <Box sx={{ bgcolor: '#8B0000', borderRadius: '5px', width: '25px', p: '0.25rem', textAlign: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    <b>{urgentRows.length}</b>
                  </Typography>
                </Box>
              </Typography>
              <Divider sx={{ mb: '1rem', width: '100%', bgcolor: 'grey.700' }} />
              <Box
                sx={{
                  overflow: 'auto',
                  maxHeight: '75vh',
                  pr: '0.5rem',
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.800',
                    borderRadius: '15px',
                  },
                }}
              >
                {isLoading ? (
                  <>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={50}
                        sx={{ mb: '1rem', borderRadius: '15px', bgcolor: 'grey.800' }}
                      />
                    ))}
                  </>
                ) : (
                  urgentRows.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setInputItems(item.items);
                        setInputPOName(item.auto_po);
                        setInputIntegration(integrations.find((integration) => integration.id === item.integration_id));
                        setInputIntegrationFrom(integrations.find((integration) => integration.id === item.integration_id_from));
                        setOpenDialog(true);
                      }}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        bgcolor: '#8B0000',
                        borderRadius: '15px',
                        border: '1px solid grey',
                        mb: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: '#6B0000',
                        },
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: 'white', textAlign: 'left', p: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        {item.item_type === 'Product' ? (
                          <WidgetsIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        ) : (
                          <OilBarrelIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        )}
                        <b>{item.auto_po}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        <SendIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>{item.partner_name_from}</b>
                        <ArrowForwardIcon fontSize="small" sx={{ color: 'white', mx: '0.5rem' }} />
                        <b>{item.partner_name}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        <EventIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>Order by {item.next_order_date ? format(new Date(item.next_order_date), 'yyyy-MM-dd') : ''}</b>
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}>
                        <EmailIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>{item.contact_email}</b>
                      </Typography>
                      {item.items.map((line_item, index) => (
                        <Typography key={index} variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '2rem', display: 'flex', alignItems: 'center' }}>
                          {index + 1}. {line_item.item_name} [{line_item.item_sku}]
                        </Typography>
                      ))}
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box sx={{ width: '33%' }}>
              <Typography component="div" variant="body1" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="An order is likely needed in next two weeks." placement='right'>
                    <InfoOutlinedIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                  </Tooltip>
                  <b>Coming up</b>
                </Box>
                <Box sx={{ bgcolor: '#996B0C', borderRadius: '5px', width: '25px', p: '0.25rem', textAlign: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    <b>{neededSoonRows.length}</b>
                  </Typography>
                </Box>
              </Typography>
              <Divider sx={{ mb: '1rem', width: '100%', bgcolor: 'grey.700' }} />
              <Box
                sx={{
                  overflow: 'auto',
                  maxHeight: '75vh',
                  pr: '0.5rem',
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.800',
                    borderRadius: '15px',
                  },
                }}
              >
                {isLoading ? (
                  <>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={50}
                        sx={{ mb: '1rem', borderRadius: '15px', bgcolor: 'grey.800' }}
                      />
                    ))}
                  </>
                ) : (
                  neededSoonRows.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setInputItems(item.items);
                        setInputPOName(item.auto_po);
                        setInputIntegration(integrations.find((integration) => integration.id === item.integration_id))
                        setInputIntegrationFrom(integrations.find((integration) => integration.id === item.integration_id_from))
                        setOpenDialog(true)
                      }}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        bgcolor: '#996B0C',
                        borderRadius: '15px',
                        border: '1px solid grey',
                        mb: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: '#8B6F3A',
                        },
                      }}>
                      <Typography
                        variant="body1"
                        sx={{ color: 'white', textAlign: 'left', p: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        {item.item_type === 'Product' ? (
                          <WidgetsIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        ) : (
                          <OilBarrelIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        )}
                        <b>{item.auto_po}</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}
                      >
                        <SendIcon fontSize="small" sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>{item.partner_name_from}</b>
                        <ArrowForwardIcon fontSize="small" sx={{ color: 'white', mx: '0.5rem' }} />
                        <b>{item.partner_name}</b>
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}>
                        <EventIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>Order by {item.next_order_date ? format(new Date(item.next_order_date), 'yyyy-MM-dd') : ''}</b>
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}>
                        <EmailIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>{item.contact_email}</b>
                      </Typography>
                      {item.items.map((line_item, index) => (
                        <Typography key={index} variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '2rem', display: 'flex', alignItems: 'center' }}>
                          {index + 1}. {line_item.item_name} [{line_item.item_sku}]
                        </Typography>
                      ))}
                    </Box>
                  ))
                )}
              </Box>
            </Box>
            <Box sx={{ width: '33%' }}>
              <Typography variant="body1" component="div" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Consider proactively reaching out to partner as an order will likely be placed in the in next 30 days." placement='right'>
                    <InfoOutlinedIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                  </Tooltip>
                  <b>Reminders</b>
                </Box>
                <Box sx={{ bgcolor: '#27496D', borderRadius: '5px', width: '25px', p: '0.25rem', textAlign: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    <b>{inquireRows.length}</b>
                  </Typography>
                </Box>
              </Typography>
              <Divider sx={{ mb: '1rem', width: '100%', bgcolor: 'grey.700' }} />
              <Box
                sx={{
                  overflow: 'auto',
                  maxHeight: '75vh',
                  pr: '0.5rem',
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.800',
                    borderRadius: '15px',
                  },
                }}
              >
                {isLoading ? (
                  <>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={50}
                        sx={{ mb: '1rem', borderRadius: '15px', bgcolor: 'grey.800' }}
                      />
                    ))}
                  </>
                ) : (
                  inquireRows.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: '100%',
                        height: 'auto',
                        bgcolor: '#27496D',
                        borderRadius: '15px',
                        border: '1px solid grey',
                        mb: '1rem',
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: '#1B3D5D',
                        },
                      }}>
                      <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', p: '1rem', display: 'flex', alignItems: 'center' }}>
                        <TipsAndUpdatesIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                        Confirm lead times. Order to {item.partner_name} likely needed in the next month.
                      </Typography>
                      {item.items.map((line_item, index) => (
                        <Typography key={index} variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '2rem', display: 'flex', alignItems: 'center' }}>
                          {index + 1}. {line_item.item_name} [{line_item.item_sku}]
                        </Typography>
                      ))}
                      <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', pb: '1rem', pl: '1rem', display: 'flex', alignItems: 'center' }}>
                        <EmailIcon fontSize='small' sx={{ color: 'white', mr: '0.5rem' }} />
                        <b>Send email to {item.contact_email}</b>
                      </Typography>
                    </Box>
                  )))
                }
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth={"lg"}
        >
          <DialogTitle>Create PO</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh', maxHeight: '50vh' }}>
              <POCreationBox
                logoBlob={logoBlob}
                poNumber={inputPOName}
                integrations={integrations}
                productsRows={productsRows}
                materialsRows={materialsRows}
                setSnackbarAlert={setSnackbarAlert}
                inputIntegration={inputIntegration}
                inputIntegrationFrom={inputIntegrationFrom}
                inputIds={inputItems}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                setRows={null}
                onClose={() => setOpenDialog(false)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
