
import { axiosInstance, checkToken } from './utils.js'

const fetchSharedPartners = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/list_partner_options/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchSharedMaterials = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/materials_inventory_share/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchSharedProducts = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/inventory_share/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchSharedProductOrders = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/product_orders_share/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchSharedDocs = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/shared_documents/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateInventoryEntry = async (newRow) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/inventory_share/`, newRow);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateMaterialInventoryEntry = async (newRow) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/materials_inventory_share/`, newRow);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const createIntegrationShare = async (share_to_email, integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/integrations_share/`, { integration_id: integration_id, share_to_email: share_to_email });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchIntegrationShare = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/list_shared/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const deleteIntegrationShare = async (id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/list_shared/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export {
  fetchSharedPartners,
  fetchSharedMaterials,
  fetchSharedProducts,
  fetchSharedProductOrders,
  updateInventoryEntry,
  updateMaterialInventoryEntry,
  createIntegrationShare,
  fetchIntegrationShare,
  deleteIntegrationShare,
  fetchSharedDocs
};
