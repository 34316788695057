import * as React from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Skeleton,
  Divider
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InventoryTrend from '../components/charts/InventoryTrend';
import BasicPieChart from '../components/charts/BasicPieChart';
import InventoryBar from '../components/charts/InventoryBar';
import { fetchInventoryTrend, fetchSalesTrend } from '../api/forecast';

const SelectDropdown = ({ selectedValue, setSelectedValue, menuItems }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ml: '0.5rem',
        width: '220px',
      }}
      size='small'
    >
      <Select
        labelId="select-small-label"
        id="select-small"
        value={selectedValue}
        onChange={handleChange}
        IconComponent={() => <ArrowDropDownIcon style={{ color: 'white' }} />}
        sx={{
          color: 'white',
          border: '1px solid grey',
          height: '2.25rem',
          borderRadius: '10px',
          '&:focus': {
            borderColor: 'white',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function Trends() {
  const [chartGrouping, setChartGrouping] = React.useState('partner');
  const trendType = 'Inventory';
  const [isLoading, setIsLoading] = React.useState(false);
  const [chartHistory, setChartHistory] = React.useState('30');
  const [isStacked, setIsStacked] = React.useState(false);
  const [trendRows, setTrendRows] = React.useState(null);
  const [inventoryRows, setInventoryRows] = React.useState(null);
  const [totalMetric, setTotalMetric] = React.useState('');
  const [avgUsage, setAvgUsage] = React.useState('');
  const [totalMetricDollars, setTotalMetricDollars] = React.useState('');
  const [focusFilter, setFocusFilter] = React.useState('');
  const [focusFilterOptions, setFocusFilterOptions] = React.useState([]);

  React.useEffect(() => {
    const fetchTrendsData = async () => {
      setIsLoading(true);
      try {
        if (trendType === 'Inventory') {
          const data = await fetchInventoryTrend(chartGrouping, chartHistory);
          const groupKeys = Object.keys(data.trend.groups);
          const menuItems = [
            { label: 'All', value: '' },
            ...groupKeys.map(key => ({
              label: key,
              value: key
            }))
          ];
          if (focusFilter) {
            if (data.trend.groups.hasOwnProperty(focusFilter)) {
              data.trend.groups = {
                [focusFilter]: data.trend.groups[focusFilter]
              };
            } else {
              setFocusFilter('');
            }
          }
          setFocusFilterOptions(menuItems);
          setTrendRows(data.trend);
          setInventoryRows(data.breakdown);
          setTotalMetric(data.summary_stats.total);
          setTotalMetricDollars(data.summary_stats.total_dollars);
          setAvgUsage(data.summary_stats.avg_consumption);
        } else if (trendType === 'Sales') {
          const data = await fetchSalesTrend(chartGrouping, chartHistory);
          const groupKeys = Object.keys(data.trend.groups);
          const menuItems = [
            { label: 'All', value: '' },
            ...groupKeys.map(key => ({
              label: key,
              value: key
            }))
          ];
          if (focusFilter) {
            if (data.trend.groups.hasOwnProperty(focusFilter)) {
              data.trend.groups = {
                [focusFilter]: data.trend.groups[focusFilter]
              };
            } else {
              setFocusFilter('');
            }
          }
          setFocusFilterOptions(menuItems);
          setTrendRows(data.trend);
          setInventoryRows(data.breakdown);
          setTotalMetric(data.summary_stats.total);
          setTotalMetricDollars(data.summary_stats.total_dollars);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTrendsData();
  }, [chartGrouping, chartHistory, trendType, focusFilter]);


  return (
    <>
      <Box sx={{ height: '30%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ height: '100%', width: '25%', py: '1rem', pl: '2rem' }}>
          <Typography variant="h6" color={'white'} sx={{ mb: '1rem' }}>Trends Dashboard</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Group by:</Typography>
            <SelectDropdown
              selectedValue={chartGrouping}
              setSelectedValue={setChartGrouping}
              menuItems={[
                { label: 'Partner', value: 'partner' },
                { label: 'Product', value: 'product' },
              ]} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Past:</Typography>
            <SelectDropdown
              selectedValue={chartHistory}
              setSelectedValue={setChartHistory}
              menuItems={[
                { label: 'Week', value: '7' },
                { label: 'Month', value: '30' },
                { label: 'Quarter', value: '90' },
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Focus:</Typography>
            <SelectDropdown
              selectedValue={focusFilter}
              setSelectedValue={setFocusFilter}
              menuItems={focusFilterOptions}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="subtitle1" color={'white'} sx={{ minWidth: '100px' }}>Show trend as stacked:</Typography>
            <Checkbox
              checked={isStacked}
              onChange={(event) => setIsStacked(event.target.checked)}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{ height: '100%', width: '75%', p: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridTemplateRows: '1fr', gap: '1rem' }}>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: '0.5rem' }}> {trendType === 'Inventory' ? 'Total Inventory (3PL & Distributor)' : 'Average Daily Sales'} </Typography>
            <Divider sx={{ mb: isLoading ? '2rem' : '1rem', width: '100%', bgcolor: 'grey.700' }} />
            {isLoading ? (
              <>
                <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700' }} />
              </>
            ) : trendType === 'Inventory' ? (
              <>
                <Typography variant="h5" color={'white'} sx={{ mb: '0.5rem' }}><b>{totalMetric.toLocaleString('en-US')} units / ${totalMetricDollars.toLocaleString('en-US')}</b></Typography>
                <Typography variant="h5" color={'white'}><b>{(totalMetric / avgUsage).toFixed(1)} days of inventory</b></Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" color={'white'} sx={{ mb: '0.5rem' }}><b>{totalMetric.toLocaleString('en-US')} units / ${totalMetricDollars.toLocaleString('en-US')}</b></Typography>
                <Typography variant="h5" color={'white'}><b>${(totalMetricDollars / totalMetric).toFixed(2).toLocaleString('en-US')} average unit price</b></Typography>
              </>
            )}
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', p: '1rem' }}>
            <Box sx={{ height: '15vh', width: isLoading ? '100%' : '125%' }}>
              <Typography variant="body1" color={'white'} sx={{ mb: '0.5rem' }}>{trendType} Mix</Typography>
              <Divider sx={{ mb: isLoading ? '2rem' : '1rem', width: '80%', bgcolor: 'grey.700' }} />
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                inventoryRows && inventoryRows.length > 0 && (
                  <BasicPieChart plotData={inventoryRows} />
                )
              )}
            </Box>
          </Box>
          <Box sx={{ border: '0.5px solid grey', borderRadius: '10px', px: '1rem', pt: '1rem', height: '100%' }}>
            <Typography variant="body1" color={'white'} sx={{ mb: '0.5rem' }}>{trendType} Details</Typography>
            <Divider sx={{ mb: isLoading ? '2rem' : '1rem', width: '100%', bgcolor: 'grey.700' }} />
            <Box sx={{ height: '15vh', width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700', mb: '1rem' }} />
                  <Skeleton variant="rounded" height={15} sx={{ backgroundColor: 'grey.700' }} />
                </>
              ) : (
                inventoryRows && inventoryRows.length > 0 && (
                  <InventoryBar plotData={inventoryRows} axisLabel={chartGrouping} />
                )
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: '70%', width: '100%', p: '2rem' }}>
        <Typography variant="h6" color={'white'} sx={{ ml: '2rem' }}>Inventory Trend</Typography>
        {trendRows && trendRows.date && trendRows.date.length > 0 && (
          <InventoryTrend
            plotData={trendRows}
            isStacked={isStacked}
          />
        )}
      </Box>
    </>
  );
}