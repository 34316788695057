import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { createIntegration } from '../api/integrations';
import CustomTextField from './CustomTextField';
import LabeledDropdown from './LabeledDropdown';

const CreatePartnerDialog = ({
  dialogOpen,
  handleClose,
  integrationTypes,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) => {
  const [name, setName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [type, setType] = useState('');
  const [typeName, setTypeName] = useState('');

  const handleTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    const selectedType = integrationTypes.find(type => type.id === selectedTypeId);
    setType(selectedTypeId);
    setTypeName(selectedType.type)
  };


  const handleSubmit = async () => {
    try {
      const newIntegration = {
        integration_type_id: type,
        name,
        contact_name: '',
        is_brand_operated: false,
        is_3pl: null,
        is_supplier: false,
        minimum_order_qty: null,
        minimum_order_units: '',
        default_lead_time: null,
        contact_title: '',
        contact_email: contactEmail,
        street_address: '',
        billing_address: '',
        config: {},
        coordinates: {}
      };

      const integrationSuccess = await createIntegration(newIntegration);
      setIntegrations((prevIntegrations) => [...prevIntegrations, integrationSuccess]);
      setName('');
      setContactEmail('');
      setType('');
      setTypeName('');
      handleClose();
    } catch (error) {
      console.error('Error creating integration:', error);
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage(error.response.data.detail);
    }
  };


  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{
        style: {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <DialogTitle>Add Partner</DialogTitle>
      <DialogContent>
        <LabeledDropdown
          label="Type"
          value={type}
          sx={{ my: '0.25rem' }}
          options={integrationTypes.map(row => ({ value: row.id, label: row.type }))}
          onChange={handleTypeChange}
        />
        <CustomTextField
          label={"Partner Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <CustomTextField
          label="Contact Email"
          disabled={typeName === 'HQ'}
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        <Typography variant="body2" sx={{ mt: '0.5rem' }}>
          Trinity will automatically sync documents from <b>all emails associated with this partner's domain.</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePartnerDialog;
