import React, { useState, useRef } from 'react';
import { Box, Button, Checkbox, IconButton, Tooltip, TextField, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { createMaterial, updateMaterial, deleteMaterial, fetchRecipeReverse, uploadFile } from '../../api/products';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UploadIcon from '@mui/icons-material/Upload';
import CircleIcon from '@mui/icons-material/Circle';
import { fetchShipments } from '../../api/shipments';
import ShipmentsDatagrid from './ShipmentsDatagrid';
import WidgetsIcon from '@mui/icons-material/Widgets';


export default function MaterialsDialogDatagrid({
  integrationId,
  productsRows,
  materialsRows,
  deliveredIds,
  materialsRecipesIds,
  setMaterialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  height = '65vh',
  marginTop = false,
  loading = null,
  setToggleView = null
}) {
  const [reverseRecipeRows, setReverseRecipeRows] = useState([]);
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await uploadFile('materials', integrationId, formData);
        setMaterialsRows((prevRows) => [...prevRows, ...response]);
        setSnackbarOpen(true);
        setSnackbarMessage("Uploaded successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        console.error('Error uploading file:', error);
        setSnackbarOpen(true);
        setSnackbarMessage("Uploaded unsuccessful, please check that your data structure mataches the template.");
        setSnackbarAlert("error");
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleAddMaterial = async () => {
    try {
      const createdMaterial = await createMaterial(
        {
          integration_id: integrationId,
          sku: 'New Sku',
          name: 'New Material',
          is_packaging: 'false'
        }
      );
      setMaterialsRows(prevMaterials => [...prevMaterials, createdMaterial]);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleReverseLookupClick = async (id) => {
    try {
      const reverseRecipes = await fetchRecipeReverse(id);
      setReverseRecipeRows(reverseRecipes);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await deleteMaterial(id);
      setMaterialsRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateMaterial(newRow.id, newRow);
      updatedRow.last_updated = data.last_updated;
      setMaterialsRows(materialsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error('Error updating row:', error);
      return null;
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, null, null, row.id, "True");
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Item', flex: 1, editable: true },
    { field: 'sku', headerName: 'SKU', flex: 1, editable: true },
    {
      field: 'is_packaging',
      headerName: 'Packaging?',
      flex: 0.4,
      editable: true,
      renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_packaging: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'default_price',
      headerName: 'Default Cost ($)',
      flex: 0.5,
      editable: true,
      renderCell: (params) => (
        params.value ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value) : ''
      ),
    },
    { field: 'usage_uom', headerName: 'Usage UoM', flex: 0.5, editable: true },
    { field: 'purchasing_uom', headerName: 'Purchase UoM', flex: 0.5, editable: true },
    {
      field: 'conversion',
      headerName: 'Unit Conversion Rate',
      flex: 0.75,
      editable: true,
      renderCell: (params) => {
        const handleChange = (event) => {
          const updatedValue = event.target.value;
          const updatedRow = {
            ...params.row,
            conversion: updatedValue,
          };
          processRowUpdate(updatedRow, params.row);
        };

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              width: '100%',
              height: '100%',
            }}
          >
            {params.row.purchasing_uom && params.row.usage_uom ? (
              <>
                <Typography variant="body1" sx={{ mx: 1, fontSize: 'inherit' }}>
                  {'1 ' + params.row.purchasing_uom + ' = '}
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={params.row.conversion}
                  onChange={handleChange}
                  sx={{
                    width: '100px',
                    mx: '5px',
                    '& .MuiInputBase-root': {
                      height: '30px',
                      fontSize: 'inherit',
                    },
                  }}
                />
                <Typography variant="body1" sx={{ fontSize: 'inherit' }}>
                  {params.row.usage_uom}
                </Typography>
              </>
            ) : (
              <Typography variant="body1" color="red" sx={{ fontSize: 'inherit' }}>
                {'<-'} Fill out usage / purchasing UoM
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {deliveredIds.some(delivered => delivered.id === params.row.id) && (
            <Tooltip title="Lot Details">
              <IconButton
                color='inherit'
                onClick={() => handleFetchInventoryDetails(params.row)}
              >
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
          )}
          {materialsRecipesIds.some(recipe => recipe.id === params.row.id) && (
            <Tooltip title="Used in BOMs">
              <IconButton
                color="inherit"
                onClick={() => handleReverseLookupClick(params.row.id)}
              >
                <FormatListNumberedIcon />
              </IconButton>
            </Tooltip>
          )}
          {(params.row.inventory_count === 0 && params.row.orders_count === 0) && (
            <>
              <Tooltip title="This product is not currenty used in your supply chain.">
                <IconButton
                  sx={{ color: '#0249c1' }}
                >
                  <CircleIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <IconButton
                color="inherit"
                onClick={() => handleDeleteClick(params.row.id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          )}
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const recipeColumns = [
    {
      field: 'product_id',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => {
        const productRow = productsRows.find(row => row.id === params.value);
        return productRow ? productRow.name : '';
      }
    },
    {
      field: 'qty',
      headerName: 'Quantity Used',
      flex: 1,
      renderCell: (params) => {
        const materialRow = materialsRows.find(row => row.id === params.row.material_id);
        return materialRow ? `${params.row.qty} ${materialRow.usage_uom}` : '';
      }
    },
  ]

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer width={'100%'}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Box display={'flex'} gap={'0.5rem'}>
            <Button
              color="inherit"
              variant='outlined'
              startIcon={<AddIcon />}
              onClick={handleAddMaterial}
              sx={{ mb: '2px' }}
            >
              Add Material
            </Button>
            {setToggleView && (
              <Button
                variant='contained'
                startIcon={<WidgetsIcon />}
                sx={{ mb: '2px', bgcolor: "#0249c1" }}
                onClick={() => setToggleView('products')}
              >
                Products View
              </Button>
            )}
          </Box>
          <Box display={'flex'} gap={'0.5rem'}>
            <Button
              color="inherit"
              variant='text'
              startIcon={<PostAddIcon />}
              sx={{ mb: '2px' }}
              href="https://trinity3-public.s3.us-east-1.amazonaws.com/MaterialsTemplate.xlsx"
              target="_blank"
              download
            >
              Get Upload Template
            </Button>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<UploadIcon />}
                sx={{ mb: '2px' }}
                onClick={handleButtonClick}
              >
                Quick Upload
              </Button>
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  };

  const RecipesToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<CloseIcon />}
          onClick={() => {
            setReverseRecipeRows([])
          }}
        >
          Close
        </Button>
      </GridToolbarContainer>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setInventoryDetailsRows([])}
        >
          Back to table
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: { height }, maxHeight: { height } }}>
        {inventoryDetailsRows.length === 0 ? (
          <>
            <Box
              sx={{
                width: reverseRecipeRows.length === 0 ? '100%' : '70%',
                height: { height },
                maxHeight: { height },
                mt: marginTop ? '1rem' : 0,
                mr: reverseRecipeRows.length > 0 ? '1rem' : '0'
              }}
            >
              <DataGrid
                rows={materialsRows}
                columns={columns}
                density='compact'
                hideFooter
                loading={loading}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: CustomToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            {
              reverseRecipeRows.length > 0 && (
                <Box sx={{ width: '29%', height: '100%', mt: marginTop ? '1rem' : 0 }}>
                  <DataGrid
                    rows={reverseRecipeRows}
                    columns={recipeColumns}
                    density='compact'
                    hideFooter
                    slots={{
                      toolbar: RecipesToolbar,
                    }}
                    sx={{
                      backgroundColor: 'white',
                      '& .MuiDataGrid-row': {
                        color: 'black',
                      },
                    }}
                  />
                </Box>
              )
            }
          </>
        ) : (
          <Box sx={{ width: '100%', height: '100%', mt: marginTop ? '1rem' : 0, }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
              hideCols={{ carrier: false, bol_string: false, tracking_number: false, pick_up_date: false, is_delivered: false }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
