import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { updateInventoryEntry, updateMaterialInventoryEntry } from '../../api/sharing';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

export default function SharedInventory({
  rows,
  setRows,
  selectedView,
  loading
}) {

  const columns = [
    {
      field: 'name',
      headerName: 'Item',
      flex: 1,
      renderCell: (params) => (
        <>
          <span>{params.value} [{params.row.sku}]</span>
        </>
      )
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 1,
      cellClassName: 'super-app-theme-editable--cell',
      editable: selectedView === 'orders' ? false : true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    { field: 'purchasing_uom', headerName: 'UoM', flex: 1 },
    { field: 'edited_by', headerName: 'Updated By', flex: 1 },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => {
        const date = params.value ? new Date(params.value) : null;
        const zonedDate = date ? fromZonedTime(date, 'UTC') : null;
        return date ? format(zonedDate, 'MM/dd/yy HH:mm') : '';
      },
    }
  ]

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      let data;
      if (selectedView === 'products') {
        data = await updateInventoryEntry(newRow);
      } else if (selectedView === 'materials') {
        data = await updateMaterialInventoryEntry(newRow);
      }
      updatedRow.last_updated = data.last_updated;
      updatedRow.edited_by = data.edited_by;
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error('Error updating row:', error);
      return null;
    }
  };


  const processRowUpdateError = (error) => {
    console.error('Error updating row:', error);
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        bgcolor: 'white',
        '& .super-app-theme-editable--cell': {
          backgroundColor: 'grey.100',
          fontWeight: '600',
        },
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          density='compact'
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={processRowUpdateError}
          disableRowSelectionOnClick
          hideFooter
        />
      </Box>
    </>
  );
}
