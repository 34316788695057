import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import woocommerce_logo from '../assets/woocommerce_logo.png';

const WooCommerceLink = () => {

  return (
    <>
      <Card sx={{ width: '250px', height: '95%', borderRadius: '15px' }}>
        <CardActionArea sx={{ height: '100%' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={woocommerce_logo} alt="Woocommerce Logo" style={{ width: '25px', height: '25px' }} />
            </Box>
            <Typography variant="body1" color="black">
              WooCommerce
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link to WooCommerce inventory.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default WooCommerceLink;