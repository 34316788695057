// notifications.js

import { axiosInstance, checkToken } from './utils.js'

const fetchNotifications = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/notifications/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching notifications:', error);
    throw error;
  }
};

const fetchSources = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/data_sources/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data sources:', error);
    throw error;
  }
};

const deleteSource = async (id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/data_source/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data sources:', error);
    throw error;
  }
};


export { fetchNotifications, fetchSources, deleteSource };
