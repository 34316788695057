import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { fetchAuthSession } from 'aws-amplify/auth';
const API_BASE_URL = process.env.REACT_APP_API_URL;


const axiosInstance = (token) => {
  const orgId = localStorage.getItem('orgId');

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (orgId !== null && orgId !== '') {
    headers['X-ORG-ID'] = orgId;
  }

  return axios.create({
    baseURL: API_BASE_URL,
    headers,
  });
};

const checkToken = async () => {
  try {
    const token = localStorage.getItem('apiToken');
    if (!token) {
      console.log('missing token');
      const session = await fetchAuthSession();
      const idToken = session.tokens.idToken.toString()
      localStorage.setItem('apiToken', idToken);
      return (idToken);
    }
    const decodedToken = jwtDecode(token);
    const now = Date.now() / 1000;
    if (decodedToken.exp < now) {
      const session = await fetchAuthSession();
      const idToken = session.tokens.idToken.toString()
      localStorage.setItem('apiToken', idToken);
      return (idToken);
    }
    else {
      return token
    }
  } catch (error) {
    console.log('expired');
  }
};

export { axiosInstance, checkToken };
