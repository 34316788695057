import { axiosInstance, checkToken } from './utils.js'


const fetchOrders = async (integration_id, type = 'product', statuses = [], order_name = null) => {
  const params = new URLSearchParams();
  if (statuses.length) params.append('status', statuses.join(','));
  if (order_name) params.append('order_name', order_name);
  const queryString = params.toString();
  const url = `/${type}_orders/${integration_id}` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchNextOrder = async (type = 'product') => {
  try {
    const url = `/next_${type}_order/`;
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchOrdersTo = async (integration_id, type = 'product', status = null, id = null) => {
  try {
    const params = new URLSearchParams();
    if (status) params.append('status', status);
    if (id) params.append('id', id);
    const queryString = params.toString();
    const url = `/${type}_orders_to/${integration_id}` + (queryString ? `?${queryString}` : '');
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchOrdersByItem = async (item_id, type = 'product') => {
  try {
    const url = `/${type}_orders_by_${type}/${item_id}`;
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchOrderLogs = async (order_id, type = 'product') => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/${type}_orders_log/${order_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchAllOrders = async (status = null, order_name = null) => {
  try {
    const params = new URLSearchParams();
    if (status) params.append('status', status);
    if (order_name) params.append('order_name', order_name);
    const queryString = params.toString();
    const url = `/orders/` + (queryString ? `?${queryString}` : '');
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createOrders = async (orders, type = 'product') => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/${type}_orders/`, orders);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateOrder = async (order_id, updated_order, type = 'product') => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/${type}_orders/${order_id}`, updated_order);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateOrderStatus = async (order_id, status, type = 'product') => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/${type}_order_status/${order_id}/${status}`, status);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const deleteOrder = async (order_id, type = 'product') => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/${type}_orders/${order_id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const triggerPoEmail = async (po_data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/po_email/', po_data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const triggerPdfPreview = async (po_data) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/po_pdf_preview/', po_data);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export {
  fetchOrders,
  fetchOrdersTo,
  fetchNextOrder,
  fetchOrdersByItem,
  fetchOrderLogs,
  fetchAllOrders,
  updateOrder,
  updateOrderStatus,
  createOrders,
  deleteOrder,
  triggerPoEmail,
  triggerPdfPreview
};
