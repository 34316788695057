import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import webflow_logo from '../assets/webflow.svg';

const WebFlowLink = () => {
  // const authUri = `https://webflow.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_WEBFLOW_CLIENT}&scope=ecommerce%3Aread+cms%3Aread+sites%3Aread`;

  // const handleOAuthLogin = () => {
  //   window.location.href = authUri;
  // };


  return (
    <>
      <Card sx={{ width: '250px', height: '95%', borderRadius: '15px' }}>
        <CardActionArea sx={{ height: '100%' }} >
          {/* onClick={handleOAuthLogin}> */}
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={webflow_logo} alt="Webflow Logo" style={{ width: '25px', height: '25px' }} />
            </Box>
            <Typography variant="body1" color="black">
              WebFlow
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link inventory from WebFlow ecommerce.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card >
    </>
  )
}

export default WebFlowLink;