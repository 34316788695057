import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
  TextField
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { deleteIntegration, createIntegration } from '../../api/integrations';
import CreateIntegrationDialog from '../CreateIntegrationDialog';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SaveIcon from '@mui/icons-material/Save';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';


export default function IntegrationMainDatagrid({
  integrations,
  setIntegrations,
  integrationTypes,
  setSelectedIntegrationId,
  setDialogOpen,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading,
  isDialogView = false
}) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');


  const handleChartClick = (id) => {
    setSelectedIntegrationId(id);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  const handleDeleteClick = (id) => {
    setDeleteDialogOpen(true);
    setSelectedId(id);
  };

  const handleDeleteConfirm = async () => {
    try {
      const integrationDeleteSuccess = await deleteIntegration(selectedId);
      console.log(integrationDeleteSuccess)
      setIntegrations((prevIntegrations) => prevIntegrations.filter(integration => integration.id !== selectedId));
      setDeleteDialogOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage("Integration deleted.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setSelectedId('')
  };


  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'integration_type',
      headerName: 'Type',
      flex: 1,
      valueGetter: (value, row) => row.integration_type.type,
      headerClassName: 'super-app-theme--header'
    },
    { field: 'contact_name', headerName: 'Contact Name', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'contact_email', headerName: 'Email', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions',
      headerName: isDialogView ? 'Orders' : 'Actions',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {isDialogView ? (
            <>
              <IconButton
                color="inherit"
                onClick={() => setSelectedIntegrationId(params.row.id)}
              >
                <DocumentScannerIcon />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => handleDeleteClick(params.row.id)}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Tooltip title="View Partner Card">
                <IconButton
                  color="inherit"
                  onClick={() => handleChartClick(params.row.id)}
                >
                  <BarChartIcon />
                </IconButton>
              </Tooltip>
              {params.row.integration_type.type !== 'HQ' && (
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteClick(params.row.id)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              )}
            </>
          )}
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const filteredColumns = isDialogView
    ? columns.filter(col => col.field !== 'integration_type')
    : columns;

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() => setCreateDialogOpen(true)}
        >
          Add Partner
        </Button>
      </GridToolbarContainer>
    );
  };

  const CustomerToolbar = React.memo(() => {
    const [addingCustomer, setAddingCustomer] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerContactName, setCustomerContactName] = useState('');
    const [customerContactEmail, setCustomerContactEmail] = useState('');

    const handleAddCustomer = async () => {
      console.log(customerName, customerContactName, customerContactEmail);
      const data = await createIntegration(
        {
          name: customerName,
          integration_type_id: integrationTypes.find((row) => row.type === 'Customer').id,
          contact_name: customerContactName,
          contact_email: customerContactEmail,
          street_address: '',
          config: {},
          coordinates: {}
        },
      );
      setIntegrations((prevIntegrations) => [...prevIntegrations, data]);
      setAddingCustomer(false);
      setCustomerName('');
      setCustomerContactName('');
      setCustomerContactEmail('');
    };

    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant="outlined"
          startIcon={<AddShoppingCartIcon />}
          onClick={() => setAddingCustomer(!addingCustomer)}
        >
          Add Customer
        </Button>
        {addingCustomer && (
          <>
            <TextField
              label="Customer Name"
              size="small"
              autoComplete='off'
              value={customerName}
              variant="outlined"
              onChange={(e) => setCustomerName(e.target.value)}
            />
            <TextField
              label="Contact Name"
              size="small"
              autoComplete='off'
              value={customerContactName}
              variant="outlined"
              onChange={(e) => setCustomerContactName(e.target.value)}
            />
            <TextField
              label="Contact Email"
              value={customerContactEmail}
              size="small"
              autoComplete='off'
              variant="outlined"
              onChange={(e) => setCustomerContactEmail(e.target.value)}
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleAddCustomer}
            >
              Save
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => setAddingCustomer(!addingCustomer)}
            >
              Cancel
            </Button>
          </>
        )}
      </GridToolbarContainer>
    );
  });


  return (
    <>
      <DataGrid
        rows={integrations}
        columns={filteredColumns}
        loading={loading}
        hideFooter
        density='compact'
        slots={{
          toolbar: isDialogView ? CustomerToolbar : Toolbar,
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row': {
            color: 'black',
          },
        }}
      />
      <CreateIntegrationDialog
        dialogOpen={createDialogOpen}
        handleClose={handleClose}
        integrationTypes={integrationTypes.filter(integration => integration.type !== 'HQ')}
        setIntegrations={setIntegrations}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
      />
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Integration</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete?
            <br />
            <br />
            <b>This will also delete all associated inventory and orders associate with this partner.</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={() => handleDeleteConfirm()}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )

}