import React from 'react';
import {
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
} from '@mui/material';
import tiktik_logo from '../assets/icons8-tik-tok-50.png';

const TikTokLink = () => {

  // const handleTikTokLogin = () => {
  //   window.location.href = 'https://services.tiktokshops.us/open/authorize?service_id=7417687763872220970';
  // };

  return (
    <>
      <Card sx={{ width: '250px', height: '95%', borderRadius: '15px' }}>
        <CardActionArea sx={{ height: '100%' }} >
          {/* onClick={handleTikTokLogin} */}
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={tiktik_logo} alt="TikTok Logo" style={{ width: '25px', height: '25px' }} />
            </Box>
            <Typography variant="body1" color="black">
              TikTok Shop
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link inventory from TikTok shop.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

export default TikTokLink;