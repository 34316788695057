import React from 'react';
import { Box, IconButton, Button, MenuItem, Select, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { createRecipe, updateRecipe, deleteRecipe } from '../../api/products';

const RecipeBuilder = ({
  recipeRows,
  setRecipeRows,
  selectedProductId,
  setSelectedProductId,
  selectedProductName,
  productsRows,
  materialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  setDialogOpen = null
}) => {

  const handleDeleteRecipeClick = async (id) => {
    try {
      const deleteSuccess = await deleteRecipe(id);
      console.log(deleteSuccess)
      setRecipeRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handleMaterialSelect = async (event) => {
    const createdRecipe = await createRecipe(
      {
        product_id: selectedProductId,
        material_id: event.target.value,
        product_id_ref: null,
        qty: null,
      }
    );
    setRecipeRows(prevRecipes => [...prevRecipes, createdRecipe]);
  };

  const handleProductSelect = async (event) => {
    const createdRecipe = await createRecipe(
      {
        product_id: selectedProductId,
        material_id: null,
        product_id_ref: event.target.value,
        qty: null,
      }
    );
    setRecipeRows(prevRecipes => [...prevRecipes, createdRecipe]);
  };

  const recipeColumns = [
    {
      field: 'name', headerName: 'Name', flex: 1, renderCell: (params) => {
        const selectedOption = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        const isMaterial = params.row.material_id !== null;
        const value = selectedOption ? selectedOption.name : '';

        return (
          value && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isMaterial ? <OilBarrelIcon style={{ marginRight: 4 }} /> : <WidgetsIcon style={{ marginRight: 4 }} />}
              {value}
            </div>
          )
        );
      }
    },
    {
      field: 'sku', headerName: 'SKU', flex: 1, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedMaterial ? selectedMaterial.sku : '';
      }
    },
    { field: 'qty', headerName: 'Qty', flex: 0.5, editable: true },
    {
      field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedMaterial ? selectedMaterial.usage_uom : '';
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <IconButton
          color="inherit"
          onClick={() => handleDeleteRecipeClick(params.row.id)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];


  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage(error.response.data.detail);
    setSnackbarAlert("error");
  };

  const processRecipeRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateRecipe(newRow.id, {
        id: newRow.id,
        material_id: newRow.material_id,
        product_id_ref: newRow.product_id_ref,
        qty: newRow.qty,
      });
      updatedRow.last_updated = data.last_updated;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setRecipeRows(recipeRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      throw error
    }
  };


  const RecipesToolbar = () => {
    return (
      <>
        <GridToolbarContainer>
          <Button
            color="inherit"
            variant='outlined'
            startIcon={<CloseIcon />}
            onClick={() => {
              if (setDialogOpen !== null) {
                setDialogOpen(false);
              }
              setRecipeRows([]);
              setSelectedProductId(null);
            }}
          >
            Close
          </Button>
          <Typography
            variant="body1"
            sx={{ mx: 1, display: 'flex', alignItems: 'center' }}
          >
            <b>{selectedProductName} Recipe</b>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <OilBarrelIcon />
            <Typography variant="body1" sx={{ mx: 1 }}>
              <b>Add Material Input:</b>
            </Typography>
          </Box>
          <Select
            size='small'
            variant='standard'
            onChange={handleMaterialSelect}
            sx={{ width: '15%' }}
          >
            {
              materialsRows
                .filter(input => !recipeRows.some(recipe => recipe['material_id'] === input.id))
                .map(input => (
                  <MenuItem key={input.id} value={input.id}>
                    {input.name} [{input.sku}]
                  </MenuItem>
                ))
            }
          </Select>
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
            <WidgetsIcon />
            <Typography variant="body1" sx={{ mx: 1 }}>
              <b>Add Product Input:</b>
            </Typography>
          </Box>
          <Select
            size='small'
            variant='standard'
            onChange={handleProductSelect}
            sx={{ width: '15%' }}
          >
            {
              productsRows
                .filter(input => !recipeRows.some(recipe => recipe['material_id'] === input.id))
                .map(input => (
                  <MenuItem key={input.id} value={input.id}>
                    {input.name} [{input.sku}]
                  </MenuItem>
                ))
            }
          </Select>
        </GridToolbarContainer>
      </>
    );
  };


  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <DataGrid
        rows={recipeRows}
        columns={recipeColumns}
        disableSelectionOnClick
        density="compact"
        hideFooter
        processRowUpdate={processRecipeRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        slots={{
          toolbar: RecipesToolbar,
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row': {
            color: 'black',
          },
        }}
      />
    </Box>
  );
};

export default RecipeBuilder;
