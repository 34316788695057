import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress
} from '@mui/material';
import trinity_logo from './assets/logo.png';
import axios from 'axios';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { ThemeProvider, CssBaseline } from '@mui/material';
import MainApp from './pages/MainApp';
import SupplierApp from './pages/SupplierApp';
import EmailClient from './pages/EmailClient';
import theme from './theme/theme';
import './theme/global.css';
import { Header } from "./components/AmplifyHeader";
import { fetchAuthSession } from 'aws-amplify/auth';
import { getLogo } from './api/users';


Amplify.configure(config);

function App({ signOut, user }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [emailClient, setEmailClient] = React.useState(false);
  const [logo, setLogo] = React.useState(null);
  const [logoBlob, setLogoBlob] = React.useState(null);
  const [userObj, setUserObj] = React.useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await fetchAuthSession();
        const userData = await axios.get(`${apiUrl}/user/`, {
          headers: {
            Authorization: `Bearer ${session.tokens.idToken.toString()}`
          }
        });
        setUserObj(userData.data);
        localStorage.setItem('identityId', session.identityId.toString());
        localStorage.setItem('orgId', userData.data?.org_id?.toString() || '');
        localStorage.setItem('apiToken', session.tokens.idToken.toString());
      } catch (error) {
        console.error('Error fetching content:', error);
        return;
      }

      try {
        const base64String = await getLogo();
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const url = URL.createObjectURL(blob);
        setLogo(url);
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64StringReader = reader.result.split(',')[1];
          setLogoBlob(base64StringReader);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.log('Error downloading file:', error);
      } finally {
        setIsInitialized(true);
      }
    };

    fetchUser();
  }, [user, apiUrl]);

  if (!isInitialized) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', bgcolor: '#474747' }}>
        <img src={trinity_logo} alt="Trinity Logo" style={{ width: '125px' }} />
        <CircularProgress size={20} sx={{ color: 'white', mt: '1rem' }} />
      </Box>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={
              userObj.is_supplier ? (
                <SupplierApp user={userObj} signOut={signOut} />
              ) : (userObj.use_v2 || emailClient) ? (
                <EmailClient
                  setEmailClient={setEmailClient}
                  user={userObj}
                  signOut={signOut}
                />
              ) : (
                <MainApp
                  user={userObj}
                  setUserObj={setUserObj}
                  setEmailClient={setEmailClient}
                  logo={logo}
                  logoBlob={logoBlob}
                  setLogo={setLogo}
                  signOut={signOut} />
              )}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default withAuthenticator(App, {
  components: {
    SignIn: {
      Header: Header,
    }
  },
  socialProviders: ['google']
});