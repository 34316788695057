import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import IntegrationMainDatagrid from '../components/datagrid/IntegrationMainDatagrid';
import DocumentDatagrid from '../components/datagrid/DocumentDatagrid';
import { fetchDocuments } from '../api/documents';


const CustomersDialogView = ({
  open,
  handleClose,
  productsRows,
  integrations,
  integrationTypes,
  setIntegrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {

  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [documentRows, setDocumentRows] = useState([]);

  useEffect(() => {
    if (selectedIntegrationId) {
      const fetchDocumentsData = async () => {
        try {
          const documents = await fetchDocuments(selectedIntegrationId);
          setDocumentRows(documents);
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      };
      fetchDocumentsData();
    }
  }, [selectedIntegrationId]);



  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Customer Orders</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="black" sx={{ mb: '0.5rem', textAlign: 'left' }}>
            Add customers that submit sales orders. For a centralized system, i.e., EDI use the EDI email address. PDFs from these customers will automatically be categorized by Trinity (if email integration is configured).
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65vh', maxHeight: '65vh', width: '100%' }}>
            {selectedIntegrationId ? (
              <DocumentDatagrid
                rows={documentRows}
                setRows={setDocumentRows}
                selectedIntegrationId={selectedIntegrationId}
                integration={integrations.find(integration => integration.id === selectedIntegrationId)}
                integrations={integrations}
                productsRows={productsRows}
                setSnackbarAlert={setSnackbarAlert}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarMessage={setSnackbarMessage}
                height='65vh'
                setSelectedIntegrationId={setSelectedIntegrationId}
              />
            ) : (
              <IntegrationMainDatagrid
                integrations={integrations.filter(integration => integration.integration_type.type === 'Customer')}
                setIntegrations={setIntegrations}
                integrationTypes={integrationTypes}
                setSnackbarAlert={setSnackbarAlert}
                setSnackbarOpen={setSnackbarOpen}
                setSelectedIntegrationId={setSelectedIntegrationId}
                setSnackbarMessage={setSnackbarMessage}
                isDialogView={true}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default CustomersDialogView;
