import React from 'react';
import {
  Grid,
  Box,
  Backdrop,
  CircularProgress
} from '@mui/material';
import MapComponent from '../components/MapComponent';
import TopBar from '../components/TopBar';
import VisualizeTable from '../components/VisualizeTable';
import Trends from '../components/Trends';
import Overview from '../components/Overview';
import Settings from '../components/Settings';
import HelpDialog from '../components/HelpDialog';
import { fetchIntegrations, fetchIntegrationTypes, createQuickbooksToken, createShopifyToken, fetchShopifyAuthUrl, createWebflowToken } from '../api/integrations';
import SnackbarAlert from '../components/SnackbarAlert';
import { fetchProducts, fetchMaterials } from '../api/products';
import { fetchNotifications } from '../api/notifications';
import { fetchSources } from '../api/notifications';
import { fetchNextOrder } from '../api/orders';


const MainApp = ({
  user,
  setUserObj,
  setEmailClient,
  logo,
  logoBlob,
  setLogo,
  signOut
}) => {
  const [currentView, setCurrentView] = React.useState('map');
  const [integrations, setIntegrations] = React.useState([]);
  const [integrationTypes, setIntegrationTypes] = React.useState([]);
  const [alertsData, setAlertsData] = React.useState([]);
  const [hqIntegration, setHqIntegration] = React.useState(null);
  const [dataSources, setDataSources] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [showRoutes, setShowRoutes] = React.useState(false);
  const [selectedDatagrid, setSelectedDatagrid] = React.useState("Integrations");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarAlert, setSnackbarAlert] = React.useState("");
  const [productsRows, setProductsRows] = React.useState([]);
  const [materialsRows, setMaterialsRows] = React.useState([]);
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  React.useEffect(() => {
    const fetchIntegrationsData = async () => {
      try {
        const integrations_data = await fetchIntegrations();
        const integration_types_data = await fetchIntegrationTypes();
        setIntegrations(integrations_data);
        setIntegrationTypes(integration_types_data);
        setHqIntegration(integrations_data.find(integration => integration.integration_type.type === 'HQ'))
        if (integrations_data.length === 1) {
          setHelpDialogOpen(true);
        }
      } catch (error) {
        console.error('Error fetching integrations:', error);
      } finally {
        setIsInitialized(true);
      }
    };

    const fetchNextOrderData = async () => {
      const data = await fetchNextOrder();
      const groupedItems = Object.values(data.reduce((acc, item) => {
        if (!acc[item.auto_po]) {
          acc[item.auto_po] = {
            auto_po: item.auto_po,
            contact_email: item.contact_email,
            item_type: item.item_type,
            integration_id: item.integration_id,
            integration_id_from: item.integration_id_from,
            coordinates_to: item.coordinates_to,
            coordinates_from: item.coordinates_from,
            partner_name: item.partner_name,
            partner_name_from: item.partner_name_from,
            next_order_date: item.next_order_date,
            order_category: item.order_category,
            items: []
          };
        }

        acc[item.auto_po].items.push({
          item_id: item.item_id,
          item_name: item.item_name,
          item_sku: item.item_sku,
          item_info: item.order_info
        });


        return acc;
      }, {}));
      setAlertsData(groupedItems);
    }


    const fetchDataSourcesData = async () => {
      try {
        const sources_data = await fetchSources();
        setDataSources(sources_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    const fetchNotificationsData = async () => {
      try {
        const notifications_data = await fetchNotifications();
        setNotifications(notifications_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };


    const checkUrlParams = async () => {
      setIsLoading(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const shop = urlParams.get('shop');
        const code = urlParams.get('code');
        const realm_id = urlParams.get('realmId');
        const app_key = urlParams.get('app_key');
        if (realm_id && code) {
          await createQuickbooksToken(code, realm_id);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('QuickBooks linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
          setCurrentView("settings");
        }
        else if (shop && code) {
          await createShopifyToken(shop, code);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('Shopify linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
          setCurrentView("settings");
        }
        else if (shop) {
          const redirect_uri = await fetchShopifyAuthUrl(shop);
          console.log(redirect_uri);
          window.location.href = redirect_uri.detail.authorization_url;
        } else if (code && !app_key) {
          await createWebflowToken(code);
          window.history.pushState({}, '', window.location.pathname);
          setSnackbarOpen(true);
          setSnackbarMessage('Webflow linked successfully');
          setSnackbarAlert('success');
          const sources_data = await fetchSources();
          setDataSources(sources_data);
          setCurrentView("settings");
        }
      } catch (error) {
        console.error('Error exchanging token:', error);
        setSnackbarOpen(true);
        setSnackbarMessage('Failed to exchange token');
        setSnackbarAlert('error');
      }
      setIsLoading(false);
    }

    checkUrlParams();
    fetchIntegrationsData();
    fetchDataSourcesData();
    fetchNotificationsData()
    fetchNextOrderData();
  }, []);

  React.useEffect(() => {
    const fetchMaterialsData = async () => {
      try {
        const data = await fetchMaterials(hqIntegration.id);
        setMaterialsRows(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    const fetchProductsData = async () => {
      try {
        const data = await fetchProducts(hqIntegration.id);
        setProductsRows(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchMaterialsData(), fetchProductsData()]);
      setIsLoading(false);
    };

    if (hqIntegration) {
      fetchData();
    }
  }, [hqIntegration]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: '#111217',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12}>
          <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ flex: '0 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: '0.5rem', mx: '0.5rem' }}>
              <TopBar
                user={user}
                setEmailClient={setEmailClient}
                isInitialized={isInitialized}
                logo={logo}
                logoBlob={logoBlob}
                currentView={currentView}
                setCurrentView={setCurrentView}
                hqIntegration={integrations.find(item => item.integration_type.type === 'HQ')}
                integrations={integrations}
                materialsRows={materialsRows}
                productsRows={productsRows}
                setIntegrations={setIntegrations}
                integrationTypes={integrationTypes}
                showRoutes={showRoutes}
                setHelpDialogOpen={setHelpDialogOpen}
                setShowRoutes={setShowRoutes}
                selectedDatagrid={selectedDatagrid}
                setSelectedDatagrid={setSelectedDatagrid}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarAlert={setSnackbarAlert}
                signOut={signOut}
              />
            </Box>
            <Box
              sx={{
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'top',
                alignItems: 'center',
                overflow: 'hidden',
                p: '0.2rem'
              }}
            >
              {currentView === 'map' ? (
                <MapComponent
                  user={user}
                  logoBlob={logoBlob}
                  alertsData={alertsData.filter(row => row.order_category !== 'reminder')}
                  productsRows={productsRows}
                  setProductsRows={setProductsRows}
                  materialsRows={materialsRows}
                  setMaterialsRows={setMaterialsRows}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  integrationTypes={integrationTypes}
                  showRoutes={showRoutes}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  dataSources={dataSources}
                />
              ) : currentView === 'overview' ? (
                <Overview
                  user={user}
                  alertsData={alertsData}
                  hqIntegration={integrations.find(item => item.integration_type.type === 'HQ')}
                  logo={logo}
                  isLoading={isLoading}
                  logoBlob={logoBlob}
                  integrations={integrations}
                  productsRows={productsRows}
                  materialsRows={materialsRows}
                  notifications={notifications}
                  setNotifications={setNotifications}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                />
              ) : currentView === 'dashboard' ? (
                <Trends />
              ) : currentView === 'table' ? (
                <VisualizeTable
                  user={user}
                  logoBlob={logoBlob}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  integrationTypes={integrationTypes}
                  productsRows={productsRows}
                  setProductsRows={setProductsRows}
                  materialsRows={materialsRows}
                  setMaterialsRows={setMaterialsRows}
                  selectedDatagrid={selectedDatagrid}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  dataSources={dataSources}
                />
              ) : currentView === 'settings' ? (
                <Settings
                  user={user}
                  setUserObj={setUserObj}
                  hqIntegration={integrations.find(item => item.integration_type.type === 'HQ')}
                  integrations={integrations}
                  setIntegrations={setIntegrations}
                  integrationTypes={integrationTypes}
                  logo={logo}
                  setLogo={setLogo}
                  dataSources={dataSources}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarAlert={setSnackbarAlert}
                  setProductsRows={setProductsRows}
                  setIsLoading={setIsLoading}
                  setDataSources={setDataSources}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
      <HelpDialog
        helpDialogOpen={helpDialogOpen}
        setHelpDialogOpen={setHelpDialogOpen}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress size={20} color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default MainApp;
