import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import LabeledDropdown from './LabeledDropdown';
import { createProductsInventory, createMaterialsInventory, fetchAllMaterialInventory, fetchAllInventory } from '../api/products';

const CreateInventoryDialog = ({
  open,
  onClose,
  itemOptions,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  setRows = null,
  type = 'product',
  inputSelectedItem = null,
}) => {
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedItem, setSelectedItem] = useState(inputSelectedItem || '');
  const [qty, setQty] = useState('');
  const [safetyStockQty, setSafetyStockQty] = useState('');
  const [leadTime, setLeadTime] = useState('');
  const [isTurnkey, setIsTurnkey] = useState(false);

  useEffect(() => {
    if (inputSelectedItem) {
      setSelectedItem(inputSelectedItem);
    }
  }, [inputSelectedItem]);


  const handleCreateInventory = async () => {
    try {
      if (type === 'product') {
        if (!selectedPartner || !selectedItem || !qty) {
          setSnackbarOpen(true);
          setSnackbarMessage('Please fill out remaining fields.');
          setSnackbarAlert("error");
          return;
        }
        await createProductsInventory(
          {
            integration_id: selectedPartner,
            product_id: selectedItem,
            qty: qty,
            lead_time: leadTime || null,
            safety_stock_qty: safetyStockQty || null,
          }
        );
        if (setRows !== null) {
          const data = await fetchAllInventory();
          setRows(data);
        }
      } else {
        if (!selectedPartner || !selectedItem || !qty) {
          setSnackbarOpen(true);
          setSnackbarMessage('Please fill out remaining fields.');
          setSnackbarAlert("error");
          return;
        }
        await createMaterialsInventory(
          {
            integration_id: selectedPartner,
            material_id: selectedItem,
            qty: qty,
            is_turnkey: isTurnkey,
          }
        );
        const data = await fetchAllMaterialInventory();
        setRows(data);
      }
      setSelectedPartner('');
      setSelectedItem('');
      setQty('');
      setSafetyStockQty('');
      setLeadTime('');
      setIsTurnkey(false);
      onClose();
    }
    catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <LabeledDropdown
          label="Partner"
          value={selectedPartner}
          sx={{ my: '0.5rem' }}
          options={integrations.sort((a, b) => a.name.localeCompare(b.name)).map(row => ({ value: row.id, label: row.name }))}
          onChange={(event) => setSelectedPartner(event.target.value)}
        />
        <LabeledDropdown
          label="Item"
          value={selectedItem}
          sx={{ my: '0.5rem' }}
          options={itemOptions.map(row => ({ value: row.id, label: `${row.name} [${row.sku}]` }))}
          onChange={(event) => setSelectedItem(event.target.value)}
        />
        <TextField
          fullWidth
          size='small'
          margin="normal"
          label={type === 'material' && selectedItem ? 'Quantity (' + itemOptions.find(row => row.id === selectedItem)?.purchasing_uom + ')*' : null || 'Quantity*'}
          value={qty}
          sx={{ my: '0.5rem' }}
          onChange={(event) => setQty(event.target.value)}
          type="number"
        />
        {type === 'product' && integrations.find(row => row.id === selectedPartner)?.integration_type.category === 'Manufacturer' ? (
          <TextField
            fullWidth
            size='small'
            margin="normal"
            label="Estimated Lead Time (days)*"
            value={leadTime}
            sx={{ my: '0.5rem' }}
            onChange={(event) => setLeadTime(event.target.value)}
            type="number"
          />
        ) : type === 'product' && integrations.find(row => row.id === selectedPartner)?.integration_type.category === '3PL' ? (
          <TextField
            fullWidth
            size='small'
            margin="normal"
            label="Safety Stock"
            value={safetyStockQty}
            sx={{ my: '0.5rem' }}
            onChange={(event) => setSafetyStockQty(event.target.value)}
            type="number"
          />
        ) : type === 'material' ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={isTurnkey}
                onChange={(event) => setIsTurnkey(event.target.checked)}
                name="Turnkey"
                color="primary"
              />
            }
            label="Manufacturer Sourced?"
          />
        ) : null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateInventory} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInventoryDialog;
