
import { axiosInstance, checkToken } from './utils.js'

const getOrg = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/org/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const inviteUser = async (email) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/user/`, { email_address: email });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const getLogo = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/logo/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createLogo = async (logoData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/logo/`, { logo: logoData });
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const toggleDailyDigest = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/user/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export { getOrg, inviteUser, getLogo, createLogo, toggleDailyDigest };
