import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  Divider,
  Link
} from '@mui/material';

const HelpDialog = ({ helpDialogOpen, setHelpDialogOpen }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleClose = () => {
    setHelpDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>Welcome to Trinity. The smartest way to manage your entire supply chain.</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />
            <Typography variant="body2" paragraph>Trinity organizes your supply chain into your HQ, suppliers, manufacturers, 3PLs, and distributors.</Typography>
            <Typography variant="body2" paragraph><b>HQ</b>: store information about the products you make the and the raw materials you use.</Typography>
            <Typography variant="body2" paragraph><b>Supplier</b>: add materials defined in the HQ to the corresponding suppliers. Add details around lead times and inventory levels (if this is provided by the supplier).</Typography>
            <Typography variant="body2" paragraph><b>Manufacturer</b>: add products and materials to the corresponding manufacturers.</Typography>
            <Typography variant="body2" paragraph><b>3PL/Distributor</b>: link with partner systems to capture current (and past) levels of inventory.</Typography>
            <Typography variant="body1" color={'error'} paragraph><b>To get started, hit "Add Partner" (top of screen) and build out your supply chain network.</b></Typography>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>Your HQ</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />
            <Typography variant="body2" paragraph>Add the products you make, the materials you use, and define recipes that map materials to recipes.</Typography>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>Suppliers</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />
            <Typography variant="body2" paragraph>After adding a supplier, add products from your HQ to this supplier.</Typography>
            <Typography variant="body2" paragraph>Define information about typical lead times for this supplier and create POs directly from Trinity.</Typography>
            <Typography variant="body2" paragraph><b>Orders</b></Typography>
            <Typography variant="body2" paragraph>Orders to suppliers can be made from Trinity directly, simply create a PO from the partner car, add items, and send from the platform (or download and attach the provided PDF).</Typography>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>Manufacturers</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />
            <Typography variant="body2" paragraph>Define who makes the products you sell and what raw materials are required to create these products.</Typography>
            <Typography variant="body2" paragraph>Automatically create POs and catalogue current and past inventory levels.</Typography>
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>3PLs/Distributors</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />
            <Typography variant="body2" paragraph>Capture saleable inventory and inventory trends.</Typography>
            <Typography variant="body2" paragraph>Determine inventory usage and automatically calculate next order dates.</Typography>
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            <Typography variant="body1" paragraph><b>Contact</b></Typography>
            <Divider fullWidth sx={{ mb: '0.5rem', borderColor: 'black' }} />

            <Typography variant="body2" paragraph>
              For any questions or concerns, please reach out directly to{' '}
              <Link href="mailto:paul@trinity3.co" underline="hover">
                <b>paul@trinity3.co</b>
              </Link>.
            </Typography>

            <Typography variant="body2" paragraph>
              Or you can{' '}
              <Link href="https://calendly.com/kyle-trinity/trinity-demo" target="_blank" underline="hover" sx={{ color: 'blue' }}>
                schedule a call here.
              </Link>
            </Typography>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={helpDialogOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Help Center</DialogTitle>
      <DialogContent sx={{ height: '40vh', maxHeight: '40vh' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="help center tabs"
        >
          <Tab label="Overview" />
          <Tab label="HQ" />
          <Tab label="Suppliers" />
          <Tab label="Manufacturers" />
          <Tab label="3PLs/Distributors" />
          <Tab label="Integrations/Contact" />
        </Tabs>
        <Box mt={2}>
          {renderTabContent()} {/* Render content based on the selected tab */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
