import { createContext, useContext, useMemo } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { createExchangeToken } from '../api/integrations';

const GoogleAuthContext = createContext();

export const useGoogleAuth = () => useContext(GoogleAuthContext);


export const GoogleAuthProvider = ({ children }) => {
  const gmailLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        await createExchangeToken(codeResponse.code, 'Gmail', process.env.REACT_APP_REDIRECT_URI);
        console.log('Gmail credentials refreshed successfully');
        window.location.reload();
      } catch (error) {
        console.error('Error exchanging token or fetching sources:', error);
      }
    },
    onFailure: (error) => {
      console.error('Google Login failed:', error);
    },
    flow: 'auth-code',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'https://www.googleapis.com/auth/gmail.readonly',
  });

  const value = useMemo(() => ({ gmailLogin }), [gmailLogin]);

  return (
    <GoogleAuthContext.Provider value={value}>
      {children}
    </GoogleAuthContext.Provider>
  );
};
