import React from 'react';
import { Box, Button, Select, MenuItem, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { createMaterialsInventory, updateMaterialsInventory, deleteMaterialsInventory, fetchHistoryInventory } from '../../api/products';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShipmentsDatagrid from './ShipmentsDatagrid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { fetchShipments } from '../../api/shipments';
import TimelineIcon from '@mui/icons-material/Timeline';
import InventoryChart from '../charts/InventoryChart';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';


export default function MaterialsInventoryDialogDatagrid({
  integrationId,
  integration,
  integrationType,
  materialsRows,
  materialInventoryRows,
  setMaterialInventoryRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null
}) {
  const columnVisibilityModel = {
    id: false,
    material_id: false,
    po_sent_qty: integrationType === 'Supplier' ? false : true,
    in_transit_qty: integrationType === 'Supplier' ? false : true,
    committed_qty: integrationType === 'Supplier' ? false : true,
    available_qty: integrationType === 'Supplier' ? false : true
  };
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const [inventoryTrendRows, setInventoryTrendRows] = React.useState([]);

  const handleNameChange = async (event, params) => {
    const newId = event.target.value;
    const selectedMaterial = materialsRows.find(material => material.id === newId);
    const createdInventory = await createMaterialsInventory(
      {
        integration_id: integrationId,
        material_id: selectedMaterial.id,
        qty: null,
        unit_of_measure: selectedMaterial.purchasing_uom,
        lead_time: integration.default_lead_time || null,
        safety_stock_qty: null
      }
    );
    setMaterialInventoryRows(prevInventory => prevInventory.filter(row => row.id !== 1).concat(createdInventory));
  };

  const handleMaterialSelect = (event) => {
    handleNameChange(event);
  };

  const handleDeleteClick = async (id) => {
    if (id !== 1) {
      try {
        const deleteSuccess = await deleteMaterialsInventory(id);
        console.log(deleteSuccess)
        setMaterialInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
        setSnackbarOpen(true);
        setSnackbarMessage("Deleted successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
    else {
      setMaterialInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, row.integration_id, null, row.material_id, "True");
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchInventoryTrend = async (row) => {
    try {
      const inventoryTrend = await fetchHistoryInventory('material', row.integration_id, row.material_id);
      setInventoryTrendRows(inventoryTrend);
    } catch (error) {
      console.log(error);
    }
  };

  const commonColumns = [
    {
      field: 'name', headerName: 'Item', flex: 1.75, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id);
        const value = selectedMaterial ? selectedMaterial.name : '';

        return (
          params.row?.unlocked ? (
            <Select
              size="small"
              variant="standard"
              fullWidth
              onChange={(event) => {
                const newValue = event.target.value;
                const updatedRow = { ...params.row, material_id: newValue, unlocked: false };
                processRowUpdate(updatedRow);
              }}
            >
              {materialsRows
                .filter(material => !materialInventoryRows.some(inventory => inventory.material_id === material.id))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((materials) => (
                  <MenuItem key={materials.id} value={materials.id}>
                    {materials.name} [{materials.sku}]
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <span>{value} [{selectedMaterial.sku}]</span>
          )
        );
      }
    },
    {
      field: 'po_sent_qty',
      headerName: 'Expected Qty',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'in_transit_qty',
      headerName: 'Inbound Qty',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'qty',
      headerName: 'Inventory',
      flex: 1,
      editable: true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'committed_qty',
      headerName: 'Committed Qty',
      flex: 1,
      editable: true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'available_qty',
      headerName: 'Available Qty',
      flex: 1,
      editable: true,
      cellClassName: 'super-app-theme-editable--cell',
      renderCell: (params) => {
        const value = params.value;
        const isBelowZero = value < 0;
        return (
          <Box display="flex" alignItems="center">
            {value ? Number(value).toLocaleString() : 0}
            {isBelowZero && (
              <WarningAmberIcon color="error" sx={{ ml: 0.25 }} />
            )}
          </Box>
        );
      }
    },
    {
      field: 'unit_of_measure', headerName: 'UoM', flex: 1, renderCell: (params) => {
        const selectedMaterial = materialsRows.find(material => material.id === params.row.material_id);
        return selectedMaterial ? selectedMaterial.purchasing_uom : ''
      }
    }
  ];

  const actionsColumn = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1.25,
    align: 'right',
    headerAlign: 'center',
    renderCell: (params) => (
      <>
        {
          ['Manufacturer'].includes(integrationType) && (
            <>
              <Tooltip title="Inventory Trend">
                <IconButton
                  color="inherit"
                  onClick={() => handleFetchInventoryTrend(params.row)}
                >
                  <TimelineIcon />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        {
          ['Manufacturer'].includes(integrationType) && params.row.delivered_qty > 0 && (
            <Tooltip title="Lot Details">
              <IconButton
                color="inherit"
                onClick={() => handleFetchInventoryDetails(params.row)}
              >
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
          )
        }
        <Tooltip title="Change material name in Trinity - this does not unlink the material from its data source. This updates the Trinity display name name to unify naming in your supply chain.">
          <IconButton
            color="inherit"
            onClick={() => {
              const newRow = { ...params.row, unlocked: !params.row.unlocked };
              setMaterialInventoryRows(materialInventoryRows.map((row) => (row.id === newRow.id ? newRow : row)));
            }}
          >
            {params.row.unlocked ? <LockOpenIcon /> : <LockIcon />}
          </IconButton>
        </Tooltip>
        <IconButton
          color="inherit"
          onClick={() => handleDeleteClick(params.row.id)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </>
    ),
    headerClassName: 'super-app-theme--header'
  }

  const leadTimeColumn = { field: 'lead_time', headerName: 'Lead Time (days)', flex: 1, editable: true }

  const turnKeyColumn = {
    field: 'is_turnkey',
    headerName: 'Mfg Sourced',
    flex: 0.75,
    editable: true,
    renderCell: (params) => (
      <Checkbox
        checked={!!params.value}
        onChange={(event) => {
          const newValue = event.target.checked;
          const updatedRow = { ...params.row, is_turnkey: newValue };
          processRowUpdate(updatedRow);
        }}
        sx={{
          color: 'black',
          '&.Mui-checked': {
            color: 'black',
          },
        }}
      />
    )
  }

  let columns = [...commonColumns];

  if (['Manufacturer'].includes(integrationType)) {
    if (integration.is_supplier) {
      columns.push(leadTimeColumn);
    }
    columns.push(turnKeyColumn);
    columns.push(actionsColumn);
  } else if (['Supplier'].includes(integrationType)) {
    columns.push(leadTimeColumn);
    columns.push(actionsColumn);
  }


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Typography variant="body1" sx={{ mx: 1, display: 'inline' }}>
          <b>Add Material:</b>
        </Typography>
        <Select
          size="small"
          variant="standard"
          label="Add Material"
          onChange={handleMaterialSelect}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="" disabled>
            Select a material
          </MenuItem>
          {materialsRows
            .filter(material => !materialInventoryRows.some(inventory => inventory.material_id === material.id))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((material) => (
              <MenuItem key={material.name} value={material.id}>
                {material.name} [{material.sku}]
              </MenuItem>
            ))}
        </Select>
      </GridToolbarContainer>
    );
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (newRow.qty >= 0) {
      try {
        const data = await updateMaterialsInventory(newRow.id, newRow);
        updatedRow.last_updated = data.last_updated;
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully");
        setSnackbarAlert("success");
        setMaterialInventoryRows(materialInventoryRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } catch (error) {
        throw error
      }
    }
  };


  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage("Invalid value entered");
    setSnackbarAlert("error");
  };

  const trendColumns = [
    {
      field: 'create_time',
      headerName: 'Time',
      width: 180,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm:ss');
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : <b>{params.row.order_qty > 0 ? '+ ' : ''}{Number(params.row.order_qty).toLocaleString()}</b>
    },
    {
      field: 'edited_by',
      headerName: 'Source',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.qty ? params.value : <b>{params.value}</b>
    },
  ];

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            setInventoryTrendRows([])
            setInventoryDetailsRows([])
          }}
        >
          Back to Table
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: '65vh' }}>
        {inventoryTrendRows.length > 0 ? (
          <>
            <Box sx={{ width: '30%', height: '100%', mr: '0.5rem' }}>
              <DataGrid
                rows={inventoryTrendRows}
                columns={trendColumns}
                density='compact'
                hideFooter
                slots={{
                  toolbar: CustomDetailsToolbar,
                }}
              />
            </Box>
            <Box sx={{ width: '70%', height: '100', p: '1rem' }}>
              <InventoryChart data={inventoryTrendRows} showAsArea={false} />
            </Box>
          </>
        ) : inventoryDetailsRows.length === 0 ? (
          <Box
            sx={{
              width: '100%',
              height: '65vh',
              maxHeight: '65vh',
              mr: inventoryDetailsRows.length ? '1rem' : '0',
              '& .super-app-theme-editable--cell': {
                backgroundColor: 'grey.100',
                fontWeight: '600',
              },
            }}>
            <DataGrid
              rows={materialInventoryRows}
              columns={columns}
              density='compact'
              hideFooter
              loading={loading}
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        ) : (
          <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
              hideCols={{ carrier: false, bol_string: false, tracking_number: false, pick_up_date: false, is_delivered: false }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
