import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  LinearProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { triggerPoEmail, triggerPdfPreview } from '../api/orders';
import CustomTextField from './CustomTextField'
import LabeledDropdown from './LabeledDropdown'
import WarningIcon from '@mui/icons-material/Warning';

const columnsPO = [
  { field: 'id', headerName: 'ID' },
  { field: 'name', headerName: 'Name', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'sku', headerName: 'SKU', flex: 1, headerClassName: 'super-app-theme--header' },
  { field: 'qty', headerName: 'Qty', flex: 0.5, headerClassName: 'super-app-theme--header' },
  { field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, headerClassName: 'super-app-theme--header' },
  { field: 'unit_price', headerName: 'Unit Price', flex: 0.5, headerClassName: 'super-app-theme--header' },
  { field: 'lead_time', headerName: 'Lead Time', flex: 0.5, headerClassName: 'super-app-theme--header' },
  {
    field: 'tax_rate',
    headerName: 'Tax Rate',
    flex: 0.5,
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => {
      const value = params.value;
      return value != null ? `${(value * 100).toFixed(1)}%` : '';
    }
  },
]

const POSender = ({
  logoBlob,
  selectedPOsArr,
  setSelectedPOsArr,
  orderType,
  rows,
  setRows,
  setTableView,
  setSendDialogOpen,
  integrations,
  integration,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  inputIntegration = null
}) => {
  const [poMessage, setPoMessage] = useState('');
  const [ccList, setCcList] = useState('');
  const [linkedIntegrationValue, setLinkedIntegrationValue] = useState('');
  const [linkedIntegration, setLinkedIntegration] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const columnVisibilityModel = { id: false };
  const [maxLeadTime, setMaxLeadTime] = useState(0);
  const [newDate, setNewDate] = useState('');

  useEffect(() => {
    if (inputIntegration) {
      setLinkedIntegration(inputIntegration);
      setCcList(inputIntegration.contact_email);
    }
  }, [inputIntegration]);


  useEffect(() => {
    if (selectedPOsArr.length > 0) {
      const maxLeadTime = Math.max(...selectedPOsArr.map(po => po.lead_time));
      setMaxLeadTime(maxLeadTime);
      const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      };
      const currentDate = new Date();
      const calculatedDate = addDays(currentDate, maxLeadTime);
      const formattedNewDate = calculatedDate.toISOString().split('T')[0];
      setNewDate(formattedNewDate);
    }
  }, [selectedPOsArr]);


  const handlePreviewPO = async (shipFromIntegration, shipToIntegration, ccList) => {
    setIsLoading(true);
    try {
      const data = await triggerPdfPreview({
        logo: logoBlob,
        orders: selectedPOsArr,
        owner: integrations.find(item => item.integration_type.type === 'HQ'),
        ship_from: shipFromIntegration,
        ship_to: shipToIntegration,
        cc_list: ccList,
        msg_body: poMessage,
        expected_arrival: newDate
      });
      const pdfBase64 = data.body.pdf_base64;
      const byteCharacters = atob(pdfBase64);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      console.error('Error generating preview:', error);
    }
    setIsLoading(false);
  };


  const handleSendPO = async () => {
    setIsLoading(true);
    try {
      const data = await triggerPoEmail({
        logo: logoBlob,
        orders: selectedPOsArr,
        owner: integrations.find(item => item.integration_type.type === 'HQ'),
        ship_from: integration,
        ship_to: linkedIntegration,
        cc_list: ccList,
        msg_body: poMessage,
        expected_arrival: newDate,
        type: orderType
      });

      if (setTableView) {
        const updatedRows = rows.map(row => {
          const foundOrder = data.find(order => order.id === row.id);
          return foundOrder ? foundOrder : row;
        });
        setRows(updatedRows);
        setTableView('table');
        setPoMessage('');
        setCcList('');
        setSelectedPOsArr([]);
      } else if (setRows) {
        const updatedRows = rows.filter(row => !data.some(order => order.id === row.id));
        setRows(updatedRows);
        setSendDialogOpen(false);
      } else {
        setSendDialogOpen(false);
      }
      setSnackbarOpen(true);
      setSnackbarMessage("PO sent successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error fetching orders:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
    setIsLoading(false);
  };



  return (
    <>
      <Box
        sx={{
          width: '50%',
          height: '65vh',
          maxHeight: '65vh',
          py: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'top',
        }}
      >
        <CustomTextField
          label="Subject"
          sx={{ mt: 0 }}
          value={'PO from ' + integrations.find(item => item.integration_type.type === 'HQ').name + ': ' + selectedPOsArr[0].order_name}
        />
        {inputIntegration ? (
          <Box sx={{ m: '0.5rem' }}><b>Shipping To: {inputIntegration.name}</b></Box>
        ) : (
          <LabeledDropdown
            label="Ship To"
            value={linkedIntegrationValue}
            disabled={inputIntegration ? true : false}
            sx={{ my: '0.5rem' }}
            options={integrations.filter((link) => link.integration_type.category === (integration.integration_type.category === "Supplier" ? "Manufacturer" : "3PL"))
              .map((row) => ({ value: row.id, label: row.name }))}
            onChange={(event) => {
              setLinkedIntegrationValue(event.target.value);
              setLinkedIntegration(integrations.find(item => item.id === event.target.value));
              setCcList(integrations.find(item => item.id === event.target.value)?.contact_email);
            }}
          />
        )}
        <CustomTextField
          label="CC (comma separated list)"
          value={ccList}
          sx={{ my: '0.5rem' }}
          onChange={(e) => setCcList(e.target.value)}
        />
        <Typography fontSize="16px" sx={{ my: '0.25rem', display: 'flex', alignItems: 'center' }}>
          <WarningIcon sx={{ color: 'orange', mr: '0.5rem' }} />
          Est. Date of Arrival:&nbsp;<b>{newDate}</b>&nbsp;(longest lead time: {maxLeadTime} days)
        </Typography>
        <CustomTextField
          label="Include message in email body"
          value={poMessage}
          multiline={true}
          sx={{ my: '0.5rem' }}
          onChange={(e) => setPoMessage(e.target.value)}
        />
        {!isLoading ? (
          <>
            <Box>
              {linkedIntegration && (
                <>
                  <Button
                    color="primary"
                    variant='contained'
                    startIcon={<SendIcon />}
                    sx={{ mr: '1rem' }}
                    onClick={() => handleSendPO()}
                  >
                    Send PO
                  </Button>
                  <Button
                    color="primary"
                    variant='outlined'
                    startIcon={<VisibilityIcon />}
                    sx={{ mr: '1rem' }}
                    onClick={() => handlePreviewPO(
                      integration,
                      linkedIntegration,
                      linkedIntegration.contact_email,
                    )}
                  >
                    Preview
                  </Button>
                </>
              )}
              {setTableView && (
                <Button
                  color="primary"
                  variant='outlined'
                  onClick={() => setTableView('table')}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
            <Typography fontSize="14px" sx={{ mt: '0.5rem' }}>Loading...</Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '50%',
          height: '65vh',
          padding: '1rem',
          maxHeight: '65vh',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '45vh',
            maxHeight: '45vh'
          }}
        >
          <DataGrid
            rows={selectedPOsArr}
            columns={columnsPO}
            density='compact'
            columnVisibilityModel={columnVisibilityModel}
            hideFooter
          />
        </Box>
      </Box>
    </>
  );
};

export default POSender;
