import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Switch
} from '@mui/material';
import StyledButton from '../components/StyledButton';
import ToggleMetricsView from '../components/ToggleMetricsView';
import AddIcon from '@mui/icons-material/Add';
import CreateIntegrationDialog from './CreateIntegrationDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CreateSupplierDialog from './CreateSupplierDialog';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import StyledIconButton from '../components/StyledIconButton';
import trinity_logo from '../assets/logo.png';

const SelectDropdown = ({ selectedValue, setSelectedValue, menuItems }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl
      sx={{
        ml: '1rem',
        width: '175px',
      }}
      size='small'
    >
      <Select
        labelId="select-small-label"
        id="select-small"
        value={selectedValue}
        onChange={handleChange}
        IconComponent={() => <ArrowDropDownIcon style={{ color: 'white' }} />}
        sx={{
          color: 'white',
          border: '1px solid grey',
          height: '2.25rem',
          borderRadius: '10px',
          '&:focus': {
            borderColor: 'white',
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


const TopBar = ({
  user,
  isInitialized,
  currentView,
  setCurrentView,
  setEmailClient,
  hqIntegration,
  integrations,
  materialsRows,
  showRoutes,
  setShowRoutes,
  setHelpDialogOpen,
  setIntegrations,
  integrationTypes,
  selectedDatagrid,
  setSelectedDatagrid,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  signOut
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [filteredTypes, setFilteredTypes] = useState([]);


  useEffect(() => {
    if (isInitialized && integrations.length === 0) {
      setDialogOpen(true);
      setFilteredTypes(integrationTypes.filter(integration => integration.type === 'HQ'))
    } else {
      setFilteredTypes(integrationTypes.filter(integration => integration.type !== 'HQ'))
    }
  }, [integrations, isInitialized, integrationTypes]);

  const datagridItems = [
    { value: 'Integrations', label: 'Partners' },
    { value: 'Documents', label: 'Documents' },
    { value: 'Recipes', label: 'Recipes' },
    { value: 'Orders', label: 'Purchase Orders' },
    { value: 'Shipments', label: 'Shipments' },
    { value: 'Products Inventory', label: 'Products' },
    { value: 'Materials Inventory', label: 'Materials' },
  ];


  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };


  return (
    <>
      <Grid container
        sx={{
          bgcolor: '#111217',
          py: '0.3rem',
          px: '1rem',
          borderRadius: '15px',
          border: '1px solid white'
        }}>
        <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <img src={trinity_logo} alt="Trinity Logo" onClick={() => setEmailClient(true)} style={{ width: '95px' }} />
          <ToggleMetricsView currentView={currentView} setCurrentView={setCurrentView} />
          {!['settings', 'overview'].includes(currentView) && (
            <>
              <StyledButton
                text="Add Partner"
                variant="outlined"
                highlight={integrations.length < 2 ? true : false}
                width='175px'
                sx={{ ml: '1rem' }}
                icon={<AddIcon />}
                onClick={handleClick}
              />
              {['dashboard'].includes(currentView) ? (
                null
              ) : currentView === 'map' ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showRoutes}
                      onChange={() => setShowRoutes((prev) => !prev)}
                      name="showRoutes"
                      color="primary"
                      sx={{
                        '& .MuiSwitch-track': {
                          bgcolor: 'grey.300',
                        },
                        '& .MuiSwitch-thumb': {
                          color: 'white',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" color="white">
                      Show Recommendations
                    </Typography>
                  }
                  sx={{ ml: '1rem' }}
                />
              ) : (
                <SelectDropdown selectedValue={selectedDatagrid} setSelectedValue={setSelectedDatagrid} menuItems={datagridItems} />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <StyledIconButton text="Help" icon={<HelpIcon />} onClick={() => setHelpDialogOpen(true)} />
          <StyledIconButton text="Settings" icon={<SettingsIcon />} onClick={() => setCurrentView('settings')} />
          <StyledIconButton icon={<LogoutIcon />} onClick={handleLogout} />
        </Grid>
      </Grid>
      {integrations.length !== 0 ? (
        <CreateIntegrationDialog
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          integrationTypes={filteredTypes}
          setIntegrations={setIntegrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      ) : (
        <CreateIntegrationDialog
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          integrationTypes={filteredTypes}
          setIntegrations={setIntegrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
          user={user}
        />
      )}
      <CreateSupplierDialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        itemOptions={materialsRows}
        integrations={integrations.filter(row => row.integration_type.category === "Supplier")}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  );
};

export default TopBar;