import React, { useState } from 'react';
import { IconButton, Popover, Box, Button, Tooltip } from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import AddIcon from '@mui/icons-material/Add';
import { updateProductsInventory } from '../../api/products';
import { updateMaterialsInventory } from '../../api/products';
import { fetchRecipe } from '../../api/products';
import CreateInventoryDialog from '../CreateInventoryDialog';
import CreateSupplierDialog from '../CreateSupplierDialog';
import ShipmentsDatagrid from './ShipmentsDatagrid';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { fetchShipments } from '../../api/shipments';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const recipeColumnVisibilityModel = {
  id: false,
  material_id: false,
};

export default function InventoryMainDatagrid({
  rows,
  setRows,
  materialsRows,
  productsRows,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  type = "Product",
  loading = null
}) {
  const [recipeRows, setRecipeRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [shipmentRows, setShipmentRows] = useState([]);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);


  const handlePopoverClose = () => {
    setShipmentRows([]);
    setRecipeRows([]);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const columnVisibilityModel = {
    id: false,
    unit_of_measure: type === "Product" ? false : true,
    po_sent_qty: type === "Product" ? false : true,
    integration_type: type === "Material" ? false : true,
  };

  const handleFetchShipments = async (row, is_delivered) => {
    const shipment_data = await fetchShipments(
      type === "Product" ? "Manufacturer" : "Supplier",
      row.integration_id,
      type === "Product" ? row.product_id : null,
      type === "Product" ? null : row.material_id,
      is_delivered
    );
    setAnchorEl(true);
    setShipmentRows(shipment_data);
  }


  const columns = [
    { field: 'id', headerName: 'ID', headerClassName: 'super-app-theme--header' },
    {
      field: 'name', headerName: 'Item', flex: 1.5, headerClassName: 'super-app-theme--header', renderCell: (params) => (
        <>
          {type === "Product" && (
            <Tooltip title="View Recipe Card">
              <IconButton
                color="inherit"
                onClick={(event) => handleExpandRecipe(event, params.row.product_id)}
              >
                <FormatListNumberedIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          )}
          <span>{params.value} [{params.row.sku}]</span>
        </>
      )
    },
    { field: 'integration_name', headerName: 'Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'integration_type', headerName: 'Partner Type', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'po_sent_qty',
      headerName: type === "Product" ? 'Expected Inventory' : `Expected ${type}s`,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'in_transit_qty',
      headerName: type === "Product" ? 'Inbound Inventory' : `Inbound ${type}s`,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const value = Number(params.row.in_transit_qty).toLocaleString() || 0;
        return type === 'Product' && params.row.integration_type === 'Manufacturer'
          ? null
          : (
            <Box display="flex" flexDirection="row" alignItems="center">
              {value}
              {
                params.row.in_transit_qty > 0 && (
                  <Tooltip title="View Shipments">
                    <IconButton onClick={() => handleFetchShipments(params.row, "False")}>
                      <LocalShippingIcon sx={{ ml: 0.25, color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          );
      },
    },
    {
      field: 'qty',
      headerName: 'Inventory',
      flex: 1, editable: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const value = Number(params.value).toLocaleString() || 0;
        return params.row.delivered_qty > 0 ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            {value}
            <Tooltip title="Lot Details">
              <IconButton onClick={() => handleFetchShipments(params.row, "True")}>
                <ManageSearchIcon sx={{ ml: 0.25, color: 'black' }} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : value;
      }
    },
    { field: 'unit_of_measure', headerName: 'UofM', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'edited_by', headerName: 'Source', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => {
        const date = params.value ? new Date(params.value) : null;
        const zonedDate = date ? fromZonedTime(date, 'UTC') : null;
        return date ? format(zonedDate, 'MM/dd/yy HH:mm') : '';
      },
    }
  ];

  const recipeColumns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'material_id', headerName: 'Material ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        const isMaterial = params.row.material_id !== null;
        const selectedOption = isMaterial
          ? materialsRows.find(material => material.id === params.row.material_id)
          : productsRows.find(product => product.id === params.row.product_id_ref);

        return selectedOption ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isMaterial ? <OilBarrelIcon style={{ marginRight: 4 }} /> : <WidgetsIcon style={{ marginRight: 4 }} />}
            {selectedOption.name}
          </div>
        ) : '';
      },
    },
    {
      field: 'sku', headerName: 'SKU', flex: 1, renderCell: (params) => {
        const selectedOption = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedOption ? selectedOption.sku : '';
      }
    },
    { field: 'qty', headerName: 'Qty', flex: 0.5 },
    {
      field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, renderCell: (params) => {
        const selectedOption = materialsRows.find(material => material.id === params.row.material_id) || productsRows.find(product => product.id === params.row.product_id_ref);
        return selectedOption ? selectedOption.usage_uom : '';
      }
    },
  ];

  const processRowUpdate = async (newRow) => {
    if (newRow.qty >= 0) {
      const updatedRow = { ...newRow, isNew: false };
      try {
        let data;
        if (type === "Product") {
          data = await updateProductsInventory(newRow.id, newRow);
        } else {
          data = await updateMaterialsInventory(newRow.id, newRow);
        }
        updatedRow.last_updated = data.last_updated;
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully");
        setSnackbarAlert("success");
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
        return null;
      }
    }
  };

  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage("Invalid value entered");
    setSnackbarAlert("error");
  };

  const handleExpandRecipe = async (event, productId) => {
    try {
      const data = await fetchRecipe(productId);
      setAnchorEl(true);
      setRecipeRows(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };


  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Add {type}
        </Button>
        {type === "Material" && (
          <Button
            color="inherit"
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => setOpenSupplierDialog(true)}
          >
            Add {type} Supplier
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Popover
        id="recipe-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            border: '1px solid black',
          },
        }}
      >
        {shipmentRows.length === 0 ? (
          <Box style={{ height: 400, width: 600 }}>
            <DataGrid
              rows={recipeRows}
              columns={recipeColumns}
              columnVisibilityModel={recipeColumnVisibilityModel}
              hideFooter
              density='compact'
              sx={{
                backgroundColor: 'white',
                '& .MuiDataGrid-row': {
                  color: 'black',
                },
              }}
            />
          </Box>
        ) : (
          <Box style={{ height: '45vh', width: '95vw' }}>
            <ShipmentsDatagrid
              rows={shipmentRows}
              setRows={setShipmentRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          </Box>
        )}
      </Popover>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        loading={loading}
        columnVisibilityModel={columnVisibilityModel}
        density='compact'
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        slots={{
          toolbar: Toolbar,
        }}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-row': {
            color: 'black',
          },
        }}
      />
      <CreateInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        rows={rows}
        setRows={setRows}
        itemOptions={type === "Product" ? productsRows : materialsRows}
        integrations={type === "Product" ? integrations.filter(row => (['3PL', 'Manufacturer']).includes(row.integration_type.category)) :
          integrations.filter(row => row.integration_type.category === "Manufacturer")}
        type={type === "Product" ? 'product' : 'material'}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
      <CreateSupplierDialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        itemOptions={materialsRows}
        integrations={integrations.filter(row => row.integration_type.category === "Supplier")}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
    </>
  )
}