import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import LabeledDropdown from './LabeledDropdown';
import { createMaterialsInventory } from '../api/products';

const CreateSupplierDialog = ({
  open,
  onClose,
  itemOptions,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  inputSelectedItem = null,
}) => {
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedItem, setSelectedItem] = useState(inputSelectedItem || '');
  const [leadTime, setLeadTime] = useState('');

  useEffect(() => {
    if (inputSelectedItem) {
      setSelectedItem(inputSelectedItem);
    }
  }, [inputSelectedItem]);


  const handleCreateInventory = async () => {
    if (!selectedPartner || !selectedItem || !leadTime) {
      setSnackbarOpen(true);
      setSnackbarMessage('Please fill out remaining fields.');
      setSnackbarAlert("error");
      return;
    }
    try {
      await createMaterialsInventory(
        {
          integration_id: selectedPartner,
          material_id: selectedItem,
          lead_time: leadTime,
        }
      );
      setSnackbarOpen(true);
      setSnackbarMessage('Material added to supplier.');
      setSnackbarAlert("success");
      setLeadTime('');
      setSelectedPartner('');
      setSelectedItem('');
      onClose();
    }
    catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Add Supplier</DialogTitle>
      <DialogContent>
        <LabeledDropdown
          label="Partner"
          value={selectedPartner}
          sx={{ my: '0.5rem' }}
          options={integrations.sort((a, b) => a.name.localeCompare(b.name)).map(row => ({ value: row.id, label: row.name }))}
          onChange={(event) => setSelectedPartner(event.target.value)}
        />
        <LabeledDropdown
          label="Item"
          value={selectedItem}
          sx={{ my: '0.5rem' }}
          options={itemOptions.map((row) => ({ value: row.id, label: `${row.name} [${row.sku}]` }))}
          onChange={(event) => setSelectedItem(event.target.value)}
        />
        <TextField
          fullWidth
          size='small'
          margin="normal"
          label="Estimated Lead Time (days)*"
          autoComplete='off'
          value={leadTime}
          sx={{ my: '0.5rem' }}
          onChange={(event) => setLeadTime(event.target.value)}
          type="number"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateInventory} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSupplierDialog;

