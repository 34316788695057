import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Dialog, Box, DialogTitle, DialogContent, Tooltip, IconButton } from '@mui/material';
import { fetchNextOrder } from '../api/orders';
import POCreationBox from './POCreationBox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';


const ActionsDialogView = ({
  open,
  integrations,
  productsRows,
  materialsRows,
  handleClose,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputIntegration, setInputIntegration] = useState('');

  useEffect(() => {
    setLoading(true);
    if (open) {
      fetchNextOrder()
        .then((response) => {
          setActions(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        });
    }
  }, [open]);

  const actionsColumns = [
    { field: 'partner_name', headerName: 'Partner', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'item_type',
      headerName: 'Item Type',
      flex: 0.75,
      renderCell: (params) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {params.value === 'Product' ? <WidgetsIcon sx={{ mr: '10px' }} /> : <OilBarrelIcon sx={{ mr: '10px' }} />}
          {params.value}
        </span>
      ),
    },
    { field: 'item_name', headerName: 'Item', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'item_sku', headerName: 'SKU', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'next_order_date',
      headerName: 'Order By',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {format(date, 'MM/dd/yy')}
            {params.row.next_order < 7 ?
              <Tooltip title="Send Order ASAP">
                <ErrorOutlineOutlinedIcon sx={{ color: 'red', ml: '0.25rem' }} />
              </Tooltip> : params.row.next_order < 30 ?
                <Tooltip title="Inquire about current lead times">
                  <WarningAmberIcon sx={{ color: 'orange', ml: '0.25rem' }} />
                </Tooltip> : ''
            }
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      display: 'flex',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Create PO">
            <IconButton
              color="inherit"
              onClick={() => setInputIntegration(integrations.find((integration) => integration.id === params.row.integration_id))}
            >
              <CreateOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ]


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Recommended Actions</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '65vh', maxHeight: '65vh', width: inputIntegration ? '50%' : '100%' }}>
              <DataGrid
                rows={actions}
                columns={actionsColumns}
                density='compact'
                loading={loading}
                hideFooter
              />
            </Box>
            {inputIntegration && (
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left', height: '65vh', maxHeight: '65vh', width: '50%', px: 2 }}>
                <IconButton
                  color="error"
                  sx={{ width: '2.5rem', height: '2.5rem', ml: 'auto' }}
                  onClick={() => setInputIntegration('')}
                >
                  <CloseIcon />
                </IconButton>
                <POCreationBox
                  integrations={integrations}
                  productsRows={productsRows}
                  materialsRows={materialsRows}
                  inputIntegration={inputIntegration}
                  setSnackbarAlert={setSnackbarAlert}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarOpen={setSnackbarOpen}
                  setRows={null}
                  showCloseButton={true}
                  onClose={() => setInputIntegration('')}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};


export default ActionsDialogView;
