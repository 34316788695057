import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { format } from 'date-fns';

export default function FinancialsDatagrid({ rows, loading = null }) {
  console.log(rows)
  const sortedRows = rows.sort((a, b) => new Date(a.month) - new Date(b.month));

  return (
    <>
      <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
        <BarChart
          dataset={sortedRows}
          series={[
            {
              dataKey: 'amount',
              label: 'Expenses',
              color: '#292828',
              valueFormatter: (value) => {
                return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
              }
            }
          ]}
          xAxis={[
            {
              dataKey: 'month',
              scaleType: 'band',
              valueFormatter: (value) => format(new Date(value), 'MM/dd/yy'),
            }
          ]}
        />
      </Box>
    </>
  );
}
