import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  List,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
  LinearProgress,
  TextField,
  Popover
} from '@mui/material';
import {
  createDocument,
  fetchDocumentContent
} from '../../api/documents.js';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { updateShipmentDocument, createShipment, deleteShipment, sendShipmentinfo } from '../../api/shipments.js';
import { fetchOrders } from '../../api/orders.js';
import AddIcon from '@mui/icons-material/Add';
import LabeledDropdown from '../LabeledDropdown';
import CustomTextField from '../CustomTextField';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import PeopleIcon from '@mui/icons-material/People';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const EmailPopover = ({ anchorEl, setAnchorEl, selectedShipment, user }) => {
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const [toEmail, setToEmail] = useState("");
  const [ccList, setCcList] = useState("");
  const [loadSending, setLoadSending] = useState(false);
  const [emailBody, setEmailBody] = useState("Please find the shipping documents attached.");

  useEffect(() => {
    if (selectedShipment) {
      setToEmail(selectedShipment[0]?.contact_email);
      setCcList(user?.email);
    }
  }, [selectedShipment, user]);

  const handleTriggerEmail = async () => {
    try {
      setLoadSending(true);
      await sendShipmentinfo(selectedShipment, toEmail, ccList, emailBody);
      handleClose();
      setLoadSending(false);
    } catch (error) {
      console.error('Error sending shipment:', error);
      setLoadSending(false);
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        sx={{
          width: '600px',
          height: '100%',
          p: '2rem',
          gap: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          border: '1px solid black',
          borderRadius: '15px'
        }}
      >
        <Typography variant="body1" display={'flex'} alignItems={'center'}>
          <PeopleIcon sx={{ mr: '0.5rem' }} />
          <b>Share Documents</b>
        </Typography>
        <Typography variant="body2">
          <b>Email will be sent from no-reply@trinity3.co</b>
        </Typography>
        <Typography variant="body2">
          Order: <b>{selectedShipment[0]?.order_name}</b>
        </Typography>
        <TextField
          label="To"
          fullWidth
          autoComplete='off'
          size="small"
          value={toEmail}
          onChange={(e) => setToEmail(e.target.value)}
        />
        <TextField
          label="CC (comma separated)"
          fullWidth
          autoComplete='off'
          size="small"
          value={ccList}
          onChange={(e) => setCcList(e.target.value)}
        />
        <TextField
          label="Email Message"
          fullWidth
          multiline
          rows={6}
          size="small"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', width: '100%', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
            <b>Attached Documents:</b>
          </Typography>
          {selectedShipment && selectedShipment.length > 0 &&
            selectedShipment.map((item, index) => (
              item.document_names && item.document_names.length > 0 ? (
                item.document_names.map((document, uploadIndex) => (
                  <Box key={`${index}-${uploadIndex}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
                      {document.document_name}
                    </Typography>
                  </Box>
                ))
              ) : null
            ))
          }
        </Box>
        {loadSending ? (
          <LinearProgress sx={{ width: '100%', my: '1rem' }} />
        ) : (
          <Button
            variant="contained"
            onClick={handleTriggerEmail}
          >
            Send
          </Button>
        )}
      </Box>
    </Popover>
  );
};

const DocumentsPopover = ({ anchorEl, setAnchorEl, selectedDocuments }) => {
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handleDownload = async (integration_id, msg_id, document_name) => {
    let data;
    if (msg_id === 'upload') {
      data = await fetchDocumentContent(msg_id + '/' + integration_id, document_name);
    }
    else {
      data = await fetchDocumentContent(msg_id, document_name);
    }
    const binaryData = atob(data);
    const byteNumbers = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteNumbers[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers]);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', document_name);
    document.body.appendChild(link);
    link.click();

    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', p: '1rem', width: '100%', justifyContent: 'flex-start' }}>
        <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
          <b>Attached Documents</b>
        </Typography>
        {selectedDocuments.document_names && selectedDocuments.document_names.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '0.5rem' }}>
            <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
              {item.document_name}
            </Typography>
            <IconButton onClick={() => handleDownload(selectedDocuments.integration_id, item.msg_id, item.document_name)}>
              <DownloadIcon fontSize='small' />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default function ShipmentsDatagrid({
  user,
  rows,
  addingShipment,
  setAddingShipment,
  setRows,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading = null,
  showToolbar = false,
  customToolbar = null,
  hideCols = {},
}) {
  const [skuList, setSkuList] = useState([]);
  const [isExistingPO, setIsExistingPO] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const [bolNumber, setBolNumber] = useState('');
  const [totalFreight, setTotalFreight] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [pickupDate, setPickupDate] = React.useState(dayjs());
  const [etaDate, setEtaDate] = useState(dayjs().add(14, 'day'));
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedPartnerTo, setSelectedPartnerTo] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [savingShipment, setSavingShipment] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [docsAnchorEl, setDocsAnchorEl] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedPO, setSelectedPO] = useState("");
  const [orderType, setOrderType] = useState('product');
  const fileInputRefs = useRef({});

  const columns = [
    {
      field: 'order_name',
      headerName: 'Order',
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.order_name || params.row.receiving_order_name
    },
    {
      field: 'receiving_order_name',
      headerName: 'Receiving Order',
      editable: true,
      flex: 1.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.receiving_order_name || params.row.receiving_order_name
    },
    {
      field: 'partners',
      headerName: 'From/To',
      flex: 2,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.row.integration_name}
          <ArrowForwardIcon />
          {params.row.integration_name_to}
        </Box>
      )
    },
    {
      field: 'name', headerName: 'Item', flex: 1.5, headerClassName: 'super-app-theme--header', renderCell: (params) => (
        <>
          <span>{params.value} [{params.row.sku}]</span>
        </>
      )
    },
    { field: 'lot_number', headerName: 'Lot', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'expiration_date_raw', headerName: 'Exp. Date', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    {
      field: 'shipment_qty',
      headerName: 'Quantity',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    {
      field: 'total_freight',
      headerName: 'Freight Cost',
      flex: 1,
      editable: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? `$${Number(params.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : ''
    },
    { field: 'carrier', headerName: 'Carrier', flex: 1.0, editable: true, headerClassName: 'super-app-theme--header' },
    {
      field: 'pick_up_date',
      headerName: 'Pickup Date',
      flex: 1,
      editable: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.pick_up_date ? format(new Date(params.row.pick_up_date), 'MM/dd/yyyy') : ''
    },
    {
      field: 'delivery_date',
      headerName: 'ETA',
      editable: true,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.delivery_date ? format(new Date(params.row.delivery_date), 'MM/dd/yyyy') : ''
    },
    { field: 'bol_string', headerName: 'BOL', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    { field: 'tracking_number', headerName: 'Tracking', flex: 1, editable: true, headerClassName: 'super-app-theme--header' },
    {
      field: 'is_delivered', headerName: 'Delivered?', editable: true, flex: 0.85, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_delivered: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'document_names',
      headerName: 'Actions',
      flex: 1.5,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center">
          {params.value && (
            <>
              {selectedDocuments && (
                <DocumentsPopover
                  anchorEl={docsAnchorEl}
                  setAnchorEl={setDocsAnchorEl}
                  selectedDocuments={selectedDocuments}
                />
              )}
              <IconButton onClick={(event) => handleExpandDocuments(event, params.row)}>
                <InsertDriveFileOutlinedIcon sx={{ color: 'black' }} />
              </IconButton>
            </>
          )}
          <IconButton onClick={() => handleUploadClick(params.row.id)}>
            <NoteAddOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
          <input
            type="file"
            ref={getFileInputRef(params.row.id)}
            style={{ display: 'none' }}
            onChange={(e) =>
              handleFileChange(e, params.row.integration_id, params.row.id, params.row.receiving_order_name || params.row.order_name)
            }
          />
          {params.row.document_names && (
            <>
              {selectedShipment && (
                <EmailPopover
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  selectedShipment={selectedShipment}
                  user={user}
                />
              )}
              <IconButton onClick={(event) => handleSendShipmentEmail(event, params.row.shipment_header_id)}>
                <SendIcon sx={{ color: 'black' }} />
              </IconButton>
            </>
          )}
          {!params.row.is_delivered && (
            <IconButton onClick={() => handleDeleteShipment(params.row.id)}>
              <DeleteOutlineIcon sx={{ color: 'black' }} />
            </IconButton>
          )}
        </Box>
      )
    },
  ]

  const handleSendShipmentEmail = async (event, shipment_header_id) => {
    const selectedShipments = rows.filter(row => row.shipment_header_id === shipment_header_id);
    setSelectedShipment(selectedShipments);
    setAnchorEl(event.currentTarget)
  };

  const handleExpandDocuments = async (event, shipment_row) => {
    setSelectedDocuments(shipment_row);
    setDocsAnchorEl(event.currentTarget)
  };

  const processRowUpdate = async (newRow, oldRow) => {
    try {
      const updatedShipments = await updateShipmentDocument([newRow]);
      setRows((prevRows) => {
        return prevRows.map((row) => {
          const updatedShipment = updatedShipments.find((shipment) => shipment.id === row.id);
          return updatedShipment ? updatedShipment : row;
        });
      });

      const updatedRow = updatedShipments.find((shipment) => shipment.id === newRow.id);
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      return updatedRow || oldRow;

    } catch (error) {
      console.error('Error updating shipment:', error);
      setSnackbarOpen(true);
      setSnackbarMessage("Error updating shipment.");
      setSnackbarAlert("error");
      return oldRow;
    }
  };

  const handleAddShipment = () => {
    setAddingShipment(true);
  };

  const handleSkuInputChange = (index, field, value) => {
    const newSkuList = [...skuList];
    newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
    setSkuList(newSkuList);
  };

  const handleRemoveItem = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const getFileInputRef = (rowId) => {
    if (!fileInputRefs.current[rowId]) {
      fileInputRefs.current[rowId] = React.createRef();
    }
    return fileInputRefs.current[rowId];
  };

  const handleUploadClick = (rowId) => {
    const inputRef = getFileInputRef(rowId);
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event, rowId, integrationId, receiving_order_name) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'application/zip')
    ) {
      uploadDocument(file, integrationId, rowId, receiving_order_name);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDF, PNG, JPEG, and ZIP files are allowed.');
    }
  };

  const uploadDocument = async (file, rowId, integrationId, receiving_order_name) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        await createDocument(integrationId, receiving_order_name + '_' + file.name, b64encodedString, rowId);
        setRows((prevRows) => {
          return prevRows.map((row) => {
            if (row.id === rowId) {
              return {
                ...row,
                document_names: row.document_names ? [...row.document_names, { "msg_id": "upload", "document_name": receiving_order_name + '_' + file.name }] : [{ "msg_id": "upload", "document_name": receiving_order_name + '_' + file.name }]
              };
            }
            return row;
          });
        });
        setSnackbarAlert('success');
        setSnackbarOpen(true);
        setSnackbarMessage('Document uploaded.');
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      }
    };
  };


  const handleAddItem = () => {
    if (orderType === 'material') {
      setSkuList(
        [...skuList,
        {
          material_id: materialsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: ''
        }
        ]);
    } else {
      setSkuList(
        [...skuList,
        {
          product_id: productsRows[0].id,
          qty: selectedPartner.minimum_order_qty,
          unit_price: ''
        }
        ]);
    }
  }


  const handleSaveShipment = async () => {
    setSavingShipment(true);
    try {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: selectedPartner,
        integration_id_to: selectedPartnerTo || null,
        total_freight: totalFreight || null,
        order_name: orderNumber,
        carrier: String(carrierName),
        bol_string: bolNumber,
        tracking_number: String(trackingNumber),
        qty: item.qty,
        pick_up_date: pickupDate.format('YYYY/MM/DD'),
        delivery_date: etaDate.format('YYYY/MM/DD'),
        unit_price: item.unit_price || null,
        is_existing_order: isExistingPO,
        order_type: orderType,
        documents: item.uploads
      }));
      const response = await createShipment(updatedSkuList);
      setRows([...rows, ...response]);
      setAddingShipment(false);
      setSkuList([]);
      setIsExistingPO(false);
      setOrderNumber('');
      setCarrierName('');
      setBolNumber('');
      setTotalFreight('');
      setTrackingNumber('');
      setPickupDate(dayjs());
      setEtaDate(dayjs());
      setSelectedPartner('');
      setSelectedPartnerTo('');
      setSnackbarAlert("success");
      setSnackbarOpen(true);
      setSnackbarMessage("Shipment created successfully.");
    } catch (error) {
      console.error('Error saving shipment:', error);
      setSnackbarAlert("error");
      setSnackbarOpen(true);
      setSnackbarMessage("There was an error creating the shipment, make sure all required fields are populated.");
    } finally {
      setSavingShipment(false);
    }
  }

  const handleLoadOptions = async (integration_id) => {
    setLoadingOptions(true);
    try {
      const orderType = integrations.find((integration) => integration.id === integration_id)?.integration_type.category === 'Supplier' ? 'material' : 'product';
      const data = await fetchOrders(integration_id, orderType, ['PO Sent', 'Awaiting Shipment']);
      setOrderDetails(data);
      const distinctOrderNames = [...new Set(data.map(order => order.order_name))];
      setOptions(distinctOrderNames);
    } catch (error) {
      console.error('Error:', error);
      setOptions([]);
    }
    setLoadingOptions(false);
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = (index) => {
    fileInputRef.current.setAttribute('data-index', index);
    fileInputRef.current.click();
  };

  const handleFileUpload = (index, files) => {
    const newSkuList = [...skuList];

    if (!newSkuList[index].uploads) {
      newSkuList[index].uploads = [];
    }

    const uploadedFiles = Array.from(files).map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve({
            file_name: file.name,
            file_data: reader.result.split(',')[1],
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(uploadedFiles).then((filesData) => {
      newSkuList[index].uploads = [
        ...newSkuList[index].uploads,
        ...filesData,
      ];
      setSkuList(newSkuList);
    });
  };

  const handleRemoveUpload = (index, uploadIndex) => {
    const newSkuList = [...skuList];
    newSkuList[index].uploads = newSkuList[index].uploads.filter((_, i) => i !== uploadIndex);
    setSkuList(newSkuList);
  }

  const handleDeleteShipment = async (shipment_id) => {
    try {
      await deleteShipment(shipment_id);
      setRows(rows.filter((row) => row.id !== shipment_id));
    } catch (error) {
      console.error('Error deleting shipment:', error);
    };
  };


  const renderOptions = () => {
    if (orderType === 'material' && materialsRows.length > 0) {
      return materialsRows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(material => (
          <MenuItem key={material.id} value={material.id}>
            {material.name} [{material.sku}]
          </MenuItem>
        ));
    } else if (productsRows.length > 0) {
      return productsRows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name} [{product.sku}]
          </MenuItem>
        ));
    }
  };

  const ShipmentsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddShipment}
        >
          Add Shipment
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {!addingShipment ? (
        <DataGrid
          rows={rows}
          columns={columns}
          density='compact'
          hideFooter
          processRowUpdate={processRowUpdate}
          columnVisibilityModel={hideCols}
          loading={loading}
          onProcessRowUpdateError={(error) => console.error('Row update error:', error)}
          slots={{
            toolbar: showToolbar ? ShipmentsToolbar : (customToolbar ? customToolbar : null),
          }}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-row': {
              color: 'black',
            },
          }}
        />
      ) : (
        <>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', justifyContent: 'left', alignItems: 'top' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'top', p: '1rem' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <LabeledDropdown
                  label="From Partner *"
                  sx={{ my: '0.25rem', width: '50%' }}
                  options={integrations.filter((integration) => !['HQ', '3PL'].includes(integration.integration_type.category)).map(row => ({ value: row.id, label: row.name }))}

                  value={selectedPartner}
                  onChange={(e) => {
                    setSelectedPO('');
                    setSelectedPartnerTo('');
                    setSkuList([]);
                    setOrderType(integrations.find(row => row.id === e.target.value).integration_type.category === 'Supplier' ? 'material' : 'product');
                    setSelectedPartner(e.target.value);
                  }}
                />
                <LabeledDropdown
                  label="To Partner *"
                  sx={{ my: '0.5rem', width: '50%' }}
                  options={
                    (integrations.find((integration) => integration.id === selectedPartner)?.integration_type.category === 'Supplier') ?
                      integrations.filter((integration) => integration.integration_type.category === 'Manufacturer').map(row => ({ value: row.id, label: row.name })) :
                      integrations.filter((integration) => integration.integration_type.category === '3PL').map(row => ({ value: row.id, label: row.name }))
                  }
                  value={selectedPartnerTo}
                  onChange={(e) => setSelectedPartnerTo(e.target.value)}
                />
              </Box>
              {selectedPartner && (
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                  <FormControl sx={{ width: '50%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={isExistingPO} onChange={() => setIsExistingPO(!isExistingPO)} />
                      }
                      label="Shipment is for existing PO"
                    />
                  </FormControl>
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                {isExistingPO ? (
                  <Select
                    onOpen={() => handleLoadOptions(selectedPartner)}
                    value={selectedPO}
                    onChange={(e) => {
                      setSelectedPartnerTo(orderDetails.find((order) => order.order_name === e.target.value).integration_id_to);
                      const orders = orderDetails.filter((order) => order.order_name === e.target.value);
                      if (orders) {
                        setSkuList(orders.map(element => ({
                          material_order_id: element.id,
                          product_order_id: element.id,
                          material_id: element.material_id,
                          product_id: element.product_id,
                          unit_of_measure: element.unit_of_measure,
                          qty: element.qty,
                          unit_price: element.unit_price
                        })));
                      }
                      setSelectedPO(e.target.value)
                    }}
                    size="small"
                    sx={{ width: '50%' }}
                    renderValue={(value) => value || 'Select Order'}
                  >
                    {loadingOptions ? (
                      <MenuItem disabled>
                        <CircularProgress size={20} />
                      </MenuItem>
                    ) : (
                      options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                ) : (
                  <CustomTextField
                    label="Order Name"
                    required={false}
                    size='small'
                    variant="outlined"
                    sx={{ my: '0.5rem', width: '50%' }}
                    fullWidth
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                )}
                <CustomTextField
                  label="Carrier"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '50%' }}
                  fullWidth
                  value={carrierName}
                  onChange={(e) => setCarrierName(e.target.value)}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                <CustomTextField
                  label="Total Freight ($)"
                  type="number"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '33%' }}
                  fullWidth
                  value={totalFreight}
                  onChange={(e) => setTotalFreight(e.target.value)}
                />
                <CustomTextField
                  label="BOL"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '33%' }}
                  fullWidth
                  value={bolNumber}
                  onChange={(e) => setBolNumber(e.target.value)}
                />
                <CustomTextField
                  label="Tracking ID"
                  required={false}
                  size='small'
                  variant="outlined"
                  sx={{ my: '0.5rem', width: '33%' }}
                  fullWidth
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={pickupDate}
                    onChange={(newValue) => setPickupDate(newValue)}
                    sx={{
                      my: '0.5rem',
                      width: '33%',
                      '& .MuiInputBase-root': {
                        height: '40px',
                        '& .MuiSvgIcon-root': {
                          color: 'black',
                        },
                      },
                    }}
                    label="Shipment Date"
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={etaDate}
                    onChange={(newValue) => setEtaDate(newValue)}
                    sx={{
                      my: '0.5rem',
                      width: '33%',
                      '& .MuiInputBase-root': {
                        height: '40px',
                        '& .MuiSvgIcon-root': {
                          color: 'black',
                        },
                      },
                    }}
                    label="ETA Date"
                  />
                </LocalizationProvider>
              </Box>
              <List sx={{ maxHeight: '30vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
                {skuList.map((item, index) => (
                  <ListItem key={index} sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <CustomTextField
                          label="Receiving Order ID"
                          required={false}
                          value={item.warehouse_order}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'warehouse_order', e.target.value)}
                        />
                        <FormControl size='small' fullWidth sx={{ mr: '5px' }}>
                          <InputLabel id={item.id}>Item *</InputLabel>
                          <Select
                            labelId={item.id}
                            id={item.id}
                            key={item.id}
                            label="Item *"
                            value={item[`${orderType}_id`]}
                            onChange={(e) => handleSkuInputChange(index, `${orderType}_id`, e.target.value)}
                          >
                            {renderOptions()}
                          </Select>
                        </FormControl>
                        <CustomTextField
                          label="Lot"
                          required={false}
                          value={item.lot_number}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'lot_number', e.target.value)}
                        />
                        <CustomTextField
                          label="Expiration Date"
                          required={false}
                          value={item.expiration_date}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'expiration_date_raw', e.target.value)}
                        />
                        <CustomTextField
                          label={orderType === 'material' ? `Quantity (${item.unit_of_measure})` : 'Quantity'}
                          type='number'
                          value={item.qty}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
                        />
                        <CustomTextField
                          label="Unit ($)"
                          type='number'
                          required={false}
                          value={item.unit_price}
                          sx={{ mr: '5px' }}
                          onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
                        />
                        <IconButton onClick={() => triggerFileInput(index)}>
                          <NoteAddOutlinedIcon sx={{ color: 'black' }} />
                        </IconButton>
                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <DeleteOutlineIcon sx={{ color: 'black' }} />
                        </IconButton>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', width: '100%', justifyContent: 'flex-end' }}>
                        {item.uploads && item.uploads.map((upload, uploadIndex) => (
                          <Box key={uploadIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ color: 'black', fontSize: '0.875rem' }}>
                              {upload.file_name}
                            </Typography>
                            <IconButton onClick={() => handleRemoveUpload(index, uploadIndex)}>
                              <DeleteOutlineIcon sx={{ color: 'red', fontSize: '1rem' }} />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </ListItem>
                ))}
                <input
                  type="file"
                  multiple
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const index = parseInt(fileInputRef.current.getAttribute('data-index'), 10);
                    handleFileUpload(index, e.target.files);
                    e.target.value = '';
                  }}
                />
              </List>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', my: '0.5rem' }}>
                {savingShipment ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <LinearProgress sx={{ width: '100%' }} color='primary' />
                    <Typography variant="body2">Saving...</Typography>
                  </Box>
                ) : (
                  <>
                    <Button
                      color="primary"
                      variant='outlined'
                      disabled={isExistingPO}
                      sx={{ width: '20%' }}
                      startIcon={<AddIcon />}
                      onClick={handleAddItem}
                    >
                      Add Item
                    </Button>
                    <Button
                      color="primary"
                      variant='contained'
                      disabled={skuList.length === 0}
                      sx={{ width: '20%' }}
                      startIcon={<SaveOutlinedIcon />}
                      onClick={handleSaveShipment}
                    >
                      Save Shipment
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
