import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarAlert = ({ snackbarOpen, handleSnackbarClose, message, severity }) => {
  return (
    <Snackbar
      open={snackbarOpen}
      onClose={handleSnackbarClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
