import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box
} from '@mui/material';
import POCreationBox from './POCreationBox';

const CreatePODialog = ({
  open,
  onClose,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  setRows = null,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Create Draft PO</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '45vh', maxHeight: '45vh' }}>
          <POCreationBox
            integrations={integrations}
            productsRows={productsRows}
            materialsRows={materialsRows}
            setSnackbarAlert={setSnackbarAlert}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setRows={setRows}
            onClose={onClose}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePODialog;
