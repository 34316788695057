// financials.js

import { axiosInstance, checkToken } from './utils.js'

const fetchFinancials = async (integration_id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/financials/${integration_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast:', error);
    throw error;
  }
};


export {
  fetchFinancials,
};
