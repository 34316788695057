// products.js

import { axiosInstance, checkToken } from './utils.js'

const fetchProducts = async (integrationId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/products/${integrationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

const fetchDeliveredProducts = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/delivered_products/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


const createProduct = async (productData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/products/', productData);
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};


const updateProduct = async (productId, updated_product) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/products/${productId}`, updated_product);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

const deleteProduct = async (productId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/products/${productId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting product:', error);
    throw error;
  }
};


const fetchMaterials = async (integrationId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/materials/${integrationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

const fetchDeliveredMaterials = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/delivered_materials/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


const fetchMaterialsInRecipes = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/recipes_materials/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


const createMaterial = async (materialData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/materials/', materialData);
    return response.data;
  } catch (error) {
    console.error('Error creating material:', error);
    throw error;
  }
};

const updateMaterial = async (materialId, updated_material) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/materials/${materialId}`, updated_material);
    return response.data;
  } catch (error) {
    console.error('Error updating material:', error);
    throw error;
  }
};


const deleteMaterial = async (materialId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/materials/${materialId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting material:', error);
    throw error;
  }
};

const fetchRecipe = async (productId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/recipes/${productId}`);
    return response.data;
  } catch (error) {
    console.error('Error creating material:', error);
    throw error;
  }
};

const fetchRecipeReverse = async (materialId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/recipes_reverse/${materialId}`);
    return response.data;
  } catch (error) {
    console.error('Error creating material:', error);
    throw error;
  }
};


const fetchAllRecipe = async (productId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/recipes/`);
    return response.data;
  } catch (error) {
    console.error('Error creating material:', error);
    throw error;
  }
};

const createRecipe = async (recipeData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/recipes/', recipeData);
    return response.data;
  } catch (error) {
    console.error('Error creating material:', error);
    throw error;
  }
};

const updateRecipe = async (recipeId, updated_recipe) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/recipes/${recipeId}`, updated_recipe);
    return response.data;
  } catch (error) {
    console.error('Error updating material:', error);
    throw error;
  }
};

const deleteRecipe = async (recipeId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/recipes/${recipeId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting recipe:', error);
    throw error;
  }
};

const fetchInventory = async (integrationId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/inventory/${integrationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

const createProductsInventory = async (inventoryData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/inventory/', inventoryData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};

const updateProductsInventory = async (inventoryId, updated_inventory) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/inventory/${inventoryId}`, updated_inventory);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory:', error);
    throw error;
  }
};


const deleteProductsInventory = async (inventoryId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/inventory/${inventoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting inventory:', error);
    throw error;
  }
};

const fetchMaterialsInventory = async (integrationId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/materials_inventory/${integrationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching materials inventory:', error);
    throw error;
  }
};

const createMaterialsInventory = async (materialInventoryData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/materials_inventory/', materialInventoryData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};

const updateMaterialsInventory = async (inventoryId, updated_inventory) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/materials_inventory/${inventoryId}`, updated_inventory);
    return response.data;
  } catch (error) {
    console.error('Error updating inventory:', error);
    throw error;
  }
};

const deleteMaterialsInventory = async (inventoryId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`/materials_inventory/${inventoryId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting inventory:', error);
    throw error;
  }
};

const fetchAllInventory = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/inventory/');
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

const fetchAllMaterialInventory = async () => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get('/materials_inventory/');
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

const fetchHistoryInventory = async (type, integrationId, itemId) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(`/inventory_history/${type}/${integrationId}/${itemId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory:', error);
    throw error;
  }
};

const createBulkInventory = async (inventoryData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/inventory/bulk/', inventoryData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};


const uploadFile = async (type, integrationId, fileData) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(`/upload/${type}/${integrationId}`, fileData);
    return response.data;
  } catch (error) {
    console.error('Error creating inventory:', error);
    throw error;
  }
};

export {
  fetchProducts,
  fetchDeliveredProducts,
  fetchDeliveredMaterials,
  fetchMaterialsInRecipes,
  createProduct,
  updateProduct,
  deleteProduct,
  fetchMaterials,
  createMaterial,
  updateMaterial,
  deleteMaterial,
  fetchAllRecipe,
  fetchRecipe,
  fetchRecipeReverse,
  createRecipe,
  updateRecipe,
  deleteRecipe,
  fetchInventory,
  createProductsInventory,
  updateProductsInventory,
  deleteProductsInventory,
  fetchMaterialsInventory,
  createMaterialsInventory,
  updateMaterialsInventory,
  deleteMaterialsInventory,
  fetchAllInventory,
  fetchAllMaterialInventory,
  fetchHistoryInventory,
  createBulkInventory,
  uploadFile,
};
