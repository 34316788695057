import { Box, IconButton, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';


export default function EmailLogsDatagrid({ rows, loading = null }) {

  const columns = [
    {
      field: 'msg_id',
      headerName: 'Source',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <IconButton
            key="email-icon"
            component="a"
            href={`https://mail.google.com/mail/u/0/#inbox/${params.row.msg_id}`}
            target="_blank"
          >
            <EmailOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
        )
      }
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <Chip
          label={params.value.replace(/_/g, ' ')}
          color={params.value === 'ORDER_UPDATE' ? "info" : params.value === 'PRICING' ? "error" : "success"}
          sx={{
            height: '65%',
            color: 'white',
          }}
        />
      ),
    },
    { field: 'email_from', headerName: 'From', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'subject', headerName: 'Subject', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'context',
      headerName: 'Context',
      flex: 2,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => <b>"{params.value}"</b>
    },
    {
      field: 'msg_timestamp', headerName: 'Email Time', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        height: '83vh',
        maxHeight: '83vh',
        bgcolor: 'white',
        border: '1px solid #ccc',
        borderRadius: '15px'
      }}>
      <DataGrid
        rows={rows}
        loading={loading}
        columns={columns}
        density='compact'
        hideFooter
      />
    </Box>
  );
}
