import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  List,
  ListItem,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  LinearProgress
} from '@mui/material';
import { createOrders, fetchAllOrders } from '../api/orders';
import { fetchMaterialsInventory, fetchInventory } from '../api/products';
import { triggerPdfPreview, triggerPoEmail, updateOrderStatus } from '../api/orders';
import CustomTextField from './CustomTextField';
import LabeledDropdown from './LabeledDropdown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PDFViewer from './PDFViewer.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const POCreationBox = ({
  poNumber,
  integrations,
  materialsRows,
  productsRows,
  inputIntegrationFrom,
  inputIntegration,
  inputIds,
  setSnackbarAlert,
  setSnackbarMessage,
  setSnackbarOpen,
  setRows,
  onClose,
  logoBlob = null
}) => {
  const [skuList, setSkuList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedPartnerTo, setSelectedPartnerTo] = useState('');
  const [selectedPartnerOptions, setSelectedPartnerOptions] = useState([]);
  const [validRowOptions, setValidRowOptions] = useState([]);
  const [skipValidRowOptions, setSkipValidRowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [ccList, setCcList] = useState('');
  const [poMessage, setPoMessage] = useState('Please find the attached PO. Thank you.');
  const [orderType, setOrderType] = useState('product');
  const [orderRows, setOrderRows] = useState([]);

  useEffect(() => {
    if (inputIntegration) {
      setSelectedPartner(inputIntegrationFrom.id);
      setSelectedPartnerTo(inputIntegration.id);
      setSkipValidRowOptions(true);
      const partnerToType = integrations.find(row => row.id === inputIntegration.id).integration_type.category;
      setCcList(inputIntegration.contact_email);
      setOrderType(partnerToType === 'Manufacturer' ? 'material' : 'product');
      setSelectedPartnerOptions(
        integrations.filter(row =>
          (partnerToType === 'Manufacturer' ? ['Supplier'] : ['Manufacturer']).includes(row.integration_type.category)
        )
      );
      if (inputIds && partnerToType === 'Manufacturer') {
        setSkuList(inputIds.map(id => ({
          material_id: id.item_id,
          qty: inputIntegrationFrom.minimum_order_qty,
          unit_of_measure: materialsRows.find(row => row.id === id.item_id)?.purchasing_uom,
          unit_price: materialsRows.find(row => row.id === id.item_id)?.default_price,
          item_info: id.item_info,
          tax_rate: 0
        })))
      } else if (inputIds) {
        setSkuList(inputIds.map(id => ({
          product_id: id.item_id,
          qty: inputIntegrationFrom.minimum_order_qty,
          unit_of_measure: 'units',
          unit_price: '',
          item_info: id.item_info,
          tax_rate: 0
        })))
      }
    } else {
      setSelectedPartnerOptions(
        integrations.filter(row => !['HQ', '3PL'].includes(row.integration_type.category))
      );
    }

  }, [inputIntegration, inputIntegrationFrom, integrations, inputIds, materialsRows, productsRows]);

  const handleSetPartner = async (event) => {
    const partnerType = integrations.find(row => row.id === event.target.value).integration_type.category;
    setSelectedPartner(event.target.value);
    setOrderType(partnerType === 'Supplier' ? 'material' : 'product');
    if (partnerType === 'Supplier') {
      const data = await fetchMaterialsInventory(event.target.value);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.material_id);
      setValidRowOptions(validIds);
    } else {
      const data = await fetchInventory(event.target.value);
      const validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.product_id);
      setValidRowOptions(validIds);
    }
  };

  const handleSkuInputChange = useCallback((index, field, value) => {
    setSkuList(prevSkuList => {
      const newSkuList = [...prevSkuList];
      newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
      return newSkuList;
    });
  }, []);

  const handleRemoveItem = useCallback((index) => {
    setSkuList(prevSkuList => prevSkuList.filter((_, i) => i !== index));
  }, []);

  const handleAddItem = useCallback(() => {
    if (orderType === 'material') {
      setSkuList(prevSkuList => [
        ...prevSkuList,
        {
          material_id: materialsRows[0].id,
          qty: integrations.find(row => row.id === selectedPartner).minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: materialsRows[0]?.default_price,
          tax_rate: 0
        }
      ]);
    } else {
      setSkuList(prevSkuList => [
        ...prevSkuList,
        {
          product_id: productsRows[0].id,
          qty: integrations.find(row => row.id === selectedPartner).minimum_order_qty,
          unit_of_measure: selectedPartner.minimum_order_units,
          unit_price: '',
          tax_rate: 0
        }
      ]);
    }
  }, [materialsRows, orderType, productsRows, selectedPartner, integrations]);

  const handleValidate = () => {
    if (poNumber.trim() === '') {
      setSnackbarAlert("error")
      setSnackbarMessage("Please name the PO")
      setSnackbarOpen(true)
      return false;
    }

    if (skuList.length === 0) {
      setSnackbarAlert("error")
      setSnackbarMessage("Please add items to the PO")
      setSnackbarOpen(true)
      return false;
    }

    for (const item of skuList) {
      if (!item[`${orderType}_id`] || item.qty === '' || item.unit_of_measure === '' || item.unit_price === '') {
        setSnackbarAlert("error")
        setSnackbarMessage("Please complete all items")
        setSnackbarOpen(true)
        return false;
      }
    }

    return true;
  };

  const handleCreateOrders = async () => {
    if (handleValidate()) {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: selectedPartner,
        integration_id_to: selectedPartnerTo || null,
        order_name: poNumber,
        status: "Draft",
        tax_rate: (item.tax_rate || 0) / 100
      }));
      try {
        const order_rows = await createOrders(updatedSkuList, orderType);
        if (setRows) {
          const data = await fetchAllOrders();
          setRows(data);
        }
        return order_rows
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
  };



  const calcMaxLeadTime = (orderRows) => {
    const maxLeadTime = Math.max(...orderRows.map(po => po.lead_time));
    const addDays = (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };
    const currentDate = new Date();
    const calculatedDate = addDays(currentDate, maxLeadTime);
    const formattedNewDate = calculatedDate.toISOString().split('T')[0];
    return formattedNewDate;
  }

  const handlePreviewPO = async (orderRows) => {
    setIsLoading(true);
    try {
      const data = await triggerPdfPreview({
        logo: logoBlob,
        orders: orderRows,
        owner: integrations.find(item => item.integration_type.type === 'HQ'),
        ship_from: inputIntegrationFrom,
        ship_to: inputIntegration,
        cc_list: '',
        msg_body: '',
        expected_arrival: calcMaxLeadTime(orderRows)
      });
      const pdfBase64 = data.body.pdf_base64;
      const byteCharacters = atob(pdfBase64);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      setBlobUrl(blob);
    } catch (error) {
      console.error('Error generating preview:', error);
    }
    setIsLoading(false);
  };

  const handleUpdateStatus = async () => {
    try {
      for (const row of orderRows) {
        await updateOrderStatus(row.id, "Draft", orderType);
      }
      onClose();
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const handleSendPO = async () => {
    setIsLoading(true);
    try {
      await triggerPoEmail({
        logo: logoBlob,
        orders: orderRows,
        owner: integrations.find(item => item.integration_type.type === 'HQ'),
        ship_from: inputIntegrationFrom,
        ship_to: inputIntegration,
        cc_list: ccList,
        msg_body: poMessage,
        expected_arrival: calcMaxLeadTime(orderRows),
        type: orderType
      });
      setSnackbarOpen(true);
      setSnackbarMessage("PO sent successfully.");
      setSnackbarAlert("success");
      onClose();
    } catch (error) {
      console.error('Error fetching orders:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      {blobUrl ? (
        <>
          <Box display="flex" flexDirection="row" height="100%">
            <Box display="flex" flexDirection="column" width="50%" sx={{ px: '0.5rem' }}>
              <PDFViewer pdfBlob={blobUrl} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={'center'}
              width="50%"
              sx={{
                p: '1rem',
                border: '1px solid #ccc',
                borderRadius: '15px'
              }}
            >
              <Typography variant="body1" display={'flex'} alignItems={'center'}>
                <AttachEmailIcon sx={{ mr: '0.5rem' }} />Compose PO Email - PDF will be included as an attachment
              </Typography>
              <Typography variant="body1" sx={{ m: '0.5rem' }}>
                <b>From: {integrations.find(item => item.integration_type.type === 'HQ').name}</b> (email will be sent from <b>no-reply@trinity3.co</b>)
              </Typography>
              <CustomTextField
                label="Subject"
                sx={{ mt: 0 }}
                value={'PO from ' + integrations.find(item => item.integration_type.type === 'HQ').name + ': ' + poNumber}
              />
              <CustomTextField
                label="CC (comma separated list)"
                value={ccList}
                sx={{ my: '0.5rem' }}
                onChange={(e) => setCcList(e.target.value)}
              />
              <Typography variant="body2" sx={{ ml: '0.5rem' }}><b>{integrations.find(item => item.integration_type.type === 'HQ').contact_email} will be automatically CC'ed</b></Typography>
              <CustomTextField
                label="Include message in email body"
                value={poMessage}
                rows={7}
                multiline={true}
                sx={{ my: '0.5rem' }}
                onChange={(e) => setPoMessage(e.target.value)}
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {!isLoading ? (
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                      <Button
                        color="primary"
                        variant='outlined'
                        startIcon={<ArrowBackIcon />}
                        onClick={() => setBlobUrl(null)}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        variant='outlined'
                        startIcon={<CheckIcon />}
                        onClick={handleUpdateStatus}
                      >
                        I'll send myself
                      </Button>
                    </Box>
                    <Button
                      color="primary"
                      variant='contained'
                      startIcon={<SendIcon />}
                      onClick={handleSendPO}
                    >
                      Send PO
                    </Button>
                  </>
                ) : (
                  <Box sx={{ width: '100%', my: '0.5rem' }}>
                    <LinearProgress />
                    <Typography fontSize="14px" sx={{ mt: '0.5rem' }}>
                      Loading...
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <LabeledDropdown
              label="Partner"
              value={selectedPartner}
              disabled
              sx={{ my: '0.5rem', width: '49%', mr: '0.5rem' }}
              options={selectedPartnerOptions.map(row => ({ value: row.id, label: row.name }))}
              onChange={handleSetPartner}
            />
            <LabeledDropdown
              label="Ship To"
              value={selectedPartnerTo}
              disabled
              sx={{ my: '0.5rem', width: '49%' }}
              options={integrations
                .filter((row) =>
                  integrations.find((r) => r.id === selectedPartner)?.integration_type.category === 'Manufacturer'
                    ? row.integration_type.category === '3PL'
                    : row.integration_type.category === 'Manufacturer'
                )
                .map((row) => ({ value: row.id, label: row.name }))}
              onChange={(event) => setSelectedPartnerTo(event.target.value)}
            />
          </Box>

          {selectedPartner && integrations.find(row => row.id === selectedPartner)?.minimum_order_qty && (
            <Typography sx={{ mb: '0.25rem', ml: '0.25rem', fontSize: '0.85rem' }}>
              Minimum Order Quantity: {integrations.find(row => row.id === selectedPartner).minimum_order_qty}{' '}
              {integrations.find(row => row.id === selectedPartner).minimum_order_units}
            </Typography>
          )}

          <CustomTextField
            label="PO Number"
            size="small"
            variant="outlined"
            disabled
            sx={{ my: '0.25rem', width: '49%' }}
            fullWidth
            value={poNumber}
          />

          <List sx={{ maxHeight: '40vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
            {skuList.map((item, index) => (
              <ListItem key={item[`${orderType}_id`]} sx={{ p: 0, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                  <FormControl size="small" fullWidth sx={{ mr: '5px', width: '60%' }}>
                    <InputLabel id={item.id}>Item</InputLabel>
                    <Select
                      labelId={item.id}
                      id={item.id}
                      key={item.id}
                      label="Item"
                      value={item[`${orderType}_id`]}
                      onChange={(e) => {
                        if (orderType === 'material') {
                          const selectedMaterial = materialsRows.find(row => row.id === e.target.value);
                          handleSkuInputChange(index, 'unit_price', selectedMaterial?.default_price || 0);
                          handleSkuInputChange(index, 'unit_of_measure', selectedMaterial?.purchasing_uom || '');
                        }
                        handleSkuInputChange(index, `${orderType}_id`, e.target.value);
                      }}
                    >
                      {orderType === 'material'
                        ? (skipValidRowOptions
                          ? materialsRows.map(row => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))
                          : materialsRows
                            .filter(material => validRowOptions.includes(material.id))
                            .map(row => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.name}
                              </MenuItem>
                            )))
                        : (skipValidRowOptions
                          ? productsRows.map(row => (
                            <MenuItem key={row.id} value={row.id}>
                              {row.name}
                            </MenuItem>
                          ))
                          : productsRows
                            .filter(product => validRowOptions.includes(product.id))
                            .map(row => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.name}
                              </MenuItem>
                            )))}
                    </Select>
                  </FormControl>

                  <CustomTextField
                    label={orderType === 'material' ? `Quantity (${item.unit_of_measure})` : 'Quantity'}
                    type="number"
                    value={item.qty}
                    sx={{ mr: '5px', width: '50%' }}
                    onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
                  />

                  <CustomTextField
                    label="Unit Price ($)"
                    type="number"
                    value={item.unit_price}
                    sx={{ mr: '5px', width: '50%' }}
                    onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
                  />

                  <CustomTextField
                    label="Tax Rate (%)"
                    type="number"
                    sx={{ width: '50%' }}
                    value={item.tax_rate}
                    onChange={(e) => handleSkuInputChange(index, 'tax_rate', e.target.value)}
                  />

                  <IconButton onClick={() => handleRemoveItem(index)}>
                    <DeleteOutlineIcon sx={{ color: 'black' }} />
                  </IconButton>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', width: '100%' }}>
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{
                      my: '0.25rem',
                      ml: '0.25rem',
                    }}
                  >
                    <b>{item?.item_info}</b>
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>

          {!isLoading ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                color="primary"
                variant="outlined"
                disabled={poNumber === ''}
                sx={{ my: '0.25rem', width: '25%' }}
                startIcon={<AddIcon />}
                onClick={handleAddItem}
              >
                Add Item
              </Button>
              <Button
                onClick={async () => {
                  const data = await handleCreateOrders();
                  setOrderRows(data);
                  await handlePreviewPO(data);
                }}
                variant="contained"
                color="primary"
                sx={{ my: '0.25rem', width: '25%' }}
              >
                Create Draft
              </Button>
            </Box>
          ) : (
            <Box sx={{ width: '100%', my: '0.5rem' }}>
              <LinearProgress />
              <Typography fontSize="14px" sx={{ mt: '0.5rem' }}>
                Loading...
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );

};

export default POCreationBox;
