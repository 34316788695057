import React from 'react';
import { TextField } from '@mui/material';

class CustomTextField extends React.Component {
  render() {
    const { label, value, type = 'text', onChange, required = true, multiline = false, rows = 10, sx = {}, ...props } = this.props;

    return (
      <TextField
        label={required ? `${label} *` : label}
        value={value}
        onChange={onChange}
        onWheel={(e) => e.target.blur()}
        type={type}
        size='small'
        autoComplete='off'
        fullWidth
        multiline={multiline}
        rows={rows}
        sx={{
          my: '0.25rem',
          ...sx,
        }}
        {...props}
      />
    );
  }
}

export default CustomTextField;
