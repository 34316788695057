import React, { useState, useEffect } from 'react';
import IntegrationDialogView from './IntegrationDialogView';
import IntransitDialogView from './IntransitDialogView';
import ActionsDialogView from './ActionsDialogView';
import CustomersDialogView from './CustomersDialogView';
import AssistantDialogView from './AssistantDialogView';
import { GoogleMap, LoadScript, Marker, Polyline, Data, MarkerClusterer } from '@react-google-maps/api';
import hq from '../assets/hq.svg';
import mfg from '../assets/factory.svg';
import warehouse from '../assets/warehouse.svg';
import supplier from '../assets/logistics.svg';
import truck from '../assets/truck.svg';
import actions from '../assets/actions.svg';
import actions_urgent from '../assets/icons8-bell-48.png';
import actions_alert from '../assets/icons8-bell-48_.png';
// import document from '../assets/document.svg';
import cluster from '../assets/cluster.svg';
import countries_outline from '../assets/countries.geojson';
import { fetchInventoryAggregates } from '../api/forecast';
import { Typography, Dialog, Button, DialogActions, DialogContent, DialogTitle, Box, IconButton, Tooltip, Divider } from '@mui/material';
import POCreationBox from './POCreationBox';
import WidgetsIcon from '@mui/icons-material/Widgets';

const options = {
  mapId: process.env.REACT_APP_MAP_ID,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  minZoom: 2.1,
  scaleControl: true,
  restriction: {
    latLngBounds: {
      north: 85.00000,
      south: -85.00000,
      east: 180,
      west: -180
    },
    strictBounds: true,
  },
  rotateControl: false,
  trafficLayer: false,
  transitLayer: false,
  disableDefaultUI: true,
};

const MapComponent = ({
  user,
  logoBlob,
  alertsData,
  integrations,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  setIntegrations,
  integrationTypes,
  showRoutes,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  dataSources
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [inputIntegration, setInputIntegration] = React.useState("");
  const [inputIntegrationFrom, setInputIntegrationFrom] = React.useState("");
  const [inputPOName, setInputPOName] = React.useState("");
  const [inputItems, setInputItems] = React.useState([]);
  const [inventoryAggregates, setInventoryAggregates] = React.useState([]);
  const [showByProduct, setShowByProduct] = useState(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [transitDialogOpen, setTransitDialogOpen] = useState(false);
  const [actionsDialogOpen, setActionsDialogOpen] = useState(false);
  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const [assistantDialogOpen, setAssistantDialogOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 38.716, lng: -98.356 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchInventoryAggregates();
        setInventoryAggregates(data);
      } catch (error) {
        console.error('Error fetching inventory aggregates:', error);
      }
    };

    fetchData();
  }, []);

  const handleAggregatesToggle = async () => {
    try {
      const data = await fetchInventoryAggregates(!showByProduct);
      setInventoryAggregates(data);
      setShowByProduct(!showByProduct);
    } catch (error) {
      console.error('Error fetching inventory aggregates:', error);
    }
  };


  const handleMarkerClick = (id, coordinates) => {
    setSelectedIntegrationId(id);
    setMapCenter({ lat: coordinates.lat, lng: coordinates.long });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API}
        onLoad={handleMapLoad}
        onError={(error) => console.error('Error loading Google Maps script:', error)}
      >
        {mapLoaded && (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {inventoryAggregates.length > 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 75,
                  right: 5,
                  borderRadius: '15px',
                  padding: '1rem',
                  backgroundColor: '#111217',
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'left',
                  flexDirection: 'column',
                  zIndex: 10,
                  width: showByProduct ? '400px' : '300px',
                  border: '0.5px solid white',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: '5px' }}>
                  <Typography sx={{ color: 'white', fontFamily: 'MontHeavy' }}>Total Inventory by Partner</Typography>
                  <Tooltip title="Toggle by Product" placement="right">
                    <IconButton onClick={handleAggregatesToggle} sx={{ color: 'white', ml: 1 }}>
                      <WidgetsIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider sx={{ bgcolor: 'grey.500' }} />
                <Box
                  sx={{
                    padding: '0.5rem',
                    maxHeight: '55vh',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      background: 'transparent',
                      width: 5,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'grey',
                      borderRadius: 5
                    }
                  }}
                >
                  {showByProduct
                    ? (
                      Object.entries(
                        inventoryAggregates.reduce((acc, row) => {
                          if (!acc[row.name]) {
                            acc[row.name] = [];
                          }
                          acc[row.name].push(row);
                          return acc;
                        }, {})
                      ).map(([name, products], index) => (
                        <div key={index} style={{ marginBottom: '1rem' }}>
                          <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{name}</Typography>
                          {products.map((product, subIndex) => (
                            <div key={subIndex} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Typography sx={{ color: 'white', paddingLeft: '1rem' }}>{product.product_name}</Typography>
                              <Typography sx={{ color: 'white' }}>{product.qty.toLocaleString()}</Typography>
                            </div>
                          ))}
                        </div>
                      ))
                    )
                    : (
                      inventoryAggregates.map((row, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Typography sx={{ color: 'white' }}>{row.name}</Typography>
                          <Typography sx={{ color: 'white' }}>{row.qty.toLocaleString()}</Typography>
                        </div>
                      ))
                    )
                  }
                </Box>
              </Box>
            )}
            <div
              style={{
                position: 'absolute',
                bottom: 85,
                left: 5,
                borderRadius: '15px',
                padding: '1rem',
                backgroundColor: '#111217',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                zIndex: 10,
                border: '0.5px solid white'
              }}
            >
              <img
                src={actions}
                alt="ordersIcon"
                onClick={() => setCustomersDialogOpen(true)}
                style={{
                  cursor: 'pointer',
                  width: '55px',
                  height: '55px',
                }}
              />
              <Typography sx={{ color: 'white', fontFamily: 'MontHeavy', m: 0 }}>Orders</Typography>
              <img
                src={truck}
                onClick={() => setTransitDialogOpen(true)}
                alt="intransitIcon"
                style={{
                  cursor: 'pointer',
                  width: '50px',
                  height: '50px',
                  transform: 'scaleX(-1)',
                }}
              />
              <Typography sx={{ color: 'white', fontFamily: 'MontHeavy', mb: '5px' }}>In Transit</Typography>
              {/* <img
                src={document}
                onClick={() => setAssistantDialogOpen(true)}
                alt="documentIcon"
                style={{
                  cursor: 'pointer',
                  width: '45px',
                  height: '50px',
                }}
              />
              <Typography sx={{ color: 'white', fontFamily: 'MontHeavy', m: 0 }}>Documents</Typography> */}
            </div>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
              }}
              center={mapCenter}
              zoom={5}
              options={options}
            >
              <MarkerClusterer
                options={{
                  styles: [
                    {
                      url: cluster,
                      textColor: 'black',
                      height: 50,
                      width: 50,
                      fontFamily: 'MontHeavy',
                      textSize: 18,
                    },
                  ],
                  calculator: (markers) => {
                    const count = markers.length;
                    return {
                      text: `${count}`,
                      index: count < 10 ? 0 : count < 100 ? 1 : 2,
                    };
                  },
                }}
              >
                {(clusterer) => (
                  <div>
                    {integrations.filter((integration) => integration.integration_type.type !== 'Customer').map(({ id, name, coordinates, integration_type }) => (
                      <Marker
                        key={id}
                        position={{ lat: coordinates.lat, lng: coordinates.long }}
                        clusterer={clusterer}
                        label={{
                          text: name, // `${name} ⚪`
                          color: 'white',
                          fontSize: '12px',
                          className: 'marker-label',
                          fontFamily: 'MontHeavy',
                        }}
                        icon={{
                          url: integration_type.category === 'HQ' ? hq : integration_type.category === '3PL' ? warehouse : integration_type.category === 'Supplier' ? supplier : mfg,
                          scaledSize: new window.google.maps.Size(45, 45),
                          anchor: new window.google.maps.Point(25, 25),
                          labelOrigin: new window.google.maps.Point(25, -5),
                        }}
                        onClick={() => handleMarkerClick(id, coordinates)}
                      />
                    ))}
                  </div>
                )}
              </MarkerClusterer>
              {showRoutes && alertsData.map((route, index) => (
                <React.Fragment key={index}>
                  <Polyline
                    key={`line-${index}`}
                    path={[
                      { lng: route.coordinates_from.long, lat: route.coordinates_from.lat },
                      { lng: route.coordinates_to.long, lat: route.coordinates_to.lat },
                    ]}
                    options={{
                      strokeColor: '#686869',
                      strokeOpacity: 0.45,
                      strokeWeight: 2,
                    }}
                  />
                  <Marker
                    key={`icon-${index}`}
                    position={{ lng: (route.coordinates_from.long + route.coordinates_to.long) / 2, lat: (route.coordinates_from.lat + route.coordinates_to.lat) / 2 }}
                    icon={{
                      url: route.order_category === 'urgent' ? actions_urgent : actions_alert,
                      scaledSize: new window.google.maps.Size(20, 20),
                      anchor: route.order_category === 'urgent'
                        ? new window.google.maps.Point(10, 10)
                        : new window.google.maps.Point(-10, 10),
                      labelOrigin: new window.google.maps.Point(25, -5),
                    }}
                    onClick={() => {
                      setInputItems(route.items);
                      setInputPOName(route.auto_po);
                      setInputIntegration(integrations.find((integration) => integration.id === route.integration_id));
                      setInputIntegrationFrom(integrations.find((integration) => integration.id === route.integration_id_from));
                      setOpenDialog(true);
                    }}
                  />
                </React.Fragment>
              ))}
              <Data
                key="countries_layer"
                onLoad={(dataLayer) => {
                  dataLayer.loadGeoJson(countries_outline);
                  dataLayer.setStyle({
                    strokeColor: '#757575',
                    strokeWeight: 1,
                    fillOpacity: 0
                  });
                }}
              />
            </GoogleMap>
          </div>
        )}
      </LoadScript>
      {dialogOpen && (
        <IntegrationDialogView
          logoBlob={logoBlob}
          productsRows={productsRows}
          setProductsRows={setProductsRows}
          materialsRows={materialsRows}
          setMaterialsRows={setMaterialsRows}
          open={dialogOpen}
          integration={integrations.find(integration => integration.id === selectedIntegrationId)}
          integrations={integrations}
          setIntegrations={setIntegrations}
          onClose={handleCloseDialog}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
          dataSources={dataSources}
        />
      )}
      {transitDialogOpen && (
        <IntransitDialogView
          user={user}
          open={transitDialogOpen}
          productsRows={productsRows}
          materialsRows={materialsRows}
          handleClose={() => setTransitDialogOpen(false)}
          integrations={integrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      {customersDialogOpen && (
        <CustomersDialogView
          open={customersDialogOpen}
          handleClose={() => setCustomersDialogOpen(false)}
          productsRows={productsRows}
          integrations={integrations}
          setIntegrations={setIntegrations}
          integrationTypes={integrationTypes}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      {actionsDialogOpen && (
        <ActionsDialogView
          open={actionsDialogOpen}
          productsRows={productsRows}
          materialsRows={materialsRows}
          integrations={integrations}
          handleClose={() => setActionsDialogOpen(false)}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      {assistantDialogOpen && (
        <AssistantDialogView
          open={assistantDialogOpen}
          productsRows={productsRows}
          setProductsRows={setProductsRows}
          materialsRows={materialsRows}
          setMaterialsRows={setMaterialsRows}
          integrations={integrations}
          hqIntegration={integrations.find(integration => integration.integration_type.category === 'HQ')}
          handleClose={() => setAssistantDialogOpen(false)}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Create PO</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh', maxHeight: '50vh' }}>
            <POCreationBox
              logoBlob={logoBlob}
              poNumber={inputPOName}
              integrations={integrations}
              productsRows={productsRows}
              materialsRows={materialsRows}
              setSnackbarAlert={setSnackbarAlert}
              inputIntegration={inputIntegration}
              inputIntegrationFrom={inputIntegrationFrom}
              inputIds={inputItems}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
              setRows={null}
              onClose={() => setOpenDialog(false)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MapComponent;
