import React, { useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Skeleton,
  LinearProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { createShipment } from '../api/shipments.js';
import { createDocument } from '../api/documents.js';
import { createMaterial, createProduct } from '../api/products.js';
import UploadIcon from '@mui/icons-material/Upload';
import PDFViewer from '../components/PDFViewer.js';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import LabeledDropdown from './LabeledDropdown';
import StarIcon from '@mui/icons-material/Star';
import LinkIcon from '@mui/icons-material/Link';


const AssistantDialogView = ({
  open,
  handleClose,
  hqIntegration,
  integrations,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [blobUrl, setBlobUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [b64encodedStringData, setB64encodedStringData] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const fileInputRef = useRef(null);
  const [parsedPdf, setParsedPdf] = useState("");
  const [orderType, setOrderType] = useState("product");
  const [selectedPOStatus, setSelectedPOStatus] = useState("Delivered");

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      uploadDocument(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDFs are allowed.');
    }
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    setLoadingPdf(true);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const byteCharacters = atob(b64encodedString);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        setBlobUrl(blob);
        setB64encodedStringData(b64encodedString);
        setFileName(file.name);
        const data = await createDocument(hqIntegration.id, file.name, b64encodedString, null, "False");
        console.log(data)
        setOrderType(data.body?.order_type || "product");
        setParsedPdf(data);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      } finally {
        setLoadingPdf(false);
      }
    };
  };

  const handleSaveShipment = async () => {
    try {
      const updatedSkuList = await Promise.all(
        parsedPdf.body.line_items.map(async (item) => {
          let itemId = item.product_id;

          if (itemId === 'null') {
            const createFn = orderType === 'material' ? createMaterial : createProduct;
            const createPayload = orderType === 'material' ? {
              integration_id: hqIntegration.id,
              sku: item.product_name,
              name: item.product_name,
              default_price: item.price,
              is_packaging: 'false'
            } : {
              integration_id: hqIntegration.id,
              sku: item.product_name,
              name: item.product_name,
              upc: null,
            }
            const response = await createFn(createPayload);
            itemId = response.id;
            if (orderType === 'material') {
              setMaterialsRows(prevMaterials => [...prevMaterials, response]);
            } else {
              setProductsRows(prevProducts => [...prevProducts, response]);
            }
          }

          return {
            ...item,
            integration_id: parsedPdf.body.integration_id,
            integration_id_to: parsedPdf.body.integration_id_to || null,
            total_freight: parsedPdf.body.total_freight_cost || null,
            order_name: parsedPdf.body.order_name,
            warehouse_order: parsedPdf.body.order_name,
            order_status: selectedPOStatus,
            pick_up_date: parsedPdf.body.order_date || null,
            delivery_date: parsedPdf.body.order_date || null,
            material_id: itemId,
            product_id: itemId,
            qty: parseFloat(item.qty),
            unit_price: item.price || null,
            is_existing_order: false,
            order_type: orderType,
          };
        })
      );
      console.log(updatedSkuList);
      await createShipment(updatedSkuList);
      await createDocument(parsedPdf.body.integration_id, fileName, b64encodedStringData, null, "True");
      setParsedPdf("");
      setBlobUrl(null);
      setSnackbarAlert("success");
      setSnackbarOpen(true);
      setSnackbarMessage("Created successfully.");
      handleClose();
    } catch (error) {
      console.error('Error saving shipment:', error);
      setSnackbarAlert("error");
      setSnackbarOpen(true);
      setSnackbarMessage("There was an error uploading the info, make sure all fields are filled out.");
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={(blobUrl || loadingPdf) ? 'xl' : "xs"}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Document Assistant
        {(blobUrl || loadingPdf) && (
          <>
            <Button
              color="primary"
              variant='contained'
              startIcon={<UploadIcon />}
              onClick={handleUploadClick}
            >
              Add New Document
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: (blobUrl || loadingPdf) ? '75vh' : '25vh', maxHeight: (blobUrl || loadingPdf) ? '75vh' : '25vh' }}>
          {blobUrl || loadingPdf ? (
            <Box display="flex" flexDirection="row" width="100%" height="100%">
              <Box display="flex" flexDirection="column" width="55%" height="100%" sx={{ p: '1rem' }}>
                {blobUrl && <PDFViewer pdfBlob={blobUrl} />}
              </Box>
              <Box display="flex" flexDirection="column" width="55%" height="100%" justifyContent="top" sx={{ py: '2rem' }}>
                {loadingPdf ? (
                  <>
                    <Typography variant="body1"><b>Analyzing and mapping document...</b></Typography>
                    <LinearProgress sx={{ my: '0.5rem' }} />
                    <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                    <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                    <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                  </>
                ) : (
                  <Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: '1rem' }}>
                      <Typography variant="body1" component={"div"}>
                        <b>Name: {parsedPdf.body.order_name} | Ordered: {parsedPdf.body.order_date}</b>
                      </Typography>
                      <FormControl sx={{ width: '40%', height: '35px' }}>
                        <InputLabel id="status-select-label" shrink>Order Status</InputLabel>
                        <Select
                          labelId="status-select-label"
                          label="Order Status"
                          size='small'
                          value={selectedPOStatus}
                          onChange={(event) => setSelectedPOStatus(event.target.value)}
                        >
                          <MenuItem value="In Transit">In Transit</MenuItem>
                          <MenuItem value="Delivered">Delivered</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                      <LabeledDropdown
                        label="From Partner"
                        value={parsedPdf.body.integration_id}
                        sx={{ my: '0.5rem', width: '50%', mr: '0.5rem' }}
                        options={integrations.filter(row => !['HQ', '3PL'].includes(row.integration_type.category)).map(row => ({ value: row.id, label: row.name }))}
                        onChange={(event) => {
                          integrations.find((r) => r.id === event.target.value)?.integration_type.category === 'Manufacturer' ? setOrderType("product") : setOrderType("material");
                          setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, integration_id: event.target.value } }))
                        }}
                      />
                      <LabeledDropdown
                        label="To Partner"
                        disabled={parsedPdf.body.integration_id && parsedPdf.body.integration_id === 'null'}
                        value={parsedPdf.body.integration_id_to}
                        sx={{ my: '0.5rem', width: '50%', mr: '0.5rem' }}
                        options={integrations.filter((row) =>
                          integrations.find((r) => r.id === parsedPdf.body.integration_id)?.integration_type.category === 'Manufacturer' ? row.integration_type.category === '3PL' : row.integration_type.category === 'Manufacturer'
                        ).map((row) => ({ value: row.id, label: row.name }))}
                        onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, integration_id_to: event.target.value } }))}
                      />
                    </Box>
                    <Typography variant="body1" component={"div"}><b>Total Cost: ${parsedPdf.body.total_cost} | Freight Cost: ${parsedPdf.body.total_freight_cost || '0'}</b></Typography>
                    <Divider sx={{ my: '1rem', width: '100%', bgcolor: 'grey.700' }} />
                    <Box mt={2} sx={{ height: 'auto', maxHeight: '60%', overflow: 'auto' }}>
                      <Typography variant="body3" component={"div"} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5rem' }}>
                        <StarIcon color='warning' /> : <b>&nbsp;Indicates a new product/material</b>
                      </Typography>
                      <Typography variant="body3" component={"div"} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '0.5rem' }}>
                        <LinkIcon color='info' /> : <b>&nbsp;Existing product/material</b>
                      </Typography>
                      {parsedPdf.body.line_items.map((item, index) => (
                        <Typography key={index} variant="body2" component={"div"} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {(item.product_id && item.product_id !== 'null') ? (
                            <>
                              <LinkIcon color='info' sx={{ mr: '0.5rem' }} />
                              <LabeledDropdown
                                label="Item"
                                value={item.product_id}
                                sx={{ my: '0.5rem', width: '30%', mr: '0.5rem' }}
                                options={orderType === 'material' ? materialsRows.map(row => ({ value: row.id, label: row.name })) : productsRows.map(row => ({ value: row.id, label: row.name }))}
                                onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, product_id: event.target.value } : lineItem) } }))}
                              />
                            </>
                          ) : (
                            <>
                              <StarIcon color='warning' sx={{ mr: '0.5rem' }} />
                              <TextField
                                label="Item"
                                value={item.product_name || ""}
                                autoComplete='off'
                                size='small'
                                sx={{ width: '30%', my: '5px', mr: '5px' }}
                                onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, product_name: event.target.value } : lineItem) } }))}
                              />
                              {orderType === 'material' && (
                                <TextField
                                  label="UoM"
                                  value={item.unit_of_measure || ""}
                                  autoComplete='off'
                                  size='small'
                                  sx={{ width: '10%', my: '5px', mr: '5px' }}
                                  onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, unit_of_measure: event.target.value } : lineItem) } }))}
                                />
                              )}
                            </>
                          )}
                          <TextField
                            label={"Qty"}
                            value={item.qty || 0}
                            type='number'
                            size='small'
                            sx={{ width: '12%', m: '5px' }}
                            onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, qty: event.target.value } : lineItem) } }))}
                          />
                          <TextField
                            label='Price ($)'
                            value={item.price || 0}
                            type='number'
                            size='small'
                            sx={{ width: '12%', m: '5px' }}
                            onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, price: event.target.value } : lineItem) } }))}
                          />
                          <TextField
                            label='Lot'
                            value={item.lot_number || ""}
                            autoComplete='off'
                            size='small'
                            sx={{ width: '13%', m: '5px' }}
                            onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, lot_number: event.target.value } : lineItem) } }))}
                          />
                          <TextField
                            label='Exp. Date'
                            value={item.expiration_date || ""}
                            size='small'
                            autoComplete='off'
                            sx={{ width: '14%', ml: '5px', my: '5px' }}
                            onChange={(event) => setParsedPdf(prevState => ({ ...prevState, body: { ...prevState.body, line_items: prevState.body.line_items.map((lineItem, lineItemIndex) => lineItemIndex === index ? { ...lineItem, expiration_date: event.target.value } : lineItem) } }))}
                          />
                        </Typography>
                      ))}
                    </Box>
                    <Button
                      color="primary"
                      variant='contained'
                      startIcon={<SaveOutlinedIcon />}
                      onClick={handleSaveShipment}
                      sx={{ mt: '0.5rem', width: '35%' }}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Button
                color="primary"
                variant='contained'
                startIcon={<UploadIcon />}
                onClick={handleUploadClick}
              >
                Add Document
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog >
  );
};

export default AssistantDialogView;
