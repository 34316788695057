import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  Skeleton,
  FormControl,
  InputLabel,
  TextField,
  Divider
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
// import VerifiedIcon from '@mui/icons-material/Verified';
// import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import {
  fetchDocuments,
  fetchDocumentContent,
  refreshDocuments,
  createDocument,
  updateDocument,
  deleteDocument,
  analyzeDocument
} from '../../api/documents.js';
import { fetchInventory } from '../../api/products.js';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PDFViewer from '../../components/PDFViewer.js';
// import SendIcon from '@mui/icons-material/Send';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


const BackButton = ({ setBlobUrl, setParsedDocument, buttonText = "Back to Table", ...props }) => {
  return (
    <Button
      color="inherit"
      variant="outlined"
      sx={{ width: '35%', height: '2.5rem', mt: '1rem' }}
      startIcon={<ArrowBackIcon />}
      onClick={() => {
        if (setBlobUrl) setBlobUrl(null);
      }}
      {...props}
    >
      {buttonText}
    </Button>
  );
};

const docTypes = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Sales Order', label: 'Sales Order' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Receiving Instructions', label: 'Receiving Instructions' },
  { value: 'Receiving Order', label: 'Receiving Order' },
  { value: 'Certifications', label: 'Certifications' },
  { value: 'Bill of Lading', label: 'Bill of Lading' },
  { value: 'Upload', label: 'Upload' },
];

export default function DocumentDatagrid({
  rows,
  setRows,
  materialsRows,
  productsRows,
  setMaterialsRows,
  setProductsRows,
  integration,
  integrations,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  isDialogView = true,
  height = '90vh',
  loading = null,
  setSelectedIntegrationId = null,
}) {
  const [selectedDocumentType, setSelectedDocumentType] = useState("All Documents");
  const [loadingRows, setLoadingRows] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [blobType, setBlobType] = useState(null);
  const columnVisibilityModel = { id: false, integration_name: isDialogView ? false : true };
  const [parsedPdf, setParsedPdf] = useState("");
  const [analyzeOrder, setAnalyzeOrder] = useState(false);
  const [sendTo, setSendTo] = useState("");
  const [productInventory, setProductInventory] = useState([]);
  const fileInputRef = useRef(null);


  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'application/zip')
    ) {
      uploadDocument(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDF, PNG, and JPEG are allowed.');
    }
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const response = await createDocument(integration.id, file.name, b64encodedString);
        setRows([...rows, response]);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading document. Check if this document already exists.');
      }
    };
  };

  const handleFetchDocument = async (row) => {
    setBlobUrl(null);
    try {
      let data;
      if (row.msg_id === 'upload') {
        data = await fetchDocumentContent(row.msg_id + '/' + row.integration_id, row.document_name);
      } else {
        data = await fetchDocumentContent(row.msg_id, row.document_name);
      }

      const pdfBase64 = data;
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let mimeType = 'application/octet-stream'; // Default
      if (row.document_name.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (row.document_name.endsWith('.png')) {
        mimeType = 'image/png';
      } else if (row.document_name.endsWith('.jpg') || row.document_name.endsWith('.jpeg')) {
        mimeType = 'image/jpeg';
      } else if (row.document_name.endsWith('.zip')) {
        mimeType = 'application/zip';
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });

      if (mimeType === 'application/zip') {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', row.document_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        setBlobType(mimeType);
        if (mimeType !== 'application/pdf') {
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
        } else {
          setBlobUrl(blob);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleRefreshDocuments = async () => {
    setLoadingRows(true);
    try {
      await refreshDocuments(integration.id);
      setSnackbarOpen(true);
      setSnackbarMessage("Refresh triggered. Check back in a minute.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
    setLoadingRows(false);
  };



  const handleDeleteDocument = async (id) => {
    try {
      await deleteDocument(id);
      setRows(rows.filter((r) => r.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  }

  const handleDocumentChange = async (event, row, changedVal) => {
    try {
      const updatedValue = event.target.value;
      let updatedRow = { ...row };
      if (changedVal === 'category') {
        updatedRow = { ...updatedRow, category: updatedValue };
      }
      if (changedVal === 'order_name') {
        updatedRow = { ...updatedRow, order_name: updatedValue };
      }
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_id: updatedValue };
      }
      if (changedVal === 'is_published') {
        updatedRow = { ...updatedRow, is_published: updatedValue };
      }
      const data = await updateDocument(updatedRow);
      updatedRow.id = data.id;
      if (changedVal === 'integration_id') {
        updatedRow = { ...updatedRow, integration_name: integrations.find((integration) => integration.id === updatedValue)?.name };
      }
      setRows(rows.map((r) => (r.id === row.id ? updatedRow : r)));
      setSnackbarOpen(true);
      setSnackbarMessage("Updated.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handleCategoryFilter = async (event) => {
    setSelectedDocumentType(event);
    let data;
    if (isDialogView) {
      data = await fetchDocuments(integration.id, event === 'All Documents' ? null : event);
    } else {
      data = await fetchDocuments(null, event === 'All Documents' ? null : event);
    }
    setRows(data);
  }

  const handleAnalyzeDocument = async (document_id) => {
    setAnalyzeOrder(true)
    try {
      const data = await analyzeDocument(document_id);
      setParsedPdf(data.body)
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleFetchInventory = async (integration_id) => {
    try {
      setSendTo(integration_id);
      const data = await fetchInventory(integration_id);
      console.log(data)
      setProductInventory(data);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleSelectMapped = async (event, index) => {
    const selectedProduct = productInventory.find((product) => product.id === event.target.value);
    if (selectedProduct) {
      setParsedPdf((prevParsedPdf) => ({
        ...prevParsedPdf,
        line_items: prevParsedPdf.line_items.map((item, i) =>
          i === index ? { ...item, mapped_id: selectedProduct.id, qty_available: selectedProduct.qty } : item
        ),
      }));
    }
  };

  const handleConversionChange = async (event, index) => {
    const conversionVal = event.target.value;
    if (conversionVal) {
      setParsedPdf((prevParsedPdf) => ({
        ...prevParsedPdf,
        line_items: prevParsedPdf.line_items.map((item, i) =>
          i === index ? { ...item, total_qty: (conversionVal * item.qty), is_fulfillable: (conversionVal * item.qty) < item?.qty_available } : item
        ),
      }));
    }
  };


  const columns = [
    { field: 'source', headerName: 'Data Source', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'integration_name',
      headerName: 'Partner',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.row.integration_id === integrations.find(integration => integration.integration_type.category === "HQ")?.id) {
          return (
            <Select
              value={params.row.integration_id}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "integration_id")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map(integration => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.integration_name;
      },
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 0.75,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.row.source !== 'Email') {
          return (
            <Select
              value={params.row.category}
              size='small'
              onChange={(e) => handleDocumentChange(e, params.row, "category")}
              variant='standard'
              fullWidth
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {docTypes.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          );
        }
        return params.row.category;
      },
    },
    {
      field: 'document_name',
      headerName: 'Document',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const documentName = params.row.document_name;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
            <span>{documentName}</span>
            <Tooltip title="View Document" id={`${documentName}-view`}>
              <IconButton onClick={() => handleFetchDocument(params.row)}>
                <SearchOutlinedIcon sx={{ color: 'black' }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    { field: 'edited_by', headerName: 'Synced By', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'msg_timestamp', headerName: 'Email Date', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'last_updated', headerName: 'Updated', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const icons = [];
        // if (params.row.category === 'Certifications') {
        //   icons.push(
        //     <Tooltip key={`certs-tooltip-${params.row.id}`} title={params.row.is_published ? 'Unpublish Certification' : "Publish Certification"} id={`${params.row.document_name}-view`}>
        //       <IconButton
        //         key={`certs-icon-${params.row.id}`}
        //         onClick={() => handleDocumentChange({ target: { value: !params.row.is_published } }, params.row, "is_published")}
        //       >
        //         {params.row.is_published ? <VerifiedIcon sx={{ color: 'black' }} /> : <VerifiedOutlinedIcon sx={{ color: 'black' }} />}
        //       </IconButton>
        //     </Tooltip>
        //   );
        // }
        if (params.row.source === 'Email') {
          icons.push(
            <IconButton
              key={`email-icon-${params.row.id}`}
              component="a"
              href={`https://mail.google.com/mail/u/0/#inbox/${params.row.msg_id}`}
              target="_blank"
            >
              <EmailOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        } if (params.row.category === 'Sales Order') {
          icons.push(
            <IconButton
              key={`genai-icon-${params.row.id}`}
              onClick={() => {
                handleFetchDocument(params.row);
                handleAnalyzeDocument(params.row.id);
              }}
            >
              <AutoFixHighIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        } if (params.row.source === 'Upload') {
          icons.push(
            <IconButton
              key={`delete-icon-${params.row.id}`}
              onClick={() => handleDeleteDocument(params.row.id)}
            >
              <DeleteOutlineOutlinedIcon sx={{ color: 'black' }} />
            </IconButton>
          );
        }
        return <>{icons}</>;
      },
      headerClassName: 'super-app-theme--header'
    }
  ];


  const GridToolbar = () => {
    return (
      <>
        {!loadingRows ? (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <GridToolbarContainer>
              {setSelectedIntegrationId && (
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => {
                    setRows([])
                    setSelectedIntegrationId(null)
                  }}
                >
                  Back
                </Button>
              )}
              <>
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleUploadClick}
                >
                  Add Document
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </>
            </GridToolbarContainer>
            {isDialogView && !setSelectedIntegrationId && (
              <GridToolbarContainer>
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<SyncIcon />}
                  onClick={handleRefreshDocuments}
                >
                  Sync Email
                </Button>
              </GridToolbarContainer>
            )}

            {!setSelectedIntegrationId && (
              <GridToolbarContainer>
                <Select
                  value={selectedDocumentType}
                  size="small"
                  variant="standard"
                  sx={{ ml: '1rem', width: '250px' }}
                  onChange={(e) => handleCategoryFilter(e.target.value)}
                >
                  <MenuItem key="all" value="All Documents">
                    All Documents
                  </MenuItem>
                  {docTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </GridToolbarContainer>
            )}
          </Box>
        ) : (
          <CircularProgress size={24} sx={{ ml: '0.5rem', mt: '0.5rem' }} />
        )}
      </>
    );
  };


  const ViewerToolbar = () => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <GridToolbarContainer>
            <BackButton
              setBlobUrl={setBlobUrl}
              sx={{}}
            />
          </GridToolbarContainer>
        </Box>
      </>
    );
  };


  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: { height }, bgcolor: 'white', borderRadius: '8px', width: '100%' }}>
        {!blobUrl ? (
          <Box sx={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density='compact'
              columnVisibilityModel={columnVisibilityModel}
              hideFooter
              loading={loading}
              slots={{
                toolbar: GridToolbar,
              }}
              sx={{
                backgroundColor: 'white',
                '& .MuiDataGrid-row': {
                  color: 'black',
                },
              }}
            />
          </Box>
        ) : analyzeOrder ? (
          <>
            <Box display="flex" flexDirection="column" width="50%" height="100%">
              <PDFViewer pdfBlob={blobUrl} />
            </Box>
            {parsedPdf ? (
              <Box display="flex" flexDirection="column" width="50%" height="100%" padding={2}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
                  <Button
                    color="inherit"
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      setAnalyzeOrder(false);
                      setSendTo("");
                      setParsedPdf("");
                      setBlobUrl("");
                    }}
                  >
                    Back
                  </Button>
                  {/* <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SendIcon />}
                  >
                    Send
                  </Button> */}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  sx={{ mb: '0.5rem' }}
                >
                  <Typography variant="h6">
                    Order: {parsedPdf.order_name}
                  </Typography>

                  <FormControl size="small" sx={{ width: '60%' }}>
                    <InputLabel id="send-to-select-label">Fulfill From</InputLabel>
                    <Select
                      labelId="send-to-select-label"
                      label="Fulfill From"
                      id="send-to-select"
                      value={sendTo}
                      onChange={(event) => handleFetchInventory(event.target.value)}
                    >
                      {integrations
                        .filter((integration) => integration.integration_type.type === '3PL')
                        .map((integration) => (
                          <MenuItem key={integration.id} value={integration.id}>
                            {integration.name} ({integration.contact_email})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography>
                  Order Date: <b>{parsedPdf.order_date}</b> | Requested Delivery: <b>{parsedPdf.requested_delivery_date}</b>
                </Typography>
                <Typography>
                  Ship To: <b>{parsedPdf.ship_to_address}</b>
                </Typography>
                <Divider fullWidth sx={{ mt: '0.5rem', borderColor: 'black' }} />
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    height: '100%',
                    maxHeight: '45vh',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      backgroundColor: 'transparent',
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'grey',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {parsedPdf.line_items.map((item, index) => (
                    <Box key={index} display="flex" flexDirection="column" marginTop={2}>
                      <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Typography variant="body2" style={{ marginRight: '8px' }}>
                          {index + 1}.
                        </Typography>
                        <Typography variant="body2" style={{ marginRight: '16px' }}>
                          <b>{item.product_name}</b>
                        </Typography>
                        <Typography variant="body2" style={{ marginRight: '16px' }}>
                          Qty: {item.qty} {item.unit_of_measure}
                        </Typography>
                        <Typography variant="body2">
                          Price: ${item.price.toFixed(2)}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection={'row'} alignItems="center" marginLeft={4}>
                        <SubdirectoryArrowRightIcon style={{ marginRight: '8px' }} />
                        <FormControl>
                          <Select
                            labelId="send-to-select-label"
                            id="send-to-select"
                            sx={{
                              mt: '0.5rem',
                              mr: '0.5rem',
                              width: '200px',
                              height: '30px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                            }}
                            onChange={(e) => handleSelectMapped(e, index)}
                          >
                            {productInventory.map((product) => {
                              const productDetails = productsRows.find((p) => p.id === product.product_id);
                              return (
                                <MenuItem key={product.id} value={product.id}>
                                  {productDetails ? `${productDetails.name} [${productDetails.sku}]` : "Product not found"}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <TextField
                          variant="outlined"
                          size="small"
                          type='number'
                          placeholder="Conversion"
                          autoComplete='off'
                          onChange={(e) => handleConversionChange(e, index)}
                          sx={{
                            mt: '0.5rem',
                            width: '125px',
                            height: '30px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            ml: '8px',
                            mt: '0.7rem',
                            color: !item.qty_available && item.qty_available !== 0 ? 'inherit' : item.qty_available < item.total_qty ? 'error.main' : 'success.main',
                          }}
                        >
                          X {item.qty} {item.unit_of_measure} = <b>{item.total_qty}</b> needed (<b>{item.qty_available}</b> on hand)
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <>
                <Box display="flex" flexDirection="column" width="50%" height="100%" padding={2}>
                  <Typography variant="body2">Analyzing document...</Typography>
                  <LinearProgress sx={{ my: '0.5rem' }} />
                  <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                  <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                  <Skeleton variant="rounded" height="15%" sx={{ mb: '1rem' }} />
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Box sx={{ width: '50%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                density='compact'
                columnVisibilityModel={{
                  ...columnVisibilityModel,
                  source: false,
                  edited_by: false,
                  last_updated: false,
                }}
                hideFooter
                slots={{
                  toolbar: ViewerToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
            {blobType === 'application/pdf' ? (
              <Box display="flex" flexDirection="column" width="50%" height="100%">
                <PDFViewer pdfBlob={blobUrl} />
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" width="50%" height="100%">
                <img src={blobUrl} alt="docImg" />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}
