import React, { useState, useEffect, useCallback } from 'react';
import {
  Tooltip,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateSupplierDialog from '../CreateSupplierDialog';
import CreateInventoryDialog from '../CreateInventoryDialog';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import RecipeBuilder from './RecipeBuilder';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { fetchNotifications } from '../../api/notifications';
import { updateMaterialsInventory, updateProductsInventory } from '../../api/products';


export default function NotificationsTreeView({
  rows,
  setRows,
  integrations,
  materialsRows,
  productsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert
}) {
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [recipeDialogOpen, setRecipeDialogOpen] = React.useState(false);
  const [leadTimeDialogOpen, setLeadTimeDialogOpen] = React.useState(false);
  const [leadTime, setLeadTime] = React.useState("");
  const [leadTimeType, setLeadTimeType] = React.useState("");
  const [recipeRows, setRecipeRows] = React.useState([]);

  const fetchNotificationsData = useCallback(async () => {
    try {
      const notifications_data = await fetchNotifications();
      setRows(notifications_data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [setRows]);

  useEffect(() => {
    if (!openDialog && !openSupplierDialog && !recipeDialogOpen && !leadTimeDialogOpen) {
      fetchNotificationsData();
    }
  }, [openDialog, openSupplierDialog, recipeDialogOpen, leadTimeDialogOpen, fetchNotificationsData]);

  const handleResolveClick = async (row) => {
    if (row.alert_category === "Missing Supplier") {
      setOpenSupplierDialog(true);
      setSelectedItemId(row.id);
    } else if (row.alert_category === "Missing Manufacturer") {
      setOpenDialog(true);
      setSelectedItemId(row.id);
    }
    else if (row.alert_category === "Missing Recipe") {
      setRecipeDialogOpen(true);
      setSelectedItemId(row.item_id);
      setSelectedProductName(productsRows.find(product => product.id === row.item_id).name);
    } else if (row.alert_category === "Missing Lead Time") {
      setLeadTimeDialogOpen(true);
      setLeadTimeType(productsRows.some(product => product.id === row.item_id) ? 'product' : 'material');
      setSelectedItemId(row.id);
    }
  };

  const handleSaveLeadTime = async () => {
    if (leadTimeType === 'product') {
      await updateProductsInventory(selectedItemId, { id: selectedItemId, lead_time: leadTime });
      setSnackbarAlert("success");
      setSnackbarMessage("Updated successfully");
      setSnackbarOpen(true);
    } else if (leadTimeType === 'material') {
      await updateMaterialsInventory(selectedItemId, { id: selectedItemId, lead_time: leadTime });
      setSnackbarAlert("success");
      setSnackbarMessage("Updated successfully");
      setSnackbarOpen(true);
    }
  }

  const predefinedCategories = [
    "Missing Manufacturer",
    "Missing Supplier",
    "Missing Recipe",
    "Missing Lead Time"
  ];

  const groupedRows = predefinedCategories.reduce((acc, category) => {
    acc[category] = rows.filter(row => row.alert_category === category);
    return acc;
  }, {});

  const renderActionButton = (row) => {
    return (
      <>
        {['Missing Supplier', 'Missing Manufacturer'].includes(row.alert_category) ? (
          <Tooltip title="Add">
            <IconButton onClick={() => handleResolveClick(row)}>
              <AddIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Send Drafted PO">
            <IconButton onClick={() => handleResolveClick(row)}>
              <AddIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ height: '100%', width: '100%' }}>
        <SimpleTreeView
          sx={{
            width: '100%',
            '& .MuiTreeItem-label': {
              color: 'white',
            },
            '& .MuiTreeItem-content.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '& .MuiTreeItem-iconContainer': {
              color: 'white',
            },
          }}
        >
          {predefinedCategories.map((category) => {
            const rowsInCategory = groupedRows[category] || [];
            return (
              <TreeItem
                itemId={category}
                label={<Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>{`${category} (${rowsInCategory.length})`}</Typography>}
                key={category}
                sx={{
                  border: '1px solid grey',
                  borderRadius: '10px',
                  bgcolor: rowsInCategory.length > 0 ? 'grey.700' : 'grey.800',
                  my: '1rem',
                  maxHeight: '30vh',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    backgroundColor: 'transparent',
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'grey.600',
                    borderRadius: '15px',
                  },
                  '& .MuiTreeItem-content': {
                    padding: '0.5rem',
                  },
                }}
              >
                {rowsInCategory.length > 0 ? (
                  rowsInCategory.map((row) => (
                    <TreeItem
                      sx={{
                        bgcolor: 'grey.700',
                        mb: '0.5rem',
                        mr: '1rem',
                        border: '1px solid darkgrey',
                        borderRadius: '15px',
                      }}
                      itemId={row.id.toString()}
                      key={row.id}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography variant='body2' sx={{ color: 'white' }} onClick={() => handleResolveClick(row)}>{row.alert_detail}</Typography>
                          {renderActionButton(row)}
                        </Box>
                      }
                    />
                  ))
                ) : (
                  <Typography variant="body2" sx={{ color: 'white', ml: '1rem' }}>
                    No alerts in this category.
                  </Typography>
                )}
              </TreeItem>
            );
          })}
        </SimpleTreeView>
      </Box>
      <CreateInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        rows={rows}
        setRows={null}
        itemOptions={productsRows.filter(row => row.id === selectedItemId)}
        integrations={integrations.filter(row => row.integration_type.category === "Manufacturer")}
        type={'product'}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        inputSelectedItem={productsRows.find(row => row.id === selectedItemId)?.id}
      />
      <CreateSupplierDialog
        open={openSupplierDialog}
        onClose={() => setOpenSupplierDialog(false)}
        itemOptions={materialsRows.filter(row => row.id === selectedItemId)}
        integrations={integrations.filter(row => row.integration_type.category === "Supplier")}
        setSnackbarAlert={setSnackbarAlert}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        inputSelectedItem={materialsRows.find(row => row.id === selectedItemId)?.id}
      />
      <Dialog
        open={recipeDialogOpen}
        onClose={() => setRecipeDialogOpen(false)}
        fullWidth
        maxWidth="xl"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '55vh', maxHeight: '55vh' }}>
          <RecipeBuilder
            recipeRows={recipeRows}
            setRecipeRows={setRecipeRows}
            selectedProductId={selectedItemId}
            setSelectedProductId={setSelectedItemId}
            selectedProductName={selectedProductName}
            productsRows={productsRows}
            materialsRows={materialsRows}
            setSnackbarAlert={setSnackbarAlert}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setDialogOpen={setRecipeDialogOpen}
          />
        </Box>
      </Dialog>
      <Dialog
        open={leadTimeDialogOpen}
        onClose={() => setLeadTimeDialogOpen(false)}
      >
        <DialogTitle>Adding Lead Time</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 'auto', p: '1rem' }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Lead Time (days)"
              value={leadTime}
              type='number'
              onChange={(e) => setLeadTime(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLeadTimeDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => {
            handleSaveLeadTime();
            setLeadTime('');
            setLeadTimeDialogOpen(false)
          }}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
