import * as React from 'react';
import { LineChart, axisClasses } from '@mui/x-charts';
import { format } from 'date-fns';
import { cheerfulFiestaPaletteDark } from '@mui/x-charts/colorPalettes';

export default function InventoryTrend({ plotData, isStacked }) {
  const sortedLabels = Object.keys(plotData.groups).sort((a, b) => a.localeCompare(b));
  const sortedSeries = sortedLabels.map((label, index) => ({
    data: [...plotData.groups[label]].reverse(),
    curve: isStacked ? 'linear' : 'monotoneX',
    label: label,
    showMark: true,
    area: isStacked ? true : false,
    connectNulls: true,
    ...(isStacked ? { stack: true } : {}),
  }));

  const sortedDates = plotData.date
    .map(date => new Date(date))
    .sort((a, b) => a - b);

  return (
    <LineChart
      colors={cheerfulFiestaPaletteDark}
      xAxis={[
        {
          data: sortedDates,
          scaleType: 'time',
          valueFormatter: value => format(value, 'MM/dd/yy'),
        }
      ]}
      series={sortedSeries}
      sx={{
        [`.${axisClasses.root}`]: {
          [`.${axisClasses.tick}, .${axisClasses.line}`]: {
            stroke: '#ffffff',
            strokeWidth: 3,
          },
          [`.${axisClasses.tickLabel}`]: {
            fill: '#ffffff',
          },
        },
      }}
      slotProps={{
        legend: {
          labelStyle: {
            fill: 'white',
          },
          hidden: sortedSeries.length > 5,
        },
      }}
      margin={{
        right: 30,
      }}
    />
  );
}

