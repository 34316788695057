import React, { useRef } from 'react';
import { Box, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import AddIcon from '@mui/icons-material/Add';
import {
  createDocument,
} from '../../api/documents.js';

export default function SharedDocuments({
  rows,
  setRows,
  selectedPartner,
  selectedOrgId,
  loading,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg')) {
      uploadDocument(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PDF, PNG, and JPEG are allowed.');
    }
  };

  const uploadDocument = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        const response = await createDocument(selectedPartner, file.name, b64encodedString, null, "True", selectedOrgId);
        setRows([...rows, response]);
      } catch (error) {
        console.error('Error uploading document:', error);
      }
    };
  };

  const columns = [
    { field: 'source', headerName: 'Data Source', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'document_name',
      headerName: 'Document',
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    { field: 'edited_by', headerName: 'Synced By', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'msg_timestamp', headerName: 'Email Date', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'last_updated', headerName: 'Updated', width: 140,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
  ];

  const GridToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleUploadClick}
        >
          Add Document
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        bgcolor: 'white',
        '& .super-app-theme-editable--cell': {
          backgroundColor: 'grey.100',
          fontWeight: '600',
        },
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          density='compact'
          disableRowSelectionOnClick
          hideFooter
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </>
  );
}
