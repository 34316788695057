import { axiosInstance, checkToken } from './utils.js'


const fetchOrdersV2 = async (searchTerm = null) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append('search_term', searchTerm);
  const queryString = params.toString();
  const url = `/v2/orders/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchDocumentsV2 = async (searchTerm = null, is_unlinked = null) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append('search_term', searchTerm);
  if (is_unlinked) params.append('is_unlinked', is_unlinked);
  const queryString = params.toString();
  const url = `/v2/documents/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const unlinkDocumentV2 = async (document_id) => {
  const url = `/v2/unlink/${document_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createOrderV2 = async (order) => {
  const url = '/v2/orders/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(url, order);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateOrderV2 = async (order_id, order) => {
  const url = `/v2/orders/${order_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, order);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateOrderDocumentsV2 = async (order_id, document_id) => {
  const url = `/v2/order_documents/${order_id}/${document_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const deleteOrderV2 = async (id) => {
  const url = '/v2/orders/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`${url}${id}/`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export {
  fetchOrdersV2,
  fetchDocumentsV2,
  createOrderV2,
  unlinkDocumentV2,
  updateOrderV2,
  updateOrderDocumentsV2,
  deleteOrderV2
};
