import React, { useState } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { updateOrder, createOrders, deleteOrder, fetchOrderLogs } from '../../api/orders';
import { fetchMaterialsInventory, fetchInventory } from '../../api/products';
import { fetchProductLimits, fetchProductLimitDetails } from '../../api/forecast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrdersProgressStepper from '../OrdersProgressStepper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomTextField from '../CustomTextField'
import POSender from '../POSender'
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

const OrdersDialogDatagrid = ({
  logoBlob,
  orderType,
  rows,
  setRows,
  materialsRows,
  productsRows,
  integration,
  integrations,
  tableView,
  setTableView,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null
}) => {
  const [rowLogs, setRowLogs] = useState([]);
  const [productLimits, setProductLimits] = useState([]);
  const [productLimitDetails, setProductLimitDetails] = useState([]);
  const [poNumber, setPONumber] = useState('');
  const [selectedPOsArr, setSelectedPOsArr] = useState([]);
  const [inputIntegration, setInputIntegration] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [validRowOptions, setValidRowOptions] = useState([]);


  const handleFetchLogs = async (order_id) => {
    try {
      const data = await fetchOrderLogs(order_id, orderType);
      setRowLogs(data);
      setTableView('logs');
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };


  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateOrder(newRow.id, newRow, orderType);
      updatedRow.last_updated = data.last_updated;
      updatedRow.status = data.status;
      setSnackbarOpen(true);
      setSnackbarMessage("Updated successfully");
      setSnackbarAlert("success");
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error('Error updating row:', error);
      return null;
    }
  };


  const handleAddItem = (materialOverride = false) => {
    if (orderType === 'material' || materialOverride === true) {
      setSkuList(
        [...skuList,
        {
          material_id: materialsRows[0].id,
          qty: integration.minimum_order_qty,
          unit_of_measure: materialsRows[0].purchasing_uom,
          unit_price: materialsRows[0].default_price || '',
          tax_rate: 0,
          item_type: 'material'
        }
        ]);
    } else {
      setSkuList(
        [...skuList,
        {
          product_id: productsRows[0].id,
          qty: integration.minimum_order_qty,
          unit_of_measure: integration.minimum_order_units || 'units',
          unit_price: '',
          tax_rate: 0,
          item_type: 'product'
        }
        ]);
    }
  }

  const handleRemoveItem = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const handleValidate = () => {
    if (poNumber.trim() === '') {
      setSnackbarAlert("error")
      setSnackbarMessage("Please name the PO")
      setSnackbarOpen(true)
      return false;
    }

    if (skuList.length === 0) {
      setSnackbarAlert("error")
      setSnackbarMessage("Please add items to the PO")
      setSnackbarOpen(true)
      return false;
    }

    for (const item of skuList) {
      if (!item[`${item.item_type}_id`] || item.qty === '' || item.unit_of_measure === '' || item.unit_price === '') {
        setSnackbarAlert("error")
        setSnackbarMessage("Please complete all items")
        setSnackbarOpen(true)
        return false;
      }
      else if (item.qty < integration.minimum_order_qty && item.unit_of_measure === integration.minimum_order_units) {
        setSnackbarAlert("error")
        setSnackbarMessage("Minimum quantity violated")
        setSnackbarOpen(true)
        return false;
      }
    }

    return true;
  };

  const handleSkuInputChange = (index, field, value) => {
    const newSkuList = [...skuList];
    newSkuList[index][field] = field === 'qty' || field === 'unit_price' ? parseFloat(value) : value;
    if (field === `${orderType}_id` && orderType === "material") {
      newSkuList[index]['unit_of_measure'] = materialsRows.filter(row => row.id === value)[0].purchasing_uom;
      newSkuList[index]['unit_price'] = materialsRows.filter(row => row.id === value)[0].default_price;
    }
    setSkuList(newSkuList);
  };

  const handleCreateOrders = async () => {
    if (handleValidate()) {
      const updatedSkuList = skuList.map(item => ({
        ...item,
        integration_id: integration.id,
        integration_id_to: null,
        order_name: poNumber,
        status: "Draft",
        tax_rate: item.tax_rate / 100
      }));
      try {
        if (!integration.is_supplier) {
          const data = await createOrders(updatedSkuList, orderType);
          setRows(prevRows => [...prevRows, ...data]);
        } else {
          const products_data = await createOrders(updatedSkuList.filter(item => item.item_type === 'product'), 'product');
          const materials_data = await createOrders(updatedSkuList.filter(item => item.item_type === 'material'), 'material');
          setRows(prevRows => [...prevRows, ...products_data, ...materials_data]);
        }
        setSnackbarOpen(true);
        setSnackbarMessage("Order created successfully.");
        setSnackbarAlert("success");
        setSkuList([]);
        setPONumber('');
        setTableView('table');
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
  };


  const handleShowEmail = async (order) => {
    setTableView('sending_po');
    const ordersArr = rows.filter(row => row.order_name === order.order_name);
    setSelectedPOsArr(ordersArr);
    setInputIntegration(integrations.find(integration => integration.id === order.integration_id_to));
  };

  const handleDeleteOrder = async (id) => {
    try {
      if (!integration.is_supplier) {
        await deleteOrder(id, orderType);
      } else {
        const currentOrder = rows.find(order => order.id === id);
        if (currentOrder && currentOrder.material_id) {
          await deleteOrder(id, 'material');
        } else {
          await deleteOrder(id, orderType);
        }
      }
      setRows((prevOrders) => prevOrders.filter(order => order.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Order deleted.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const columns = [
    {
      field: 'To', headerName: 'To', flex: 1, renderCell: (params) => {
        return (
          params.row.status === 'Draft' || params.row.status === 'PO Sent' ? (
            <Select
              size="small"
              variant="standard"
              value={params.row.integration_id_to}
              fullWidth
              sx={{
                fontSize: 'inherit',
              }}
              onChange={(event) => {
                const newValue = event.target.value;
                const updatedRow = { ...params.row, integration_id_to: newValue };
                processRowUpdate(updatedRow);
              }}
            >
              {integrations
                .filter((partner) => {
                  if (orderType === 'product') {
                    return partner.integration_type.category === '3PL';
                  } else {
                    return partner.integration_type.category === 'Manufacturer';
                  }
                })
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            integrations.find(integration => integration.id === params.row.integration_id_to)?.name
          )
        );
      }
    },
    {
      field: 'name', headerName: 'Item', flex: 1.5, renderCell: (params) => {
        return (
          <span>{params.value} [{params.row.sku}]</span>
        );
      }
    },
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'super-app-theme-editable--cell',
      width: 225,
      renderCell: (params) => {
        if (params.value === 'Delivered') {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="inherit"><b>Delivered</b></Typography>
              <CheckCircleIcon color="success" sx={{ ml: 0.5 }} />
            </Box>
          );
        }
        return <OrdersProgressStepper row={params.row} orderType={orderType} rows={rows} setRows={setRows} />;
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0
    },
    { field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'unit_price',
      headerName: 'Unit ($)',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value == null) {
          return '';
        }
        return `$${Number(params.value).toFixed(2)}`;
      }
    },
    {
      field: 'est_arrival', headerName: 'Est Arrival', width: 80,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      width: 120,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {params.row.status !== 'Draft' && (
            <Tooltip title="Show History">
              <IconButton
                color="inherit"
                onClick={() => handleFetchLogs(params.row.id)}
              >
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
          {
            params.row.status === 'Draft' && (
              <>
                <Tooltip title="Send PO through Trinity">
                  <IconButton
                    color="inherit"
                    onClick={() => handleShowEmail(params.row)}
                  >
                    <EmailOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    color="inherit"
                    onClick={() => handleDeleteOrder(params.row.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  const columnLogs = [
    { field: 'order_name', headerName: 'Order', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty', headerName: 'Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'unit_of_measure', headerName: 'UofM', flex: 0.5, headerClassName: 'super-app-theme--header' },
    {
      field: 'unit_price',
      headerName: 'Unit ($)',
      flex: 1,
      renderCell: (params) => (
        params.value ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value) : ''
      ),
    },
    { field: 'edited_by', headerName: 'Edited By', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'est_arrival', headerName: 'Est Arrival', width: 120,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'create_time',
      headerName: 'Updated At',
      width: 140,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    }
  ];

  const handleShowLimitDetails = async (product_id) => {
    const productLimitDetailsData = await fetchProductLimitDetails(integration.id, product_id);
    setProductLimitDetails(productLimitDetailsData);
  };

  const columnLimits = [
    {
      field: 'name', headerName: 'Product', flex: 2, headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() => handleShowLimitDetails(params.row.id)}
          >
            <FormatListNumberedIcon />
          </IconButton>
          <span>{params.value}</span>
        </>
      )
    },
    {
      field: 'qty',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Typography variant="inherit" sx={{ ml: 0.25, display: 'flex', alignItems: 'center' }}>
            Max Qty
            <Tooltip title="Maximum quantity of finished goods based on current raw material levels.">
              <InfoOutlinedIcon fontSize="small" sx={{ ml: 0.25 }} />
            </Tooltip>
          </Typography>
        </Box>
      )
    }
  ];

  const columnLimitDetails = [
    { field: 'name', headerName: 'Material', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'material_qty', headerName: 'Raw Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty_per_product', headerName: 'Unit Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty', headerName: 'Finished Goods Qty', flex: 1, headerClassName: 'super-app-theme--header' }
  ];


  const renderOptions = (item_type) => {
    if (item_type === 'material') {
      return materialsRows
        .filter(material => validRowOptions.includes(material.id))
        .map(material => (
          <MenuItem key={material.id} value={material.id}>
            {material.name} [{material.sku}]
          </MenuItem>
        ))
    } else {
      return productsRows
        .filter(product => validRowOptions.includes(product.id))
        .map((product) => (
          <MenuItem key={product.id} value={product.id}>
            {product.name}  [{product.sku}]
          </MenuItem>
        ));
    }
  };

  const handleAddClick = async () => {
    let validIds = [];
    if (orderType === 'product') {
      const data = await fetchInventory(integration.id);
      validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.product_id);
      const productLimitData = await fetchProductLimits(integration.id);
      setProductLimits(productLimitData);
    } else {
      const data = await fetchMaterialsInventory(integration.id);
      validIds = data
        .filter(item => item.lead_time !== null && item.lead_time !== '')
        .map(item => item.material_id);
    }

    if (integration.is_supplier) {
      const data = await fetchMaterialsInventory(integration.id);
      validIds = validIds.concat(
        data
          .filter(item => item.lead_time !== null && item.lead_time !== '')
          .map(item => item.material_id)
      );

    }
    setValidRowOptions(validIds);
    setTableView("adding_order")
  };


  const OrdersToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          {integration.is_brand_operated ? 'Create MO' : 'Create PO'}
        </Button>
      </GridToolbarContainer>
    );
  };

  const LogsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setTableView("table")}
        >
          Back To Orders
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <>
      {!['adding_order', 'logs', 'sending_po'].includes(tableView) && (
        <>
          <Box sx={{
            width: '100%',
            height: '65vh',
            maxHeight: '65vh',
            '& .super-app-theme-editable--cell': {
              backgroundColor: 'grey.100',
              fontWeight: '600',
            },
          }}>
            <DataGrid
              rows={rows}
              columns={columns}
              density='compact'
              loading={loading}
              processRowUpdate={processRowUpdate}
              hideFooter
              slots={{
                toolbar: OrdersToolbar,
              }}
            />
          </Box>
        </>
      )}
      {
        tableView === 'logs' ? (
          <>
            <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
              <DataGrid
                rows={rowLogs}
                columns={columnLogs}
                density='compact'
                hideFooter
                slots={{
                  toolbar: LogsToolbar,
                }}
              />
            </Box>
          </>
        ) : tableView === 'adding_order' ? (
          <>
            <Box sx={{ width: orderType === "product" ? '55%' : "65%", height: '65vh', maxHeight: '65vh' }}>
              {(orderType === 'material' && materialsRows.length === 0) ? (
                <Typography color={'red'}>You must first add materials in the HQ</Typography>
              ) : (orderType === 'product' && productsRows.length === 0) ? (
                <Typography color={'red'}>You must first add products in the HQ</Typography>
              ) : (
                <>
                  <CustomTextField
                    label="PO Number"
                    size='small'
                    variant="outlined"
                    fullWidth
                    sx={{ width: '35%' }}
                    value={poNumber}
                    onChange={(e) => setPONumber(e.target.value)}
                  />
                  <Typography variant="body2" color={'error'} sx={{ my: 1 }}>
                    If you do not see a product/material, check that the product/material has a lead time.
                  </Typography>
                  <List sx={{ maxHeight: '40vh', overflow: 'auto', py: skuList.length === 0 ? '0' : '0.25rem' }}>
                    {skuList.map((item, index) => (
                      <ListItem key={index} sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
                        <FormControl size='small' fullWidth sx={{ mr: '5px' }}>
                          <InputLabel id={item.id}>{item.item_type.charAt(0).toUpperCase() + item.item_type.slice(1)}</InputLabel>
                          <Select
                            labelId={item.id}
                            id={item.id}
                            key={item.id}
                            label={item.item_type.charAt(0).toUpperCase() + item.item_type.slice(1)}
                            value={item[`${item.item_type}_id`]}
                            onChange={(e) => handleSkuInputChange(index, `${item.item_type}_id`, e.target.value)}
                          >
                            {renderOptions(item.item_type)}
                          </Select>
                        </FormControl>
                        <CustomTextField
                          label="Qty"
                          type='number'
                          value={item.qty}
                          sx={{ mr: '5px', width: '50%' }}
                          onChange={(e) => handleSkuInputChange(index, 'qty', e.target.value)}
                        />
                        <CustomTextField
                          label="UoM"
                          disabled
                          value={item.unit_of_measure}
                          sx={{ mr: '5px', width: '50%' }}
                        />
                        <CustomTextField
                          label="Unit Price ($)"
                          type='number'
                          value={item.unit_price}
                          sx={{ mr: '5px', width: '50%' }}
                          onChange={(e) => handleSkuInputChange(index, 'unit_price', e.target.value)}
                        />
                        <CustomTextField
                          label="Tax Rate (%)"
                          type='number'
                          value={item.tax_rate}
                          sx={{ width: '50%' }}
                          onChange={(e) => handleSkuInputChange(index, 'tax_rate', e.target.value)}
                        />
                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <DeleteOutlineIcon sx={{ color: 'black' }} />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    color="primary"
                    variant='outlined'
                    sx={{ mr: '0.25rem' }}
                    startIcon={<AddIcon />}
                    onClick={handleAddItem}
                  >
                    Add {integration.is_supplier ? 'Product' : 'Item'}
                  </Button>
                  {integration.is_supplier && (
                    <Button
                      color="primary"
                      variant='outlined'
                      sx={{ mr: '0.25rem' }}
                      startIcon={<AddIcon />}
                      onClick={() => handleAddItem(true)}
                    >
                      Add Material
                    </Button>
                  )}
                </>
              )}
              {skuList.length > 0 && (
                <Button
                  color="primary"
                  variant='contained'
                  sx={{ m: '0.25rem' }}
                  onClick={() => handleCreateOrders(skuList)}
                >
                  Create
                </Button>
              )}
              <Button
                color="primary"
                variant='outlined'
                sx={{ m: '0.25rem' }}
                onClick={() => setTableView('table')}
              >
                Cancel
              </Button>
            </Box >
            {orderType === "product" && (
              <Box sx={{ width: '45%', height: '65vh', maxHeight: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column' }}>
                <Typography>Click a product to view material details</Typography>
                <Box sx={{ width: '95%', height: '25vh', maxHeight: '25vh', mb: '0.5rem' }}>
                  <DataGrid
                    rows={productLimits}
                    columns={columnLimits}
                    density='compact'
                    hideFooter
                  />
                </Box>
                <Box sx={{ width: '95%', height: '25vh', maxHeight: '25vh' }}>
                  <DataGrid
                    rows={productLimitDetails}
                    columns={columnLimitDetails}
                    density='compact'
                    hideFooter
                  />
                </Box>
              </Box>
            )}
          </>
        ) : tableView === 'sending_po' ? (
          <POSender
            logoBlob={logoBlob}
            selectedPOsArr={selectedPOsArr}
            setSelectedPOsArr={setSelectedPOsArr}
            orderType={orderType}
            rows={rows}
            setRows={setRows}
            setTableView={setTableView}
            integrations={integrations}
            integration={integration}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            inputIntegration={inputIntegration}
          />
        ) : null
      }
    </>
  );
};

export default OrdersDialogDatagrid;
