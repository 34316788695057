import { Image } from "@aws-amplify/ui-react";
import logo from '../assets/logo.png';

export function Header() {

  return (
    <div style={{ textAlign: "center" }}>
      <Image
        alt="logo"
        src={logo}
        marginTop={'1rem'}
        style={{ width: "150px", height: "auto", display: "inline-block" }}
      />
    </div>
  );
}
