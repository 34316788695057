import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import trinity_logo from '../assets/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import WidgetsIcon from '@mui/icons-material/Widgets';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import StyledIconButton from '../components/StyledIconButton';
import { fetchSharedPartners, fetchSharedMaterials, fetchSharedProducts, fetchSharedProductOrders, fetchSharedDocs } from '../api/sharing';
import SharedInventory from '../components/datagrid/SharedInventory';
import SharedDocuments from '../components/datagrid/SharedDocuments';
import SnackbarAlert from '../components/SnackbarAlert';

const SupplierApp = ({
  user,
  signOut
}) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarAlert, setSnackbarAlert] = React.useState("");
  const [selectedPartner, setSelectedPartner] = React.useState("");
  const [selectedOrgId, setSelectedOrgId] = React.useState("");
  const [selectedContactEmail, setSelectedContactEmail] = React.useState("");
  const [partnerOptions, setPartnerOptions] = React.useState([]);
  const [sharedInventory, setSharedInventory] = React.useState([]);
  const [sharedDocuments, setSharedDocuments] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedView, setSelectedView] = React.useState("products");

  useEffect(() => {
    const fetchSharedPartnersData = async () => {
      try {
        const response = await fetchSharedPartners();
        setPartnerOptions(response);
        setSelectedPartner(response[0].integration_id);
        setSelectedOrgId(response[0].org_id);
        setSelectedContactEmail(response[0].contact_email);
      } catch (error) {
        console.error("Error fetching shared materials:", error);
      } finally {
      }
    };

    if (partnerOptions.length === 0) {
      fetchSharedPartnersData()
    }
  }, [selectedView, partnerOptions]);

  useEffect(() => {
    const fetchSharedMaterialsData = async (selectedPartner) => {
      try {
        const response = await fetchSharedMaterials(selectedPartner);
        setSharedInventory(response);
      } catch (error) {
        console.error("Error fetching shared materials:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSharedProductsData = async (selectedPartner) => {
      try {
        const response = await fetchSharedProducts(selectedPartner);
        setSharedInventory(response);
      } catch (error) {
        console.error("Error fetching shared products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSharedProductOrdersData = async () => {
      try {
        const response = await fetchSharedProductOrders();
        setSharedInventory(response);
      } catch (error) {
        console.error("Error fetching shared products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSharedDocsData = async (selectedPartner) => {
      try {
        const response = await fetchSharedDocs(selectedPartner);
        setSharedDocuments(response);
      } catch (error) {
        console.error("Error fetching shared products:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedPartner) {
      setIsLoading(true);
      if (selectedView === "orders") {
        fetchSharedProductOrdersData();
      } else if (selectedView === "materials") {
        fetchSharedMaterialsData(selectedPartner);
      } else if (selectedView === "products") {
        fetchSharedProductsData(selectedPartner);
      } else if (selectedView === "documents") {
        fetchSharedDocsData(selectedPartner);
      }
    }

  }, [selectedView, selectedPartner]);

  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ height: '100vh', bgcolor: '#f5f5f5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box
        sx={{
          width: '99%',
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          m: '0.5rem'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#111217',
            py: '0.3rem',
            px: '1rem',
            borderRadius: '15px',
          }}
        >
          <img src={trinity_logo} alt="Trinity Logo" style={{ width: '95px' }} />
          <StyledIconButton icon={<LogoutIcon />} onClick={handleLogout} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="left"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '15%',
              height: '90vh',
              p: '1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'top',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1"><b>Partner Sharing Center</b></Typography>
            <Divider
              flexItem
              sx={{ bgcolor: 'black', width: '100%', my: '0.5rem' }}
            />
            {/* <Button
              variant="text"
              fullWidth
              onClick={() => setSelectedView("orders")}
              sx={{ mb: '0.5rem', textTransform: 'none', }}
            >
              Orders
            </Button> */}
            <Button
              variant="text"
              fullWidth
              onClick={() => setSelectedView("products")}
              startIcon={<WidgetsIcon />}
              sx={{
                mb: '0.5rem',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textAlign: 'left',
                pl: '15%',
              }}
            >
              Products
            </Button>
            <Button
              variant="text"
              fullWidth
              onClick={() => setSelectedView("materials")}
              sx={{
                mb: '0.5rem',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textAlign: 'left',
                pl: '15%',
              }}
              startIcon={<OilBarrelIcon />}
            >
              Materials
            </Button>
            <Button
              variant="text"
              fullWidth
              sx={{
                mb: '0.5rem',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                textAlign: 'left',
                pl: '15%',
              }}
              startIcon={<DocumentScannerIcon />}
              onClick={() => setSelectedView("documents")}
            >
              Documents
            </Button>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: 'black', height: '100%', my: '0.5rem' }}
          />
          <Box
            sx={{
              width: '85%',
              height: '85vh',
              p: '1rem',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                mb: '0.5rem',
              }}
            >
              <FormControl size='small' sx={{ width: '25%' }}>
                <InputLabel id="partner-select-label">Partner</InputLabel>
                <Select
                  labelId="partner-select-label"
                  id="partner-select"
                  value={selectedPartner}
                  sx={{ backgroundColor: 'white', }}
                  onChange={(event) => setSelectedPartner(event.target.value)}
                  label="Partner"
                >
                  {partnerOptions.map((item) => (
                    <MenuItem key={item.integration_id} value={item.integration_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography variant="body2" display={'flex'} alignItems={'center'}>
                <EmailOutlinedIcon sx={{ mx: '0.5rem' }} />
                <b>{selectedContactEmail}</b>
              </Typography>
            </Box>
            {selectedView === "orders" && (
              <>
                <SharedInventory
                  rows={sharedInventory}
                  setRows={setSharedInventory}
                  selectedView={selectedView}
                  loading={isLoading}
                />
              </>
            )}
            {selectedView === "products" && (
              <>
                <SharedInventory
                  rows={sharedInventory}
                  setRows={setSharedInventory}
                  selectedView={selectedView}
                  loading={isLoading}
                />
              </>
            )}
            {selectedView === "materials" && (
              <>
                <SharedInventory
                  rows={sharedInventory}
                  setRows={setSharedInventory}
                  selectedView={selectedView}
                  loading={isLoading}
                />
              </>
            )}
            {selectedView === "documents" && (
              <>
                <SharedDocuments
                  rows={sharedDocuments}
                  setRows={setSharedDocuments}
                  selectedPartner={selectedPartner}
                  selectedOrgId={selectedOrgId}
                  loading={isLoading}
                  setSnackbarAlert={setSnackbarAlert}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
    </Box>
  );
};

export default SupplierApp;
