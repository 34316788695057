import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import IntegrationDialogView from './IntegrationDialogView';
import IntegrationMainDatagrid from './datagrid/IntegrationMainDatagrid';
import OrdersMainDatagrid from './datagrid/OrdersMainDatagrid';
import InventoryMainDatagrid from './datagrid/InventoryMainDatagrid';
import ProductRecipeTable from './datagrid/ProductsDialogDatagrid';
import MaterialsDialogDatagrid from './datagrid/MaterialsDialogDatagrid';
import DocumentDatagrid from './datagrid/DocumentDatagrid';
import ShipmentsDatagrid from './datagrid/ShipmentsDatagrid';
import { fetchShipments } from '../api/shipments';
import { fetchAllOrders } from '../api/orders';
import { fetchAllInventory, fetchAllMaterialInventory, fetchDeliveredMaterials, fetchDeliveredProducts, fetchMaterialsInRecipes } from '../api/products';
import { fetchDocuments } from '../api/documents';

const VisualizeTable = ({
  user,
  logoBlob,
  integrations,
  setIntegrations,
  integrationTypes,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  selectedDatagrid,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  dataSources
}) => {
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [deliveredProductIds, setDeliveredProductIds] = React.useState([]);
  const [deliveredMaterialIds, setDeliveredMaterialIds] = React.useState([]);
  const [toggleRecipeView, setToggleRecipeView] = React.useState('products');
  const [materialsRecipesIds, setMaterialsRecipesIds] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState('All Orders');

  useEffect(() => {
    if (selectedDatagrid === 'Orders') {
      const fetchOrdersData = async (status) => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllOrders(status);
          setRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      if (selectedStatus !== 'All Orders') {
        fetchOrdersData(selectedStatus);
      } else {
        fetchOrdersData();
      }
    } else if (selectedDatagrid === 'Recipes') {
      const fetchValidIds = async () => {
        try {
          setLoading(true);
          const deliveredProducts = await fetchDeliveredProducts();
          const deliveredMaterials = await fetchDeliveredMaterials();
          const materialsRecipes = await fetchMaterialsInRecipes();
          setDeliveredMaterialIds(deliveredMaterials);
          setDeliveredProductIds(deliveredProducts);
          setMaterialsRecipesIds(materialsRecipes);
        } catch (error) {
          console.error('Error fetching valid ids:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchValidIds();
    } else if (selectedDatagrid === 'Shipments') {
      const fetchAllShipmentsData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchShipments();
          setRows(data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchAllShipmentsData();
    } else if (selectedDatagrid === 'Products Inventory') {
      const fetchInventoryData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllInventory();
          setRows(data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchInventoryData();
    } else if (selectedDatagrid === 'Materials Inventory') {
      const fetchMaterialInventoryData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchAllMaterialInventory();
          setRows(data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchMaterialInventoryData();
    } else if (selectedDatagrid === 'Documents') {
      const fetchDocumentData = async () => {
        try {
          setRows([]);
          setLoading(true);
          const data = await fetchDocuments();
          setRows(data);
        } catch (error) {
          console.error('Error fetching documents:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDocumentData();
    }
  }, [selectedDatagrid, selectedStatus]);


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  return (
    <>
      {selectedDatagrid === 'Integrations' ? (
        <Box sx={{
          mt: '0.5rem',
          height: '98%',
          width: '100%'
        }}>
          <IntegrationMainDatagrid
            integrations={integrations.filter(integration => integration.integration_type.type !== 'Customer')}
            setIntegrations={setIntegrations}
            integrationTypes={integrationTypes}
            setSelectedIntegrationId={setSelectedIntegrationId}
            setDialogOpen={setDialogOpen}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            loading={loading}
          />
          {dialogOpen && (
            <IntegrationDialogView
              open={dialogOpen}
              productsRows={productsRows}
              setProductsRows={setProductsRows}
              materialsRows={materialsRows}
              setMaterialsRows={setMaterialsRows}
              logoBlob={logoBlob}
              integration={integrations.find(integration => integration.id === selectedIntegrationId)}
              integrations={integrations}
              setIntegrations={setIntegrations}
              onClose={handleCloseDialog}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              dataSources={dataSources}
            />
          )}
        </Box>
      ) : selectedDatagrid === 'Recipes' ? (
        <Box sx={{
          mt: '0.5rem',
          height: '98%',
          width: '100%',
        }}>
          {toggleRecipeView === 'products' ? (
            <ProductRecipeTable
              integrationId={integrations.find(integration => integration.integration_type.category === 'HQ')?.id}
              productsRows={productsRows}
              deliveredIds={deliveredProductIds}
              setProductsRows={setProductsRows}
              materialsRows={materialsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              height='100%'
              setToggleView={setToggleRecipeView}
              loading={loading}
            />
          ) : (
            <MaterialsDialogDatagrid
              integrationId={integrations.find(integration => integration.integration_type.category === 'HQ')?.id}
              productsRows={productsRows}
              materialsRows={materialsRows}
              deliveredIds={deliveredMaterialIds}
              materialsRecipesIds={materialsRecipesIds}
              setMaterialsRows={setMaterialsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              height='100%'
              setToggleView={setToggleRecipeView}
              loading={loading}
            />
          )}
        </Box>
      ) : selectedDatagrid === 'Orders' ? (
        <Box sx={{
          m: '0.5rem',
          height: '98%',
          width: '100%'
        }}>
          <OrdersMainDatagrid
            rows={rows}
            setRows={setRows}
            materialsRows={materialsRows}
            productsRows={productsRows}
            integrations={integrations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            hiddenColumns={['id', 'actions', 'schedule_date']}
            loading={loading}
          />
        </Box>
      ) : selectedDatagrid === 'Shipments' ? (
        <Box sx={{
          m: '0.5rem',
          height: '98%',
          width: '100%'
        }}>
          <ShipmentsDatagrid
            user={user}
            rows={rows}
            setRows={setRows}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            loading={loading}
            hideCols={{ lot_number: false, expiration_date_raw: false }}
          />
        </Box>
      ) : selectedDatagrid === 'Products Inventory' ? (
        <>
          <Box sx={{
            m: '0.5rem',
            height: '98%',
            width: '100%'
          }}>
            <InventoryMainDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          </Box>
        </>
      ) : selectedDatagrid === 'Materials Inventory' ? (
        <>
          <Box sx={{
            m: '0.5rem',
            height: '98%',
            width: '100%'
          }}>
            <InventoryMainDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              type="Material"
              loading={loading}
            />
          </Box>
        </>
      ) : selectedDatagrid === 'Documents' ? (
        <>
          <Box sx={{
            m: '0.5rem',
            height: '98%',
            width: '100%'
          }}>
            <DocumentDatagrid
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              setProductsRows={setProductsRows}
              setMaterialsRows={setMaterialsRows}
              integration={integrations.find(integration => integration.integration_type.category === 'HQ')}
              integrations={integrations}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              isDialogView={false}
              loading={loading}
              height='100%'
            />
          </Box>
        </>
      ) : null
      }
    </>
  );
};

export default VisualizeTable;
