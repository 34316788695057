import React, { useEffect, useState } from 'react';
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ShipmentsDatagrid from './datagrid/ShipmentsDatagrid';
import { fetchShipments } from '../api/shipments';

const IntransitDialogView = ({
  open,
  user,
  handleClose,
  integrations,
  productsRows,
  materialsRows,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
}) => {
  const [shipments, setShipments] = useState([]);
  const [addingShipment, setAddingShipment] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);

      fetchShipments()
        .then((data) => {
          setShipments(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching shipments:", error);
          setLoading(false);
        });
    }
  }, [open]);


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Shipments</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '65vh', maxHeight: '65vh' }}>
            <ShipmentsDatagrid
              user={user}
              rows={shipments}
              setRows={setShipments}
              addingShipment={addingShipment}
              setAddingShipment={setAddingShipment}
              integrations={integrations}
              productsRows={productsRows}
              materialsRows={materialsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              showToolbar={true}
              loading={loading}
              hideCols={{ lot_number: false, expiration_date_raw: false }}
            />
          </Box>
        </DialogContent>
        {addingShipment && (
          <>
            <DialogActions>
              <Button variant='contained' onClick={() => setAddingShipment(false)}>Back to Table</Button>
              <Button variant='outlined' onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};


export default IntransitDialogView;
