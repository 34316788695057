// src/components/StyledButton.js
import React from 'react';
import { Button, Tooltip } from '@mui/material';

const StyledButton = ({ text, icon, onClick, variant = 'text', tooltip, width = '100%', sideBarFlag = false, highlight = false, sx = {} }) => {

  const button = (
    <Button
      variant={variant}
      sx={{
        color: 'white',
        backgroundColor: 'transparent',
        width: { width },
        height: '2.25rem',
        display: sideBarFlag ? 'flex' : null,
        alignItems: sideBarFlag ? 'center' : null,
        justifyContent: sideBarFlag ? 'flex-start' : null,
        textAlign: sideBarFlag ? 'left' : null,
        pl: sideBarFlag ? '16%' : null,
        my: sideBarFlag ? '0.5rem' : null,
        borderRadius: '10px',
        border: variant === 'text' ? 'none' : '1px solid grey',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'grey.800',
        },
        ...sx
      }}
      onClick={onClick}
      startIcon={icon}
    >
      {text}
    </Button>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="right">
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default StyledButton;
