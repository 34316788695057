import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Avatar,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GoogleAuth from './GoogleAuth';
import ShopifyLink from './ShopifyLink';
import QuickBooksLink from './QuickbooksLink';
import WebFlowLink from './WebFlowLink';
import AirtableLink from './AirtableLink';
import WooCommerceLink from './WooCommerceLink';
import TikTokLink from './TikTokLink';
import ShopifySetup from './ShopifySetup';
import RefreshIcon from '@mui/icons-material/Refresh';
import QuickbooksSetup from './QuickbooksSetup';
import StyledButton from './StyledButton';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import shopify_logo from '../assets/icons8-shopify-48.png';
import gmail_logo from '../assets/icons8-gmail-48.png';
import quickbooks_logo from '../assets/icons8-quickbooks-32.png';
import webflow_logo from '../assets/webflow.svg';
import sheets_logo from '../assets/icons8-google-sheets-48.png';
import { fetchShopifyLocations, fetchQuickbooksLocations } from '../api/integrations';
import { deleteSource, fetchSources } from '../api/notifications';
import { getOrg, createLogo, toggleDailyDigest, inviteUser } from '../api/users';
import airtable_logo from '../assets/airtable.png';
import tiktok_logo from '../assets/icons8-tik-tok-50.png';
import woocommerce_logo from '../assets/woocommerce_logo.png';
import { useGoogleAuth } from './GoogleAuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SheetsLink from './SheetsLink';


export default function Settings({
  user,
  setUserObj,
  hqIntegration,
  setProductsRows,
  integrations,
  setIntegrations,
  integrationTypes,
  logo,
  setLogo,
  dataSources,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  setIsLoading,
  setDataSources
}) {
  const [shopifyLocations, setShopifyLocations] = useState([]);
  const [quickbooksLocations, setQuickbooksLocations] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [inviteUserDialog, setInviteUserDialog] = useState(false);
  const [loadingNewUser, setLoadingNewUser] = useState(false);
  const [orgRows, setOrgRows] = useState([]);
  const { gmailLogin } = useGoogleAuth();
  const [selectedSettingsView, setSelectedSettingsView] = useState('account');
  const sourceTypes = ['Gmail', 'Shopify', 'Quickbooks', 'TikTok', 'WooCommerce', 'Webflow', 'Airtable', 'Google Sheets'];

  useEffect(() => {
    const handleGetOrg = async () => {
      const data = await getOrg();
      setOrgRows(data);
    };
    handleGetOrg();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/png') {
      uploadLogo(file);
    } else {
      setSnackbarAlert('error');
      setSnackbarOpen(true);
      setSnackbarMessage('Only PNGs are allowed.');
    }
  };

  const uploadLogo = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      try {
        const b64encodedString = reader.result.split(',')[1];
        await createLogo(b64encodedString);

        const url = URL.createObjectURL(file);
        setLogo(url);
      } catch (error) {
        setSnackbarAlert('error');
        setSnackbarOpen(true);
        setSnackbarMessage('Error uploading logo.');
      }
    };
  };

  const handleGetShopifyLocations = async () => {
    setIsLoading(true);
    const data = await fetchShopifyLocations();
    const filteredLocations = data.detail.locations_header.filter(location =>
      !integrations.some(integration => integration.native_id === String(location.id))
    );
    setShopifyLocations(filteredLocations);
    setIsLoading(false);
  };

  const handleGetQuickbooksLocations = async () => {
    setIsLoading(true);
    const data = await fetchQuickbooksLocations();
    setQuickbooksLocations(data);
    setIsLoading(false);
  };

  const handleDeleteSource = async (id) => {
    setIsLoading(true);
    await deleteSource(id);
    const sources_data = await fetchSources();
    setDataSources(sources_data);
    setIsLoading(false);
  };

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value === 'Shopify') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={shopify_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          );
        } else if (params.value === 'Gmail') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={gmail_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value} ({params.row.email})
            </Box>
          )
        } else if (params.value === 'Quickbooks') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={quickbooks_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else if (params.value === 'Airtable') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={airtable_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else if (params.value === 'TikTok') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={tiktok_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else if (params.value === 'WooCommerce') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={woocommerce_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else if (params.value === 'Webflow') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={webflow_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else if (params.value === 'Google Sheets') {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={sheets_logo}
                alt={params.row.type}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              {params.value}
            </Box>
          )
        } else {
          return params.value;
        }
      },
    },
    {
      field: 'create_time', headerName: 'Created', flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      }, headerClassName: 'super-app-theme--header'
    },
    {
      field: 'last_updated', headerName: 'Last Updated', flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      }, headerClassName: 'super-app-theme--header'
    },
    {
      field: 'last_update_status',
      headerName: 'Status',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const icon = {
          Success: <CheckCircleOutlineIcon fontSize="small" color="success" />,
          Failed: <ErrorOutlineIcon fontSize="small" color="error" />,
        }[params.value];
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              height: '100%',
            }}
          >
            {icon}
            <Typography
              variant="body2"
              sx={{ marginLeft: '5px', textAlign: 'center' }}
            >
              {params.value}
            </Typography>
          </Box>
        );
      }
    },
    { field: 'refresh_frequency', headerName: 'Frequency', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.75,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {params.row.type === 'Shopify' && (
            <IconButton
              color="inherit"
              onClick={() => handleGetShopifyLocations()}
            >
              <SettingsOutlinedIcon />
            </IconButton>
          )}
          {params.row.type === 'Quickbooks' && (
            <Tooltip title="Add partner from Quickbooks">
              <IconButton
                color="inherit"
                onClick={() => handleGetQuickbooksLocations()}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {params.row.type === 'Gmail' && (
            <Tooltip title="Refresh Gmail login">
              <IconButton
                color="inherit"
                onClick={gmailLogin}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            color="inherit"
            onClick={() => handleDeleteSource(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="left"
        justifyContent="center"
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '15%',
            p: '1rem',
          }}
        >
          <StyledButton
            text="Account Settings"
            variant="text"
            sx={{ mb: '0.5rem' }}
            onClick={() => setSelectedSettingsView('account')}
          />
          <StyledButton
            text="Seat Management"
            variant="text"
            sx={{ mb: '0.5rem' }}
            onClick={() => setSelectedSettingsView('seat_mgmt')}
          />
          <StyledButton
            text="Data Sources"
            variant="text"
            onClick={() => setSelectedSettingsView('data_sources')}
          />
        </Box>
        <Box
          sx={{
            width: '85%',
            p: '1rem'
          }}
        >
          {selectedSettingsView === 'account' ? (
            <Box>
              <Typography variant="h6" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}>
                Account Settings
              </Typography>
              <Divider sx={{ mb: '2rem', bgcolor: 'grey.400', width: '50%' }} />
              {logo ? (
                <Avatar
                  sx={{
                    bgcolor: 'white',
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '200px',
                    maxHeight: '200px',
                    borderRadius: '15px',
                    mb: '2rem',
                    p: '1rem',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  alt="logo"
                  src={logo}
                  onClick={() => document.getElementById('file-input').click()}
                />
              ) : (
                <StyledButton
                  text="Add Logo"
                  variant="outlined"
                  sx={{ width: '150px', mb: '0.5rem' }}
                  icon={<DesignServicesIcon />}
                  onClick={() => document.getElementById('file-input').click()}
                />
              )}
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-input"
              />
              {hqIntegration && (
                <>
                  <Typography variant="h4" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}>
                    {hqIntegration.name}
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <PlaceIcon style={{ marginRight: '0.5em' }} />
                    {hqIntegration.street_address}
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'white', mb: '0.25rem', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <EmailIcon style={{ marginRight: '0.5em' }} />
                    Account Admin: {hqIntegration.contact_email}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={user.receive_daily_digest}
                        onChange={async () => {
                          try {
                            const updatedUser = await toggleDailyDigest();
                            setUserObj(updatedUser);
                          } catch (error) {
                            console.error('Error toggling daily digest:', error);
                          }
                        }}
                        sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }}
                      />
                    }
                    label="Receive Daily Digest"
                    sx={{ my: '0.5rem', color: 'white' }}
                  />
                </>
              )}
            </Box>
          ) : selectedSettingsView === 'seat_mgmt' ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h6" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}>
                  Seat Management
                </Typography>
                <Divider sx={{ mb: '2rem', bgcolor: 'grey.400', width: '50%' }} />
                <Typography
                  variant='body1'
                  sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}
                >
                  Members of your team in Trinity:
                </Typography>
              </Box>
              <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                {orgRows.filter(row => !row.is_supplier).map(row => (
                  <li key={row.email} style={{ margin: '0.5rem 0' }}>
                    <Typography sx={{ textAlign: 'left', color: 'white' }}>
                      {row.email} {row.is_admin ? <b>(admin)</b> : ''}
                    </Typography>
                  </li>
                ))}
              </ul>
              <Typography
                variant='body1'
                sx={{ color: 'white', my: '2.5rem', textAlign: 'left' }}
              >
                <b>Chat with us to invite new team members!</b>
              </Typography>
              {orgRows.filter(row => row.is_supplier).length > 0 && (
                <>
                  <Divider sx={{ mb: '2rem', bgcolor: 'grey.400', width: '50%' }} />
                  <Typography
                    variant='body1'
                    sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}
                  >
                    Members of external organizations you've shared data with:
                  </Typography>
                  <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                    {orgRows.filter(row => row.is_supplier).map(row => (
                      <li key={row.email} style={{ margin: '0.5rem 0' }}>
                        <Typography sx={{ textAlign: 'left', color: 'white' }}>
                          {row.email} {row.is_admin ? <b>(admin)</b> : ''} {row.email === user.email ? <b>(current user)</b> : ''}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {/* <StyledButton
                text="Invite User"
                sx={{ width: '150px', mt: '0.5rem' }}
                icon={<PersonAddIcon />}
                variant="outlined"
                onClick={() => setInviteUserDialog(true)}
              /> */}
            </>
          ) : (
            <>
              <Typography variant="h6" sx={{ color: 'white', mb: '0.5rem', textAlign: 'left' }}>
                Data Sources (connect with us to set up integrations)
              </Typography>
              <Divider sx={{ mb: '1rem', bgcolor: 'grey.400' }} />
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={3}
                sx={{
                  overflowY: 'auto',
                  height: '15vh',
                  maxWidth: '100%',
                  '&::-webkit-scrollbar': {
                    height: '0.4rem',
                    backgroundColor: 'black',
                    '&-thumb': {
                      backgroundColor: 'grey.600',
                      borderRadius: '15px',
                    },
                  },
                }}
              >
                {
                  sourceTypes
                    .filter(type => !dataSources.some(row => row.type === type))
                    .map(type => (
                      <Box key={type}>
                        {type === 'Gmail' && <GoogleAuth type={type} gmailLogin={gmailLogin} />}
                        {type === 'Shopify' && <ShopifyLink />}
                        {type === 'Quickbooks' && <QuickBooksLink />}
                        {type === 'TikTok' && <TikTokLink />}
                        {type === 'WooCommerce' && <WooCommerceLink />}
                        {type === 'Webflow' && <WebFlowLink />}
                        {type === 'Airtable' && <AirtableLink />}
                        {type === 'Google Sheets' && <SheetsLink />}
                      </Box>
                    ))
                }
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  maxHeight: '65vh',
                  height: '65vh',
                  overflow: 'auto',
                  py: '1rem',
                }}
              >
                <DataGrid
                  rows={dataSources}
                  columns={columns}
                  hideFooter
                  density='compact'
                  sx={{
                    backgroundColor: 'white',
                    '& .MuiDataGrid-row': {
                      color: 'black',
                    },
                  }}
                />
              </Box>
            </>
          )}
          <ShopifySetup
            setIntegrations={setIntegrations}
            setProductsRows={setProductsRows}
            dataSources={dataSources}
            hqIntegration={hqIntegration}
            integrations={integrations}
            shopifyLocations={shopifyLocations}
            setShopifyLocations={setShopifyLocations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            integrationTypes={integrationTypes}
          />
          <QuickbooksSetup
            integrations={integrations}
            setIntegrations={setIntegrations}
            quickbooksLocations={quickbooksLocations}
            setQuickbooksLocations={setQuickbooksLocations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
            integrationTypes={integrationTypes}
          />
          <Dialog maxWidth="xs" fullWidth open={inviteUserDialog} onClose={() => setInviteUserDialog(false)}>
            <DialogTitle>Invite User</DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                The user will receive a temporary password.
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                size='small'
                label="Email"
                autoComplete='off'
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                sx={{ my: '0.5rem' }}
              />
            </DialogContent>
            <DialogActions>
              {loadingNewUser ? (
                <Box style={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <>
                  <Button onClick={() => {
                    setNewUserEmail('');
                    setInviteUserDialog(false);
                  }}>
                    Cancel
                  </Button>
                  <Button
                    disabled={newUserEmail === ''}
                    onClick={async () => {
                      setLoadingNewUser(true);
                      try {
                        const response = await inviteUser(newUserEmail);
                        setOrgRows([...orgRows, response]);
                        setNewUserEmail('');
                        setInviteUserDialog(false);
                      } catch (error) {
                        setSnackbarAlert('error');
                        setSnackbarMessage(error.response.data.detail);
                        setSnackbarOpen(true);
                      } finally {
                        setLoadingNewUser(false);
                      }
                    }}
                  >
                    Send Invite
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      </Box >
    </>
  );
}
