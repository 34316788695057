import React from 'react';
import { LineChart } from '@mui/x-charts';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

const InventoryChart = ({ data, showAsArea = true }) => {
  const formattedData = data.map(record => {
    const utcDate = new Date(record.create_time);
    const zonedDate = fromZonedTime(utcDate, 'UTC');
    return {
      ...record,
      create_time: zonedDate,
    };
  });

  const sortedDates = formattedData.map(record => new Date(record.create_time))
    .sort((a, b) => a - b);

  return (
    <LineChart
      xAxis={[
        {
          data: sortedDates,
          scaleType: 'time',
          valueFormatter: value => format(value, 'MM/dd/yy HH:mm:ss'),
        }
      ]}
      yAxis={[{
        min: 0,
      }]}
      series={[
        {
          data: formattedData.map((d) => d.qty).reverse(),
          label: "Inventory",
          showMark: true,
          connectNulls: true,
          area: showAsArea,
          color: '#292828',
        },
        ...(showAsArea ? [{
          data: formattedData.map((d) => d.safety_stock_qty || 0).reverse(),
          label: "Safety Stock",
          showMark: false,
          connectNulls: true,
          color: 'red',
        }] : []),
      ]}
      margin={{
        left: 60,
        right: 0,
        top: 20,
        bottom: 20,
      }}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default InventoryChart;
