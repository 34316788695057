import React, { useEffect, useState, useMemo } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SendIcon from '@mui/icons-material/Send';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { updateOrderStatus } from '../api/orders';
import { createShipment } from '../api/shipments.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StepConnector from '@mui/material/StepConnector';


const IconStepper = ({
  row,
  rows,
  setRows,
  orderType
}) => {
  const steps = orderType === 'material'
    ? [
      { label: '', title: 'Move to PO Sent', icon: <SendIcon fontSize="small" /> },
      { label: '', title: 'Move to In Transit', icon: <LocalShippingIcon fontSize="small" /> },
      { label: '', title: 'Move to Delivered', icon: <CheckCircleIcon fontSize="small" /> },
    ]
    : [
      { label: '', title: 'Move to PO Sent', icon: <SendIcon fontSize="small" /> },
      { label: '', title: 'Move to Awaiting Shipment', icon: <HourglassEmptyIcon fontSize="small" /> },
      { label: '', title: 'Move to In Transit', icon: <LocalShippingIcon fontSize="small" /> },
      { label: '', title: 'Move to Delivered', icon: <CheckCircleIcon fontSize="small" /> },
    ];

  const statusToIndex = useMemo(() => (
    orderType === 'material'
      ? { 'Draft': 0, 'PO Sent': 1, 'In Transit': 2, 'Delivered': 3 }
      : { 'Draft': 0, 'PO Sent': 1, 'Awaiting Shipment': 2, 'In Transit': 3, 'Delivered': 4 }
  ), [orderType]);

  const [activeStep, setActiveStep] = useState(statusToIndex[row.status] || 0);

  useEffect(() => {
    setActiveStep(statusToIndex[row.status] || 0);
  }, [row.status, statusToIndex]);

  const handleStepClick = (index) => {
    if (index === activeStep && activeStep < steps.length) {
      setActiveStep((prevStep) => prevStep + 1);
      handleUpdateStatus()
    }
  };

  const handleUpdateStatus = async () => {
    try {
      if (orderType === 'material' && activeStep === 1) {
        await createShipment([{
          ...row,
          total_freight: null,
          material_order_id: row.id,
          is_existing_order: true,
          qty: parseFloat(row.qty),
          unit_price: parseFloat(row.unit_price),
          order_type: orderType
        }]);
      } else if (orderType === 'product' && activeStep === 2) {
        await createShipment([{
          ...row,
          total_freight: null,
          product_order_id: row.id,
          is_existing_order: true,
          qty: parseFloat(row.qty),
          unit_price: parseFloat(row.unit_price),
          order_type: orderType
        }]);
      } else {
        const data = await updateOrderStatus(row.id, row.status, orderType);
        setRows(rows.map((row) => (row.id === data.id ? data : row)));
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  return (
    <Stepper
      activeStep={activeStep}
      connector={<StepConnector />}
      alternativeLabel
      sx={{
        gap: '0px',
        width: '100%',
      }}
    >
      {steps.map((step, index) => (
        <Step key={`${step.title}-${row.id}`}>
          <Tooltip title={step.title === 'Move to In Transit' && !row.integration_id_to ? 'Select where this PO will be shipped before setting it to In Transit' : step.title} placement="top">
            <StepLabel
              icon={
                <IconButton
                  onClick={() => handleStepClick(index)}
                  disabled={index !== activeStep || (step.title === 'Move to In Transit' && !row.integration_id_to)}
                  color={'info'}
                >
                  {step.icon}
                </IconButton>
              }
            >
              {step.label}
            </StepLabel>
          </Tooltip>
        </Step>
      ))}
    </Stepper>
  );
};

export default IconStepper;
