import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { cheerfulFiestaPalette } from '@mui/x-charts/colorPalettes';

export default function BasicPieChart({ plotData }) {
  const TOTAL = plotData.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const sortedData = [...plotData].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <PieChart
      colors={cheerfulFiestaPalette}
      series={[{
        data: sortedData,
        highlightScope: { fade: 'global', highlight: 'item' },
        arcLabel: getArcLabel,
      }]}
      slotProps={{
        legend: { hidden: true },
      }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: '10px !important',
        },
      }}
    />
  );
}
