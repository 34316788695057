import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { cheerfulFiestaPaletteDark } from '@mui/x-charts/colorPalettes';

export default function InventoryBar({ plotData, axisLabel }) {
  const sortedData = [...plotData].sort((a, b) => a.label.localeCompare(b.label));

  const dataWithColors = sortedData.map((item, index) => ({
    ...item,
    color: cheerfulFiestaPaletteDark[index % cheerfulFiestaPaletteDark.length],
  }));

  const series = dataWithColors.map((item) => ({
    dataKey: item.label,
    label: item.label,
    color: item.color,
  }));


  const dataset = [
    {
      category: axisLabel.charAt(0).toUpperCase() + axisLabel.slice(1),
      ...Object.fromEntries(dataWithColors.map(item => [item.label, item.value])),
    },
  ];

  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'category', barGapRatio: 0.5 }]}
      series={series}
      sx={{
        [`.${axisClasses.root}`]: {
          [`.${axisClasses.tick}, .${axisClasses.line}`]: {
            stroke: '#ffffff',
            strokeWidth: 1,
          },
          [`.${axisClasses.tickLabel}`]: {
            fill: '#ffffff',
            fontSize: '10px !important',
          },
        },
      }}
      slotProps={{
        legend: {
          hidden: true,
          labelStyle: {
            fill: 'white',
          },
        },
      }}
      margin={{
        left: 40,
        right: 0,
        top: 10,
        bottom: 20,
      }}
    />
  );
}
