import React, { useState, useRef } from 'react';
import { Box, IconButton, Button, Tooltip } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadIcon from '@mui/icons-material/Upload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { createProduct, updateProduct, fetchRecipe, deleteProduct, uploadFile } from '../../api/products';
import { fetchShipments } from '../../api/shipments';
import ShipmentsDatagrid from './ShipmentsDatagrid';
import RecipeBuilder from './RecipeBuilder';

const ProductRecipeTable = ({
  integrationId,
  productsRows,
  deliveredIds,
  setProductsRows,
  materialsRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  height = '65vh',
  loading = null,
  setToggleView = null
}) => {
  const [recipeRows, setRecipeRows] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        const response = await uploadFile('products', integrationId, formData);
        setProductsRows((prevRows) => [...prevRows, ...response]);
        setSnackbarOpen(true);
        setSnackbarMessage("Uploaded successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        console.error('Error uploading file:', error);
        setSnackbarOpen(true);
        setSnackbarMessage("Uploaded unsuccessful, please check that your data structure mataches the template.");
        setSnackbarAlert("error");
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const handleDeleteProductClick = async (id) => {
    try {
      const deleteSuccess = await deleteProduct(id);
      console.log(deleteSuccess)
      setProductsRows((prevRows) => prevRows.filter(row => row.id !== id));
      setSnackbarOpen(true);
      setSnackbarMessage("Deleted successfully.");
      setSnackbarAlert("success");
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, null, row.id, null, "True");
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const productColumns = [
    { field: 'name', headerName: 'Item', flex: 1, editable: true },
    { field: 'sku', headerName: 'SKU', flex: 1, editable: true },
    { field: 'upc', headerName: 'UPC', flex: 1, editable: true },
    {
      field: 'default_price',
      headerName: 'Default Price ($)',
      width: 200,
      editable: true,
      renderCell: (params) => (
        params.value ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value) : 0
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.65,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          {deliveredIds.some(delivered => delivered.id === params.row.id) && (
            <Tooltip title="Lot Details">
              <IconButton
                color="inherit"
                onClick={() => handleFetchInventoryDetails(params.row)}
              >
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="View Recipe/BOM">
            <IconButton
              color="inherit"
              onClick={() => handleExpandRecipe(params.row.id)}
            >
              <FormatListNumberedIcon />
            </IconButton>
          </Tooltip>

          <>
            {(params.row.inventory_count === 0 && params.row.orders_count === 0) && (
              <>
                <Tooltip title="This product is not currenty used in your supply chain.">
                  <IconButton
                    sx={{ color: '#0249c1' }}
                  >
                    <CircleIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteProductClick(params.row.id)}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </>
            )}
          </>
        </>

      ),
      headerClassName: 'super-app-theme--header'
    }
  ];


  const handleExpandRecipe = async (productId) => {
    setSelectedProductId(productId);
    const productName = productsRows.find(product => product.id === productId).name;
    setSelectedProductName(productName);
    try {
      const data = await fetchRecipe(productId);
      setRecipeRows(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleAddProduct = async () => {
    try {
      const createdProduct = await createProduct(
        {
          integration_id: integrationId,
          name: 'New Product',
          sku: '',
          upc: null,
        }
      );
      setProductsRows(prevProducts => [...prevProducts, createdProduct]);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const processProductRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const data = await updateProduct(newRow.id, newRow);
      updatedRow.last_updated = data.last_updated;
      setProductsRows(productsRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;
    } catch (error) {
      console.error("Error in processProductRowUpdate:", error);
      throw error;
    }
  };

  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage(error.response.data.detail);
    setSnackbarAlert("error");
  };


  const ProductsToolbar = () => {
    return (
      <GridToolbarContainer width={'100%'}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Box display={'flex'} gap={'0.5rem'}>
            <Button
              color="inherit"
              variant='outlined'
              startIcon={<AddIcon />}
              onClick={handleAddProduct}
              sx={{ mb: '2px' }}
            >
              Add Product
            </Button>
            {setToggleView && (
              <Button
                variant='contained'
                startIcon={<OilBarrelIcon />}
                sx={{ mb: '2px', bgcolor: "#0249c1" }}
                onClick={() => setToggleView('materials')}
              >
                Materials View
              </Button>
            )}
          </Box>
          <Box display={'flex'} gap={'0.5rem'}>
            <Button
              color="inherit"
              variant='text'
              startIcon={<PostAddIcon />}
              sx={{ mb: '2px' }}
              href="https://trinity3-public.s3.us-east-1.amazonaws.com/ProductTemplate.xlsx"
              target="_blank"
              download
            >
              Get Upload Template
            </Button>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<UploadIcon />}
                sx={{ mb: '2px' }}
                onClick={handleButtonClick}
              >
                Quick Upload
              </Button>
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => setInventoryDetailsRows([])}
        >
          Back to table
        </Button>
      </GridToolbarContainer>
    );
  };


  return (
    <Box sx={{ display: 'flex', width: '100%', height: { height }, maxHeight: { height } }}>
      {
        inventoryDetailsRows.length === 0 && !selectedProductId ? (
          <>
            <Box
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <DataGrid
                rows={productsRows}
                columns={productColumns}
                disableSelectionOnClick
                density="compact"
                loading={loading}
                hideFooter
                processRowUpdate={processProductRowUpdate}
                onProcessRowUpdateError={processRowUpdateError}
                slots={{
                  toolbar: ProductsToolbar,
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiDataGrid-row': {
                    color: 'black',
                  },
                }}
              />
            </Box>
          </>
        ) : selectedProductId ? (
          <Box sx={{ width: '100%', height: '100%' }}>
            <RecipeBuilder
              recipeRows={recipeRows}
              setRecipeRows={setRecipeRows}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              selectedProductName={selectedProductName}
              productsRows={productsRows}
              materialsRows={materialsRows}
              setSnackbarAlert={setSnackbarAlert}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
            />
          </Box>
        ) : (
          <Box sx={{ width: '100%', height: '100%' }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
              hideCols={{ carrier: false, bol_string: false, tracking_number: false, pick_up_date: false, is_delivered: false }}
            />
          </Box>
        )}
    </Box>
  );
};

export default ProductRecipeTable;
