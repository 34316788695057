import React, { useEffect, useState } from 'react';
import { Box, Button, Select, MenuItem, IconButton, Tooltip, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { fetchRecipe, createProductsInventory, updateProductsInventory, deleteProductsInventory, fetchHistoryInventory } from '../../api/products';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { fetchProductLimits, fetchProductLimitDetails } from '../../api/forecast';
import ShipmentsDatagrid from './ShipmentsDatagrid';
import RecipeBuilder from './RecipeBuilder';
import { fetchShipments } from '../../api/shipments';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import InventoryChart from '../charts/InventoryChart';


export default function InventoryDialogDatagrid({
  integrationId,
  integration,
  integrationType,
  is3PL,
  productsRows,
  materialsRows,
  inventoryRows,
  setInventoryRows,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null
}) {
  const columnVisibilityModel = {
    id: false,
    product_id: false,
    sales_price: integrationType === 'Manufacturer' && !is3PL ? false : true,
    is_active: integrationType === 'Manufacturer' ? false : true,
    is_turnkey: integrationType === 'Manufacturer' ? true : false,
    mfg_equivalent_qty: integrationType === 'Manufacturer' ? true : false,
    po_sent_qty: integrationType === 'Manufacturer' ? false : true,
    in_transit_qty: integrationType === 'Manufacturer' ? false : true,
    sales_per_day: integrationType === 'Manufacturer' ? false : true,
    global_monthly_usage: integrationType === 'Manufacturer' ? true : false,
    next_order_date: integrationType === 'Manufacturer' ? false : true,
    safety_stock_qty: integrationType === 'Manufacturer' && !is3PL ? false : true,
    ordered_qty: integrationType === 'Manufacturer' ? true : false,
    lead_time: integrationType === 'Manufacturer' ? true : false,
  };
  const [inventoryDetailsRows, setInventoryDetailsRows] = React.useState([]);
  const [inventoryTrendRows, setInventoryTrendRows] = React.useState([]);
  const [productLimits, setProductLimits] = React.useState([]);
  const [productLimitDetails, setProductLimitDetails] = React.useState([]);
  const [loadingLimits, setLoadingLimits] = React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState(null);
  const [selectedProductName, setSelectedProductName] = React.useState(null);
  const [recipeRows, setRecipeRows] = useState([]);

  useEffect(() => {
    if (integrationType === 'Manufacturer') {
      fetchProductLimits(integrationId).then(data => setProductLimits(data));
    }
  }, [integrationType, integrationId]);

  const handleNameChange = async (event, params) => {
    const newId = event.target.value;
    const selectedProduct = productsRows.find(product => product.id === newId);
    const createdInventory = await createProductsInventory(
      {
        integration_id: integrationId,
        product_id: selectedProduct.id,
        qty: null,
        unit_of_measure: null,
        lead_time: integration.default_lead_time || null,
        safety_stock_qty: null
      }
    );
    setInventoryRows(prevInventory => prevInventory.filter(row => row.id !== 1).concat(createdInventory));
  };
  const handleProductSelect = (event) => {
    handleNameChange(event);
  };

  const handleDeleteClick = async (id) => {
    if (id !== 1) {
      try {
        const deleteSuccess = await deleteProductsInventory(id);
        console.log(deleteSuccess)
        setInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));
        setSnackbarOpen(true);
        setSnackbarMessage("Deleted successfully.");
        setSnackbarAlert("success");
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
      }
    }
    else {
      setInventoryRows((prevRows) => prevRows.filter(row => row.id !== id));

    }
  };

  const handleFetchInventoryDetails = async (row) => {
    try {
      const inventoryDetails = await fetchShipments(null, row.integration_id, row.product_id, null, "True");
      setInventoryDetailsRows(inventoryDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchInventoryTrend = async (row) => {
    try {
      const inventoryTrend = await fetchHistoryInventory('product', row.integration_id, row.product_id);
      setInventoryTrendRows(inventoryTrend);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowLimitDetails = async (product_id) => {
    setProductLimitDetails([]);
    setLoadingLimits(true);
    try {
      const productLimitDetailsData = await fetchProductLimitDetails(integrationId, product_id);
      setProductLimitDetails(productLimitDetailsData);
    } catch (error) {
      console.error('Error loading product limit details:', error);
    } finally {
      setLoadingLimits(false);
    }
  };

  const handleExpandRecipe = async (productId) => {
    setSelectedProductId(productId);
    const productName = productsRows.find(product => product.id === productId).name;
    setSelectedProductName(productName);
    try {
      const data = await fetchRecipe(productId);
      setRecipeRows(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const columns = [
    {
      field: 'name', headerName: 'Item', flex: 1.75, renderCell: (params) => {
        const selectedProduct = productsRows.find(product => product.id === params.row.product_id);
        const value = selectedProduct ? selectedProduct.name : '';

        return (
          params.row?.unlocked ? (
            <Select
              size="small"
              variant="standard"
              fullWidth
              onChange={(event) => {
                const newValue = event.target.value;
                const updatedRow = { ...params.row, product_id: newValue, unlocked: false };
                processRowUpdate(updatedRow);
              }}
            >
              {productsRows
                .filter(product => !inventoryRows.some(inventory => inventory.product_id === product.id))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name} [{product.sku}]
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <span>{value} [{selectedProduct.sku}]</span>
          )
        );
      }
    },
    {
      field: 'sales_price',
      headerName: 'Sales Price ($)',
      flex: 1,
      editable: true,
      renderCell: (params) => (
        params.value ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value) : ''
      ),
    },
    {
      field: 'is_active', headerName: 'Active', flex: 0.5, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_active: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'is_turnkey', headerName: 'Turnkey', flex: 0.5, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_turnkey: newValue };
            processRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'po_sent_qty',
      headerName: 'Expected Qty',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    {
      field: 'in_transit_qty',
      headerName: 'Inbound Qty',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    {
      field: 'ordered_qty',
      headerName: 'Ordered Qty',
      flex: 1,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    {
      field: 'qty',
      headerName: 'Inventory',
      flex: 1,
      editable: true,
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : 0,
    },
    { field: 'safety_stock_qty', headerName: 'Safety Stock', flex: 1, editable: true },
    { field: 'lead_time', headerName: 'Lead Time (days)', flex: 1, editable: true },
    {
      field: 'mfg_equivalent_qty',
      cellClassName: 'super-app-theme-editable--cell',
      renderHeader: () => (
        <Tooltip title="Maximum finished goods inventory that could be produced based on current raw material levels.">
          <Box display="flex" alignItems="center">
            <span>
              <b>Max Qty Available</b>
              <IconButton size="small" sx={{ ml: 0.25, color: 'black' }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </span>
          </Box>
        </Tooltip>
      ),
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const limit = productLimits.find(limit => limit.id === params.row.product_id);
        const minimum_order_qty_flag = (integration.minimum_order_qty && limit) ? (
          limit.qty >= integration.minimum_order_qty ? (
            <Tooltip title="Has enough to meet minimum order quantity">
              <IconButton size="small" sx={{ ml: 0.25 }} onClick={() => handleShowLimitDetails(params.row.product_id)}>
                <CheckCircleIcon color='success' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Not enough for minimum order quantity">
              <IconButton size="small" sx={{ ml: 0.25 }} onClick={() => handleShowLimitDetails(params.row.product_id)}>
                <WarningAmberIcon color='error' />
              </IconButton>
            </Tooltip>
          )
        ) : null;

        return limit ? (
          <>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
              <span>{Number(limit.qty).toLocaleString()}</span>
              {minimum_order_qty_flag}
            </Box>
          </>
        ) : (
          <>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
              {params.row.is_turnkey ? (
                ''
              ) : (
                <>
                  <span>No recipe defined</span>
                  <IconButton size="small" sx={{ ml: 0.25 }} onClick={() => handleExpandRecipe(params.row.product_id)}>
                    <FormatListNumberedIcon color='inherit' sx={{ ml: 0.25 }} />
                  </IconButton>
                </>
              )}
            </Box>
          </>
        );
      }
    },
    {
      field: 'global_monthly_usage',
      flex: 0.8,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      cellClassName: 'super-app-theme-editable--cell',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : '',
      renderHeader: () => (
        <Tooltip title="Total expected monthly consumption.">
          <Box display="flex" alignItems="center">
            <span>
              <b>Monthly Usage</b>
              <IconButton size="small" sx={{ ml: 0.25, color: 'black' }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </span>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'sales_per_day',
      cellClassName: 'super-app-theme-editable--cell',
      renderHeader: () => (
        <Tooltip title="Average usage per day over the last 30 days">
          <Box display="flex" alignItems="center">
            <span>
              <b>Avg. Usage / Day</b>
              <IconButton size="small" sx={{ ml: 0.25, color: 'black' }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </span>
          </Box>
        </Tooltip>
      ),
      flex: 1.2,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.value === 0) {
          return <span>{params.value}</span>;
        }
        else if (!params.value) {
          return '';
        }
        else if (!params.row.sales_per_day_last_week) {
          return <span>{params.value}</span>;
        }
        else if (params.row.sales_per_day_last_week === params.value) {
          return <span>{params.value}</span>;
        }
        const trend_up = params.row.sales_per_day_last_week > params.value;
        return (
          <Tooltip
            title={trend_up ? 'Trending up in last week' : 'Trending down in last week'}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {params.value}
              <IconButton size="small">
                {trend_up ? <TrendingUpIcon sx={{ color: 'green' }} /> : <TrendingDownIcon sx={{ color: 'red' }} />}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'next_order_date',
      cellClassName: 'super-app-theme-editable--cell',
      renderHeader: () => (
        <Tooltip title="Recommended next order date. If this value is blank, there is no manufacturer/lead time listed in Trinity for this product.">
          <span>
            <b>Next Order</b>
            <IconButton size="small" sx={{ ml: 0.25, color: 'black' }}>
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      ),
      flex: 1,
      sortable: false,
      disableColumnFilter: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {format(date, 'MM/dd/yy')}
            {params.row.next_order < 7 ? <WarningAmberIcon sx={{ color: 'orange', ml: '0.25rem' }} /> : ''}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.3,
      align: 'right',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="Inventory Trend">
            <IconButton
              color="inherit"
              onClick={() => handleFetchInventoryTrend(params.row)}
            >
              <TimelineIcon />
            </IconButton>
          </Tooltip>
          {['3PL', 'Distributor'].includes(integrationType) && params.row.delivered_qty > 0 && (
            <>
              <Tooltip title="Lot Details">
                <IconButton
                  color="inherit"
                  onClick={() => handleFetchInventoryDetails(params.row)}
                >
                  <ManageSearchIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {['Manufacturer'].includes(integrationType) && (
            <>
              <Tooltip title="Build Recipe">
                <IconButton
                  color="inherit"
                  onClick={() => handleExpandRecipe(params.row.product_id)}
                >
                  <FormatListNumberedIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Change product name in Trinity - this does not unlink the product from its data source. This updates the Trinity display name to unify naming in your supply chain.">
            <IconButton
              color="inherit"
              onClick={() => {
                const newRow = { ...params.row, unlocked: !params.row.unlocked };
                setInventoryRows(inventoryRows.map((row) => (row.id === newRow.id ? newRow : row)));
              }}
            >
              {params.row.unlocked ? <LockOpenIcon /> : <LockIcon />}
            </IconButton>
          </Tooltip>
          <IconButton
            color="inherit"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      headerClassName: 'super-app-theme--header'
    }
  ];


  const processRowUpdate = async (newRow) => {
    if (newRow.qty >= 0) {
      const updatedRow = { ...newRow, isNew: false };
      try {
        const data = await updateProductsInventory(newRow.id, newRow);
        updatedRow.last_updated = data.last_updated;
        setSnackbarOpen(true);
        setSnackbarMessage("Updated successfully");
        setSnackbarAlert("success");
        setInventoryRows(inventoryRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(error.response.data.detail);
        setSnackbarAlert("error");
        return null;
      }
    }
  };


  const processRowUpdateError = (error) => {
    setSnackbarOpen(true);
    setSnackbarMessage("Invalid value entered");
    setSnackbarAlert("error");
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Typography variant="body1" sx={{ mx: 1, display: 'inline' }}>
          <b>Add Product:</b>
        </Typography>
        <Select
          size="small"
          variant="standard"
          label="Add Product"
          onChange={handleProductSelect}
          sx={{ minWidth: 200 }}
        >
          <MenuItem value="" disabled>
            Select a product
          </MenuItem>
          {productsRows
            .filter(product => !inventoryRows.some(inventory => inventory.product_id === product.id))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name} [{product.sku}]
              </MenuItem>
            ))}
        </Select>
      </GridToolbarContainer>
    );
  };

  const CustomDetailsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          color="inherit"
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            setProductLimitDetails([]);
            setInventoryDetailsRows([]);
            setInventoryTrendRows([]);
          }}
        >
          Back To Table
        </Button>
      </GridToolbarContainer>
    );
  };

  const trendColumns = [
    {
      field: 'create_time',
      headerName: 'Time',
      width: 140,
      renderCell: (params) => {
        const date = new Date(params.value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm:ss');
      },
    },
    {
      field: 'qty',
      headerName: 'Qty',
      flex: 0.5,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.value ? Number(params.value).toLocaleString() : <b>{params.row.order_qty > 0 ? '+ ' : ''}{Number(params.row.order_qty).toLocaleString()}</b>
    },
    {
      field: 'edited_by',
      headerName: 'Source',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => params.row.qty ? params.value : <b>{params.value}</b>
    },
  ];

  const columnLimitDetails = [
    { field: 'name', headerName: 'Material', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'material_qty', headerName: 'Raw Material Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    { field: 'qty_per_product', headerName: 'Unit Product Qty', flex: 1, headerClassName: 'super-app-theme--header' },
    {
      field: 'qty',
      headerName: 'Finished Goods Qty',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        const value = params.value ? Number(params.value).toLocaleString('en-US') : '0';
        const minimum_order_qty_flag = integration.minimum_order_qty ? (
          params.value >= integration.minimum_order_qty ? (
            <CheckCircleIcon color="success" size="small" sx={{ ml: 0.5 }} />
          ) : (
            <WarningAmberIcon color="error" size="small" sx={{ ml: 0.5 }} />
          )
        ) : null;

        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {value}
            {minimum_order_qty_flag}
          </span>
        );
      }
    }
  ];


  return (
    <>
      <Box sx={{ display: 'flex', width: '100%', height: '65vh' }}>
        {inventoryDetailsRows.length > 0 ? (
          <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
            <ShipmentsDatagrid
              rows={inventoryDetailsRows}
              setRows={setInventoryDetailsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
              customToolbar={CustomDetailsToolbar}
              hideCols={{ carrier: false, bol_string: false, tracking_number: false, pick_up_date: false, is_delivered: false }}
            />
          </Box>
        ) : selectedProductId ? (
          <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
            <RecipeBuilder
              recipeRows={recipeRows}
              setRecipeRows={setRecipeRows}
              selectedProductId={selectedProductId}
              setSelectedProductId={setSelectedProductId}
              selectedProductName={selectedProductName}
              productsRows={productsRows}
              materialsRows={materialsRows}
              setSnackbarAlert={setSnackbarAlert}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
            />
          </Box>
        ) : inventoryTrendRows.length > 0 ? (
          <>
            <Box sx={{ width: '30%', height: '100%', mr: '0.5rem' }}>
              <DataGrid
                rows={inventoryTrendRows}
                columns={trendColumns}
                density='compact'
                hideFooter
                slots={{
                  toolbar: CustomDetailsToolbar,
                }}
              />
            </Box>
            <Box sx={{ width: '70%', height: '100', p: '1rem' }}>
              <InventoryChart data={inventoryTrendRows} showAsArea={integrationType === 'Manufacturer' ? false : true} />
            </Box>
          </>
        ) : productLimitDetails.length > 0 ? (
          <>
            <Box
              sx={{ width: '60%', height: '65vh', maxHeight: '65vh', mr: productLimitDetails.length ? '1rem' : '0' }}>
              <DataGrid
                rows={inventoryRows}
                columns={columns}
                density='compact'
                loading={loading}
                hideFooter
                columnVisibilityModel={columnVisibilityModel}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={processRowUpdateError}
                slots={{
                  toolbar: integrationType === 'Manufacturer' ? CustomToolbar : CustomToolbar,
                }}
              />
            </Box>
            <Box sx={{ width: '40%', height: '35vh', maxHeight: '35vh' }}>
              <DataGrid
                rows={productLimitDetails}
                columns={columnLimitDetails}
                columnVisibilityModel={columnVisibilityModel}
                density='compact'
                hideFooter
                loading={loadingLimits}
                slots={{
                  toolbar: CustomDetailsToolbar,
                }}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '65vh',
              maxHeight: '65vh',
              mr: inventoryDetailsRows.length ? '1rem' : '0',
              '& .super-app-theme-editable--cell': {
                backgroundColor: 'grey.100',
                fontWeight: '600',
              },
            }}>
            <DataGrid
              rows={inventoryRows}
              columns={columns}
              density='compact'
              loading={loading}
              hideFooter
              columnVisibilityModel={columnVisibilityModel}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={processRowUpdateError}
              slots={{
                toolbar: integrationType === 'Manufacturer' ? CustomToolbar : CustomToolbar,
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
